// React
import React from "react";
// Material
import { CircularProgress, Grid } from "@material-ui/core";
// Styles
import { createStyles, makeStyles } from "@material-ui/styles";
import { handleBilanColor } from "../../../../helpers/color-translator.helper";
// Color
import { colorCAC } from "../../../../styles/color";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    wrapperContent: {
      position: "relative",
      ["@supports (-webkit-touch-callout: none)"]: {
        marginTop: 149,
      },
    },
    circulartext: {
      zIndex: 2,
      fontWeight: 800,
      fontSize: 45,
      display: "block",
      position: "absolute",
      textAlign: "center",
      top: "50%",
      transform: "translateY(-50%)",
    },
    circular: {
      position: "absolute",
      zIndex: 2,
      transform: "rotate(90deg) !important",
    },
    circularBackground: {
      color: colorCAC.black,
      zIndex: 1,
    },
  })
);

/**
 * QuizAnswerProgress props
 */
interface IQuizAnswerProgressProps {
  progress: number;
}

/** Quiz Answer Progress */
const QuizAnswerProgress = (props: IQuizAnswerProgressProps) => {
  const classes = useStyles();
  const { progress } = props;

  return (
    <Grid
      container={true}
      justifyContent="center"
      className={classes.wrapperContent}
    >
      <span
        className={classes.circulartext}
        style={{ color: handleBilanColor(progress) }}
      >
        {progress}%
      </span>
      <CircularProgress
        size={200}
        variant="determinate"
        value={progress}
        thickness={2}
        className={classes.circular}
        style={{ color: handleBilanColor(progress) }}
      />
      <CircularProgress
        size={200}
        variant="determinate"
        value={100}
        thickness={0.1}
        className={classes.circularBackground}
      />
    </Grid>
  );
};

export default React.memo(QuizAnswerProgress);
