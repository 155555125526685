// React
import React, { useEffect, useRef, useState } from "react";

// Material
import { Button, Grid, Paper, Popover, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

// Typeform
import "@typeform/embed/build/css/widget.css";
import { createWidget } from "@typeform/embed";

// Constants
import { CONST } from "../../config/constant";
import { useHistory } from "react-router";
import { useAuthentication } from "@dsk-lib/user";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setSurveyChoice } from "../../store/survey/actions";
import {
  getItemLocalStorage,
  setItemLocalStorage,
} from "../../services/utility.service";
import {
  getSurveySetting,
  patchSurveySetting,
} from "../../services/user.service";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "2001!important" as any,
    },
    modalSurvey: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "2001!important" as any,
    },
    closeIcon: {
      cursor: "pointer",
      position: "absolute",
      top: "4vh",
      right: "4vw",
      zIndex: "2003!important" as any,
    },
    modalContent: {
      padding: theme.spacing(5),
      minWidth: "40vw",
      maxWidth: "95vw",
      maxHeight: "95vh",
    },
    modalSurveyContent: {
      backgroundColor: "transparent",
      width: "95vw",
      height: "95vh",
    },
    popover: {
      zIndex: "2002!important" as any,
    },
    popoverItem: {
      color: "red",
    },
    buttonModal: {
      margin: "5px 0 5px 12px",
    },
    typography: {
      marginBottom: "15px",
      maxWidth: "500px",
    },
  })
);

/**
 * Modal display mode
 */
enum ModalMode {
  UserChoice = "choice",
  Survey = "survey",
  Confirmation = "confirmation",
  Done = "done",
}

/**
 * ConfirmModal component
 */
const SurveyModal = () => {
  /**Keycloak */
  const { keycloak, fetchWithCredentials } = useAuthentication();
  /** Dispatch store */
  const dispatch = useDispatch<ThunkDispatch<StoreState, any, Action>>();
  /** Classes  */
  const classes = useStyles();
  /** Dom components  */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const surveyContainer = useRef<HTMLElement>(null);
  /** Modals mode */
  const [mode, setMode] = useState<ModalMode | null>(null);
  const modeRef = useRef<ModalMode | null>(null);
  /** Router history */
  const history = useHistory();
  /** Previous router transaction */
  const [previousTx, setPreviousTx] = useState<any>();
  /** Survey choice state */
  const surveyChoice = useSelector((state: StoreState) => state.surveyHandler);
  /** Check if survey started */
  const [isSurveyStarted, setSurveyStarted] = useState<boolean>(false);

  useEffect(() => {
    const tokenParsed = keycloak.tokenParsed as any;
    const surveySetting = getSurveySetting();

    if (!surveySetting?.value) {
      dispatch(setSurveyChoice(surveySetting?.value ?? null));
    } else if (
      surveySetting.value &&
      tokenParsed.auth_time &&
      surveySetting.updatedAt < new Date(tokenParsed.auth_time * 1000)
    ) {
      if (getItemLocalStorage("CAC SURVEY STARTED") === true) {
        setSurveyStarted(true);
      }
      setMode(ModalMode.UserChoice);
    }
  }, []);

  useEffect(() => {
    let unblock = () => {};

    if (surveyChoice === true) {
      unblock = history.block((tx) => {
        if (!modeRef?.current) {
          if (getItemLocalStorage("CAC SURVEY STARTED") === true) {
            setSurveyStarted(true);
          }
          setMode(ModalMode.UserChoice);
          setPreviousTx(tx);
          return false;
        } else {
          unblock();
        }
        return;
      });
    } else if (surveyChoice === false) {
      setMode(ModalMode.Done);
    }

    return () => unblock();
  }, [surveyChoice]);

  useEffect(() => {
    modeRef.current = mode;
  }, [mode]);

  useEffect(() => {
    if (mode === ModalMode.Survey) {
      createSurvey();
    }
  }, [mode]);

  // We need to wait for the modal to be renderer or the ref will always be null
  const createSurvey = () => {
    createWidget(CONST.SURVEY_ID, {
      onSubmit: () => {
        patchSurveySetting(fetchWithCredentials as any, false);
        localStorage.removeItem("CAC SURVEY STARTED");
        setMode(ModalMode.Confirmation);
      },
      container: surveyContainer.current!,
    });
  };

  const handleClose = (subscribed?: boolean) => {
    dispatch(setSurveyChoice(false));
    if (subscribed !== undefined) {
      patchSurveySetting(fetchWithCredentials as any, subscribed);
      if (subscribed === false) {
        localStorage.removeItem("CAC SURVEY STARTED");
      }
    }

    if (previousTx) {
      history.push(previousTx);
    } else {
      setMode(ModalMode.Done);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={mode === ModalMode.UserChoice}
        onClose={() => handleClose(true)}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mode === ModalMode.UserChoice}>
          <React.Fragment>
            <Paper className={classes.modalContent}>
              <Grid container={true} spacing={3} direction="column">
                <Grid item={true}>
                  <Typography variant="h5" component="h3">
                    Enquête de satisfaction
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography component="div" className={classes.typography}>
                    Afin de nous aider à améliorer la qualité de nos services et
                    évaluer votre satisfaction, nous vous proposons de consacrer
                    2 minutes à un questionnaire.
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  container={true}
                  spacing={3}
                  direction="row"
                  alignContent="center"
                  justifyContent="flex-end"
                  wrap="wrap-reverse"
                >
                  <Popover
                    className={classes.popover}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Grid container={true} direction="column">
                      <Button variant="text" onClick={() => handleClose(true)}>
                        Me le rappeler plus tard
                      </Button>
                      <Button variant="text" onClick={() => handleClose(false)}>
                        Ne plus me le rappeler
                      </Button>
                    </Grid>
                  </Popover>
                  <Button
                    className={classes.buttonModal}
                    variant="text"
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      setAnchorEl(event.currentTarget)
                    }
                  >
                    Pas maintenant...
                  </Button>
                  <Button
                    className={classes.buttonModal}
                    variant="contained"
                    color="primary"
                    style={{ color: "#FFF" }}
                    onClick={() => {
                      setMode(ModalMode.Survey);
                      setItemLocalStorage("CAC SURVEY STARTED", true);
                    }}
                  >
                    {isSurveyStarted ? "Continuer" : "Démarrer"} le
                    questionnaire
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalSurvey}
        open={mode === ModalMode.Survey}
        disableEscapeKeyDown
        closeAfterTransition={true}
        ref={modeRef}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mode === ModalMode.Survey}>
          <React.Fragment>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => handleClose(true)}
              htmlColor="white"
            />
            <Paper
              className={classes.modalSurveyContent}
              ref={surveyContainer}
            />
          </React.Fragment>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={mode === ModalMode.Confirmation}
        onClose={() => handleClose()}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={mode === ModalMode.Confirmation}>
          <React.Fragment>
            <Paper className={classes.modalContent}>
              <Grid container={true} spacing={3} direction="column">
                <Grid item={true}>
                  <Typography variant="h5" component="h3">
                    Enquête de satisfaction
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography component="div" className={classes.typography}>
                    Merci pour votre participation.
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  container={true}
                  spacing={3}
                  direction="row"
                  alignContent="center"
                  justifyContent="flex-end"
                  wrap="wrap-reverse"
                >
                  <Button
                    className={classes.buttonModal}
                    variant="contained"
                    color="primary"
                    style={{ color: "#FFF" }}
                    onClick={() => handleClose()}
                  >
                    Fermer
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(SurveyModal);
