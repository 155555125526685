import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Json: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};




export enum AccessType {
  Free = 'FREE',
  Premium = 'PREMIUM',
  FreeComplete = 'FREE_COMPLETE'
}

export type AcquiredLevel = {
  __typename?: 'AcquiredLevel';
  acquiredAt: Scalars['DateTime'];
  levelId: Scalars['String'];
  level: TrophyLevel;
};

export type ActivateCodeOutput_2402 = {
  __typename?: 'ActivateCodeOutput_2402';
  code: Code_2402;
  subscriptions: Array<SubscriptionData>;
  prepaidGoods: Array<PrepaidGood_2402>;
};

export type ActivationCodePrice_2402 = PriceInterface_2402 & {
  __typename?: 'ActivationCodePrice_2402';
  _id: Scalars['String'];
  kind: PriceKind_2402;
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  tenant: TenantType;
  amount: Scalars['Int'];
  currency: CurrencyType_2402;
  period?: Maybe<PricePeriod_2402>;
  externalReference: PriceExternalReference_2402;
  productId: Scalars['String'];
  product_2402: Product_2402;
  roles?: Maybe<Array<Role>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ActivitiesSlugs = {
  __typename?: 'ActivitiesSlugs';
  slugs: Array<Scalars['String']>;
};

export type ActivitiesSlugsV2 = {
  __typename?: 'ActivitiesSlugsV2';
  data: Array<ActivitySlug>;
};

export type ActivitySlug = {
  __typename?: 'ActivitySlug';
  slug: Scalars['String'];
  products: Array<GutenbergProduct>;
};

export enum ActivityType {
  Lesson = 'LESSON',
  Quiz = 'QUIZ',
  Flashcardv2 = 'FLASHCARDV2',
  PastPaper = 'PAST_PAPER',
  Brief = 'BRIEF'
}

export enum ActivityType_2406 {
  Brief = 'BRIEF',
  Flashcardv2 = 'FLASHCARDV2',
  Lesson = 'LESSON',
  Quiz = 'QUIZ',
  TrainingPaper = 'TRAINING_PAPER'
}

export type AddPostClapsOutput = {
  __typename?: 'AddPostClapsOutput';
  post: Post;
};

export type Address = {
  __typename?: 'Address';
  streetNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  shippingInstructions?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  streetNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  shippingInstructions?: Maybe<Scalars['String']>;
};

export type AdminReservationInput = {
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
  ssoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type AdminSubscriptionListInput = {
  ssoIds?: Maybe<Array<Scalars['String']>>;
};

export type AndroidSubscriptionPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: 'AndroidSubscriptionPrepaidGoodSource';
  type: PrepaidGoodSourceType;
  androidSubscriptionId: Scalars['String'];
};

export type AndroidSubscriptionPrepaidGoodSource_2402 = PrepaidGoodSource_2402 & {
  __typename?: 'AndroidSubscriptionPrepaidGoodSource_2402';
  type: PrepaidGoodSourceType_2402;
  androidSubscriptionId: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  _id: Scalars['String'];
  learningId: Scalars['Int'];
  audioText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  isCorrect: Scalars['Boolean'];
  /**
   * # Deprecated
   * Use `gapIndex`
   * @deprecated Use `gapIndex`
   */
  index?: Maybe<Scalars['String']>;
  gapIndex?: Maybe<Scalars['String']>;
};

export type AnswerCreateInput_2406 = {
  text?: Maybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  gapIndex?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type AnswerInput = {
  _id?: Maybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  audioText?: Maybe<Scalars['String']>;
  gapIndex?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type AnswerUpdateInput_2406 = {
  text?: Maybe<Scalars['String']>;
  isCorrect: Scalars['Boolean'];
  gapIndex?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type Answer_2406 = {
  __typename?: 'Answer_2406';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
  isCorrect: Scalars['Boolean'];
  gapIndex?: Maybe<Scalars['String']>;
};

export type AnyFlashcardV2 = BacFrenchStylisticDeviceFlashcard | BacHistoryLandmarkFlashcard | BacPhilosophyQuoteFlashcard | BrevetChemistryMoleculeFlashcard | BrevetFrenchStylisticDeviceFlashcard | BrevetHistoryLandmarkFlashcard | CdrRoadSignFlashcard | FeuVertRoadSignFlashcard | FleAfasVocabularyFlashcard | FleCcfsVocabularyFlashcard | FleEfemVocabularyFlashcard | FleJobsFlashcard | FleVocabularyFlashcard | MosalinguaFlashcard;

export enum ApiProduct {
  Primaire = 'PRIMAIRE',
  College = 'COLLEGE',
  Lycee = 'LYCEE',
  Bts = 'BTS',
  Toeic = 'TOEIC',
  Toefl = 'TOEFL',
  Concours = 'CONCOURS',
  Fle = 'FLE',
  Orthographe = 'ORTHOGRAPHE',
  CodeAuto = 'CODE_AUTO',
  CodeMoto = 'CODE_MOTO',
  CodeBateauCote = 'CODE_BATEAU_COTE',
  Orientation = 'ORIENTATION',
  Alternance = 'ALTERNANCE',
  CleaNumerique = 'CLEA_NUMERIQUE',
  Mosalingua = 'MOSALINGUA',
  Voltaire = 'VOLTAIRE',
  Secours = 'SECOURS',
  FleAfas = 'FLE_AFAS',
  FleCcfs = 'FLE_CCFS',
  FleEfem = 'FLE_EFEM',
  FeuVert = 'FEU_VERT',
  RfFormation = 'RF_FORMATION',
  ActuaCac = 'ACTUA_CAC'
}

export type Area = {
  __typename?: 'Area';
  id: Scalars['String'];
  name: Scalars['String'];
  coordinates: AreaCoordinates;
};

export type AreaCoordinates = {
  __typename?: 'AreaCoordinates';
  topLeft: Array<Scalars['Int']>;
  bottomRight: Array<Scalars['Int']>;
};

export type AssetInput = {
  okulusId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<AssetType>;
  data?: Maybe<AssetInputData>;
};

export type AssetInputData = {
  thumbnail_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AssetLink = {
  __typename?: 'AssetLink';
  type?: Maybe<Scalars['String']>;
  media?: Maybe<Media>;
};

export enum AssetType {
  Okulus = 'okulus',
  Embed = 'embed'
}

export type AssetWrapperInput = {
  type: AssetWrapperType;
  media: AssetInput;
};

export enum AssetWrapperType {
  InternalAttachement = 'INTERNAL_ATTACHEMENT',
  ExternalAttachment = 'EXTERNAL_ATTACHMENT',
  View = 'VIEW'
}

export type Attachment = {
  __typename?: 'Attachment';
  name: Scalars['String'];
  content: Scalars['String'];
};

export type AttachmentInput = {
  name: Scalars['String'];
  content: Scalars['String'];
};

export enum AuthUserRole {
  Admin = 'ADMIN',
  Reviewer = 'REVIEWER',
  User = 'USER',
  Unknown = 'UNKNOWN'
}

export enum Availability {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type AvailableReviewsInput = {
  targetLanguage?: Maybe<Language>;
  since?: Maybe<Scalars['String']>;
  until?: Maybe<Scalars['String']>;
  product: ApiProduct;
};

export type BacFrenchStylisticDeviceFlashcard = FlashcardV2 & {
  __typename?: 'BacFrenchStylisticDeviceFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<BacFrenchStylisticDeviceFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type BacFrenchStylisticDeviceFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type BacFrenchStylisticDeviceFlashcardContent = {
  __typename?: 'BacFrenchStylisticDeviceFlashcardContent';
  definition?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  stylisticDevice?: Maybe<Scalars['String']>;
};

export type BacHistoryLandmarkFlashcard = FlashcardV2 & {
  __typename?: 'BacHistoryLandmarkFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<BacHistoryLandmarkFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type BacHistoryLandmarkFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type BacHistoryLandmarkFlashcardContent = {
  __typename?: 'BacHistoryLandmarkFlashcardContent';
  landmark?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type BacPhilosophyQuoteFlashcard = FlashcardV2 & {
  __typename?: 'BacPhilosophyQuoteFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<BacPhilosophyQuoteFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type BacPhilosophyQuoteFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type BacPhilosophyQuoteFlashcardContent = {
  __typename?: 'BacPhilosophyQuoteFlashcardContent';
  quote?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  gender: Gender;
  address: Address;
};

export type BillingAddressInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
};

export enum BookmarkProduct {
  Codedelaroute = 'codedelaroute',
  Feuvert = 'feuvert',
  Superbac = 'superbac',
  Superbrevet = 'superbrevet',
  Rfformation = 'rfformation',
  Actuacac = 'actuacac',
  Projetvoltaire = 'projetvoltaire',
  Fle = 'fle',
  Toeic = 'toeic',
  Orthographe = 'orthographe',
  Mosaseries = 'mosaseries',
  Concours = 'concours',
  Primaire = 'primaire',
  Bts = 'bts',
  College = 'college',
  Lycee = 'lycee',
  Bateau = 'bateau',
  Auto = 'auto',
  Moto = 'moto',
  Culturegenerale = 'culturegenerale',
  Afas = 'afas',
  Ccfs = 'ccfs',
  Efem = 'efem'
}

export enum BookmarkResult {
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export enum BookmarkSection {
  Auto = 'AUTO',
  Moto = 'MOTO',
  Cyclo = 'CYCLO',
  Tpr = 'TPR',
  SecondeGenerale = 'SECONDE_GENERALE',
  PremiereGenerale = 'PREMIERE_GENERALE',
  TerminaleGenerale = 'TERMINALE_GENERALE',
  TerminaleSt2S = 'TERMINALE_ST2S',
  PremiereSt2S = 'PREMIERE_ST2S',
  TerminaleSti2D = 'TERMINALE_STI2D',
  PremiereSti2D = 'PREMIERE_STI2D',
  TerminaleStl = 'TERMINALE_STL',
  PremiereStl = 'PREMIERE_STL',
  TerminaleStmg = 'TERMINALE_STMG',
  PremiereStmg = 'PREMIERE_STMG',
  BacPro = 'BAC_PRO',
  Sixieme = 'SIXIEME',
  Cinquieme = 'CINQUIEME',
  Quatrieme = 'QUATRIEME',
  Troisieme = 'TROISIEME',
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  Anglais = 'ANGLAIS',
  Francais = 'FRANCAIS',
  Espagnol = 'ESPAGNOL',
  Allemand = 'ALLEMAND',
  Italien = 'ITALIEN',
  Portugais = 'PORTUGAIS',
  MiddleSchool = 'MIDDLE_SCHOOL',
  HighSchool = 'HIGH_SCHOOL',
  Student = 'STUDENT',
  Professional = 'PROFESSIONAL',
  JobSeeker = 'JOB_SEEKER',
  Cp = 'CP',
  Ce1 = 'CE1',
  Ce2 = 'CE2',
  Cm1 = 'CM1',
  Cm2 = 'CM2',
  BtsAmenagementFinition = 'BTS_AMENAGEMENT_FINITION',
  BtsAnalysesDeBiologieMedicale = 'BTS_ANALYSES_DE_BIOLOGIE_MEDICALE',
  BtsArchitecturesEnMetalConceptionEtRealisation = 'BTS_ARCHITECTURES_EN_METAL_CONCEPTION_ET_REALISATION',
  BtsAssistanceTechniqueDIngenieur = 'BTS_ASSISTANCE_TECHNIQUE_D_INGENIEUR',
  BtsAssurance = 'BTS_ASSURANCE',
  BtsAeronautique = 'BTS_AERONAUTIQUE',
  BtsaAgronomieProductionsVegetales = 'BTSA_AGRONOMIE_PRODUCTIONS_VEGETALES',
  BtsaAmenagementsPaysagers = 'BTSA_AMENAGEMENTS_PAYSAGERS',
  BtsaAnalyseConduiteEtStrategieDeLEntrepriseAgricole = 'BTSA_ANALYSE_CONDUITE_ET_STRATEGIE_DE_L_ENTREPRISE_AGRICOLE',
  BtsaAnalysesAgricolesBiologiquesEtBiotechnologies = 'BTSA_ANALYSES_AGRICOLES_BIOLOGIQUES_ET_BIOTECHNOLOGIES',
  BtsaAquaculture = 'BTSA_AQUACULTURE',
  BtsBanqueConseillerDeClientele = 'BTS_BANQUE_CONSEILLER_DE_CLIENTELE',
  BtsBioanalysesEtContr = 'BTS_BIOANALYSES_ET_CONTR',
  BtsBiotechnologies = 'BTS_BIOTECHNOLOGIES',
  BtsBatiment = 'BTS_BATIMENT',
  BtsEnInformatique = 'BTS_EN_INFORMATIQUE',
  BtsEnSecretariat = 'BTS_EN_SECRETARIAT',
  BtsInnovationTextile = 'BTS_INNOVATION_TEXTILE',
  BtsManagementOperationnelDeLaSecurite = 'BTS_MANAGEMENT_OPERATIONNEL_DE_LA_SECURITE',
  BtsMetiersDeLaModeChaussureEtMaroquinerie = 'BTS_METIERS_DE_LA_MODE_CHAUSSURE_ET_MAROQUINERIE',
  BtsCommerceInternationalAReferentielCommunEuropeen = 'BTS_COMMERCE_INTERNATIONAL_A_REFERENTIEL_COMMUN_EUROPEEN',
  BtsCommunication = 'BTS_COMMUNICATION',
  BtsComptabiliteEtGestion = 'BTS_COMPTABILITE_ET_GESTION',
  BtsConceptionDesProcessusDeDecoupeEtDEmboutissage = 'BTS_CONCEPTION_DES_PROCESSUS_DE_DECOUPE_ET_D_EMBOUTISSAGE',
  BtsConceptionDesProcessusDeRealisationDeProduits = 'BTS_CONCEPTION_DES_PROCESSUS_DE_REALISATION_DE_PRODUITS',
  BtsConceptionDesProduitsIndustriels = 'BTS_CONCEPTION_DES_PRODUITS_INDUSTRIELS',
  BtsConceptionEtIndustrialisationEnConstructionNavale = 'BTS_CONCEPTION_ET_INDUSTRIALISATION_EN_CONSTRUCTION_NAVALE',
  BtsConceptionEtIndustrialisationEnMicrotechniques = 'BTS_CONCEPTION_ET_INDUSTRIALISATION_EN_MICROTECHNIQUES',
  BtsConceptionEtRealisationDeCarrosseries = 'BTS_CONCEPTION_ET_REALISATION_DE_CARROSSERIES',
  BtsConceptionEtRealisationDeSystemesAutomatiques = 'BTS_CONCEPTION_ET_REALISATION_DE_SYSTEMES_AUTOMATIQUES',
  BtsConceptionEtRealisationEnChaudronnerieIndustrielle = 'BTS_CONCEPTION_ET_REALISATION_EN_CHAUDRONNERIE_INDUSTRIELLE',
  BtsControleDesRayonnementsIonisantsEtApplicationDesTechniquesDeProtection = 'BTS_CONTROLE_DES_RAYONNEMENTS_IONISANTS_ET_APPLICATION_DES_TECHNIQUES_DE_PROTECTION',
  BtsControleIndustrielEtRegulationAutomatique = 'BTS_CONTROLE_INDUSTRIEL_ET_REGULATION_AUTOMATIQUE',
  BtsDietetique = 'BTS_DIETETIQUE',
  BtsDeveloppementEtRealisationBois = 'BTS_DEVELOPPEMENT_ET_REALISATION_BOIS',
  BtsaDeveloppementDeLAgricultureDesRegionsChaudes = 'BTSA_DEVELOPPEMENT_DE_L_AGRICULTURE_DES_REGIONS_CHAUDES',
  BtsaDeveloppementAnimationDesTerritoiresRuraux = 'BTSA_DEVELOPPEMENT_ANIMATION_DES_TERRITOIRES_RURAUX',
  BtsEnveloppeDesB = 'BTS_ENVELOPPE_DES_B',
  BtsEnvironnementNucleaire = 'BTS_ENVIRONNEMENT_NUCLEAIRE',
  BtsEuroplasticsEtComposites = 'BTS_EUROPLASTICS_ET_COMPOSITES',
  BtsEconomieSocialeEtFamiliale = 'BTS_ECONOMIE_SOCIALE_ET_FAMILIALE',
  BtsEdition = 'BTS_EDITION',
  BtsElectrotechnique = 'BTS_ELECTROTECHNIQUE',
  BtsEtudeEtRealisationDAgencement = 'BTS_ETUDE_ET_REALISATION_D_AGENCEMENT',
  BtsEtudesDeRealisationDUnProjetDeCommunication = 'BTS_ETUDES_DE_REALISATION_D_UN_PROJET_DE_COMMUNICATION',
  BtsEtudesEtTravauxGeographiques = 'BTS_ETUDES_ET_TRAVAUX_GEOGRAPHIQUES',
  BtsEtudesEtEconomieDeLaConstruction = 'BTS_ETUDES_ET_ECONOMIE_DE_LA_CONSTRUCTION',
  BtsFluidesEnergiesDomotique = 'BTS_FLUIDES_ENERGIES_DOMOTIQUE',
  BtsFonderie = 'BTS_FONDERIE',
  BtsForge = 'BTS_FORGE',
  BtsGestionDeLaPme = 'BTS_GESTION_DE_LA_PME',
  BtsGeologieAppliquee = 'BTS_GEOLOGIE_APPLIQUEE',
  BtsaGestionEtMa = 'BTSA_GESTION_ET_MA',
  BtsaGestionEtProtectionDeLaNature = 'BTSA_GESTION_ET_PROTECTION_DE_LA_NATURE',
  BtsaGestionForestiere = 'BTSA_GESTION_FORESTIERE',
  BtsaGenieDesEquipementsAgricoles = 'BTSA_GENIE_DES_EQUIPEMENTS_AGRICOLES',
  BtsIndustriesCeramiques = 'BTS_INDUSTRIES_CERAMIQUES',
  BtsMaintenanceDesMaterielsDeConstructionEtDeManutention = 'BTS_MAINTENANCE_DES_MATERIELS_DE_CONSTRUCTION_ET_DE_MANUTENTION',
  BtsMaintenanceDesSystemes = 'BTS_MAINTENANCE_DES_SYSTEMES',
  BtsMaintenanceDesVehicules = 'BTS_MAINTENANCE_DES_VEHICULES',
  BtsMco = 'BTS_MCO',
  BtsManagementEnHotellerieRestauration = 'BTS_MANAGEMENT_EN_HOTELLERIE_RESTAURATION',
  BtsMasen = 'BTS_MASEN',
  BtsPgem = 'BTS_PGEM',
  BtsMoteursACombustionInterne = 'BTS_MOTEURS_A_COMBUSTION_INTERNE',
  BtsMetiersDeLEsthetiqueCosmetiqueParfumerie = 'BTS_METIERS_DE_L_ESTHETIQUE_COSMETIQUE_PARFUMERIE',
  BtsMetiersDeLaChimie = 'BTS_METIERS_DE_LA_CHIMIE',
  BtsMetiersDeLaCoiffure = 'BTS_METIERS_DE_LA_COIFFURE',
  BtsMetiersDeLaModeV = 'BTS_METIERS_DE_LA_MODE_V',
  BtsMetiersDeLAudiovisuel = 'BTS_METIERS_DE_L_AUDIOVISUEL',
  BtsMetiersDeLEau = 'BTS_METIERS_DE_L_EAU',
  BtsMetiersDesServicesAlEnvironnement = 'BTS_METIERS_DES_SERVICES_AL_ENVIRONNEMENT',
  BtsMetiersDuGeometreTopographeEtDeLaModelisationNumerique = 'BTS_METIERS_DU_GEOMETRE_TOPOGRAPHE_ET_DE_LA_MODELISATION_NUMERIQUE',
  BtsNotariat = 'BTS_NOTARIAT',
  BtsNegociationEtDigitalisationDeLaRelationClient = 'BTS_NEGOCIATION_ET_DIGITALISATION_DE_LA_RELATION_CLIENT',
  BtsOpticienLunetier = 'BTS_OPTICIEN_LUNETIER',
  BtsPhotographie = 'BTS_PHOTOGRAPHIE',
  BtsPilotageDeProcedes = 'BTS_PILOTAGE_DE_PROCEDES',
  BtsPodoOrthesiste = 'BTS_PODO_ORTHESISTE',
  BtsProfessionsImmobilieres = 'BTS_PROFESSIONS_IMMOBILIERES',
  BtsProthesisteDentaire = 'BTS_PROTHESISTE_DENTAIRE',
  BtsProthesisteOrthesiste = 'BTS_PROTHESISTE_ORTHESISTE',
  BtsaProductionHorticole = 'BTSA_PRODUCTION_HORTICOLE',
  BtsaProductionsAnimales = 'BTSA_PRODUCTIONS_ANIMALES',
  BtsBioqualite = 'BTS_BIOQUALITE',
  BtsServicesEtPrestationsDesSecteursSanitaireEtSocial = 'BTS_SERVICES_ET_PRESTATIONS_DES_SECTEURS_SANITAIRE_ET_SOCIAL',
  BtsSioSisr = 'BTS_SIO_SISR',
  BtsSioSlam = 'BTS_SIO_SLAM',
  BtsSupportAlActionManageriale = 'BTS_SUPPORT_AL_ACTION_MANAGERIALE',
  BtsSystemesConstructifsBoisEtHabitat = 'BTS_SYSTEMES_CONSTRUCTIFS_BOIS_ET_HABITAT',
  BtsSystemesNumeriques = 'BTS_SYSTEMES_NUMERIQUES',
  BtsSystemesPhotoniques = 'BTS_SYSTEMES_PHOTONIQUES',
  BtsaSciencesEtTechnologiesDesAliments = 'BTSA_SCIENCES_ET_TECHNOLOGIES_DES_ALIMENTS',
  BtsGestionDesTransportsEtLogistiqueAssociee = 'BTS_GESTION_DES_TRANSPORTS_ET_LOGISTIQUE_ASSOCIEE',
  BtsTechnicoCommercial = 'BTS_TECHNICO_COMMERCIAL',
  BtsTechniquesEtServicesEnMaterielsAgricoles = 'BTS_TECHNIQUES_ET_SERVICES_EN_MATERIELS_AGRICOLES',
  BtsTechniquesPhysiquesPourLIndustrieEtLeLaboratoire = 'BTS_TECHNIQUES_PHYSIQUES_POUR_L_INDUSTRIE_ET_LE_LABORATOIRE',
  BtsTourisme = 'BTS_TOURISME',
  BtsTraitementDesMateriaux = 'BTS_TRAITEMENT_DES_MATERIAUX',
  BtsTravauxPublics = 'BTS_TRAVAUX_PUBLICS',
  BtsaTechnicoCommercial = 'BTSA_TECHNICO_COMMERCIAL',
  BtsaViticultureOenologie = 'BTSA_VITICULTURE_OENOLOGIE'
}

export enum BookmarkStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Done = 'done'
}

export enum BookmarkType {
  Standard = 'standard',
  MockExam = 'mock_exam',
  FeuvertMortSubite = 'feuvert_mort_subite',
  MortSubite = 'mort_subite',
  PlacementTest = 'placement_test',
  ProgressionTest = 'progression_test',
  SuperQuiz = 'super_quiz'
}

export type BrevetChemistryMoleculeFlashcard = FlashcardV2 & {
  __typename?: 'BrevetChemistryMoleculeFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<BrevetChemistryMoleculeFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type BrevetChemistryMoleculeFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type BrevetChemistryMoleculeFlashcardContent = {
  __typename?: 'BrevetChemistryMoleculeFlashcardContent';
  name?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  composition?: Maybe<Scalars['String']>;
  model?: Maybe<File>;
};

export type BrevetFrenchStylisticDeviceFlashcard = FlashcardV2 & {
  __typename?: 'BrevetFrenchStylisticDeviceFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<BrevetFrenchStylisticDeviceFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type BrevetFrenchStylisticDeviceFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type BrevetFrenchStylisticDeviceFlashcardContent = {
  __typename?: 'BrevetFrenchStylisticDeviceFlashcardContent';
  definition?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  stylisticDevice?: Maybe<Scalars['String']>;
};

export type BrevetHistoryLandmarkFlashcard = FlashcardV2 & {
  __typename?: 'BrevetHistoryLandmarkFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<BrevetHistoryLandmarkFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type BrevetHistoryLandmarkFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type BrevetHistoryLandmarkFlashcardContent = {
  __typename?: 'BrevetHistoryLandmarkFlashcardContent';
  landmark?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type Brief = {
  __typename?: 'Brief';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  slugV2: Scalars['String'];
  html?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  status: Status;
  products: Array<GutenbergProduct>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  parentCategories: Array<Category>;
};

export type BriefsFromCategorySearchInput = {
  status?: Maybe<Status>;
};

export type BriefsSearchInput = {
  learningIds?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<Status>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Cart = {
  __typename?: 'Cart';
  _id: Scalars['String'];
  ssoId: Scalars['String'];
  customerId: Scalars['String'];
  lines: Array<Maybe<CartLine>>;
  coupon?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  discount: Scalars['Int'];
  createdAt?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
};

export type CartInput = {
  customerEmail?: Maybe<Scalars['String']>;
  lines: Array<Maybe<CartLineInput>>;
  coupon?: Maybe<Scalars['String']>;
  metadata?: Maybe<CartMetadata>;
};

export type CartIntent = {
  __typename?: 'CartIntent';
  id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
};

export type CartLine = {
  __typename?: 'CartLine';
  quantity: Scalars['Int'];
  item: Scalars['String'];
};

export type CartLineInput = {
  quantity: Scalars['Int'];
  item: Scalars['String'];
};

export type CartLineV2Input = {
  quantity: Scalars['Int'];
  priceId: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
};

export type CartMetadata = {
  reservationId: Scalars['String'];
};

export type CartPatchInput = {
  id: Scalars['String'];
  lines: Array<Maybe<CartLineInput>>;
  coupon?: Maybe<Scalars['String']>;
  metadata?: Maybe<CartMetadata>;
};

export type CartPatchV2Input = {
  id: Scalars['String'];
  lines: Array<CartLineV2Input>;
  couponCode?: Maybe<Scalars['String']>;
};

export type CartPaypalApprove = {
  __typename?: 'CartPaypalApprove';
  approvalUrls: Array<Scalars['String']>;
};

export type CartPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: 'CartPrepaidGoodSource';
  type: PrepaidGoodSourceType;
  cartId: Scalars['String'];
};

export type CartPrepaidGoodSource_2402 = PrepaidGoodSource_2402 & {
  __typename?: 'CartPrepaidGoodSource_2402';
  type: PrepaidGoodSourceType_2402;
  cartId: Scalars['String'];
};

export type CartStripeIntent = {
  __typename?: 'CartStripeIntent';
  id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
};

export type CartStripeIntentV2 = CartStripePaymentIntent | CartStripeSetupIntent;

export type CartStripePaymentIntent = {
  __typename?: 'CartStripePaymentIntent';
  id: Scalars['String'];
  client_secret: Scalars['String'];
};

export type CartStripeSetupIntent = {
  __typename?: 'CartStripeSetupIntent';
  id: Scalars['String'];
  client_secret: Scalars['String'];
};

export type CartV2 = {
  __typename?: 'CartV2';
  _id: Scalars['String'];
  ssoId?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  lines: Array<CartV2Line>;
  couponId?: Maybe<Scalars['String']>;
  coupon?: Maybe<Coupon>;
  totalPrice: Scalars['Int'];
  discount: Scalars['Int'];
  createdAt: Scalars['String'];
  resolvedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  tenant: TenantType;
  paymentProvider?: Maybe<SubscriptionProviderType>;
  paymentProviderEventId?: Maybe<Scalars['String']>;
};

export type CartV2Input = {
  customerEmail?: Maybe<Scalars['String']>;
  lines: Array<CartLineV2Input>;
  couponCode?: Maybe<Scalars['String']>;
};

export type CartV2Line = {
  __typename?: 'CartV2Line';
  quantity: Scalars['Int'];
  reservationId?: Maybe<Scalars['String']>;
  reservation?: Maybe<Reservation>;
  priceId: Scalars['String'];
  /** @deprecated Use price_2402 instead */
  price: PriceUnion;
  price_2402: PriceUnion_2402;
};


export type CartV2LinePriceArgs = {
  tenant?: Maybe<TenantType>;
};

export type CartV2Metadata = {
  reservationId: Scalars['String'];
};

export type CartV2PrepaidGoodSource_2402 = PrepaidGoodSource_2402 & {
  __typename?: 'CartV2PrepaidGoodSource_2402';
  type: PrepaidGoodSourceType_2402;
  cartV2Id: Scalars['String'];
};

export type CartsInput = {
  isResolved?: Maybe<Scalars['Boolean']>;
};

export type CartsV2Input = {
  isResolved?: Maybe<Scalars['Boolean']>;
};

export type CategoriesChildrenDataV2SearchInput = {
  status?: Maybe<Status>;
  platform?: Maybe<CategoryPlatformFilter>;
};

export type CategoriesV2SearchInput = {
  parentIdOrPath?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  platform?: Maybe<CategoryPlatformFilter>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type Category = {
  __typename?: 'Category';
  _id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  path: Scalars['String'];
  learningId: Scalars['Int'];
  status: Status;
  accessType: AccessType;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  sourceLanguage?: Maybe<Language>;
  parent?: Maybe<Category>;
  children?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** **DEPRECATED:** use `childrenDataV2` instead */
  childrensData?: Maybe<Array<Maybe<Category>>>;
  childrenDataV2?: Maybe<Array<Maybe<Category>>>;
  briefs?: Maybe<Array<Maybe<Brief>>>;
  /** @deprecated Use `flashcardsV2` field instead */
  flashcards?: Maybe<Array<Maybe<Flashcard>>>;
  flashcardsV2?: Maybe<Array<Maybe<FlashcardV2>>>;
  flashcardsV2Raw?: Maybe<Array<Maybe<FlashcardV2Raw_2411>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  /** Actually contains the training papers (both the official and unofficial papers), not only the past papers (= official ones) but name did not change for compatibility reasons. */
  pastPapers?: Maybe<Array<Maybe<PastPaper>>>;
  trainingPapers_2406?: Maybe<Array<Maybe<TrainingPaper_2406>>>;
  quizzes?: Maybe<Array<Maybe<Quiz>>>;
  /** @deprecated Always empty */
  rightOfWayTests?: Maybe<Array<Maybe<RightOfWayTest>>>;
  briefsIds?: Maybe<Array<Scalars['String']>>;
  flashcardsV2Ids?: Maybe<Array<Scalars['String']>>;
  lessonsIds?: Maybe<Array<Scalars['String']>>;
  /** @deprecated Alias for `trainingPapersIds` */
  pastPapersIds?: Maybe<Array<Scalars['String']>>;
  trainingPapersIds?: Maybe<Array<Scalars['String']>>;
  quizzesIds?: Maybe<Array<Scalars['String']>>;
  /**
   * **DEPRECATED:** Returns flashcards from Feu Vert exclusively. Use `activitiesV2` instead
   * @deprecated Field no longer supported
   */
  activities?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  activitiesV2?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  elapsedTime?: Maybe<Time>;
  topic?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  platformAvailability: CategoryPlatformAvailability;
  description?: Maybe<Scalars['String']>;
  validityTimeRange?: Maybe<ValidityTimeRange>;
  recursiveCounts?: Maybe<RecursiveCount>;
  userProgress?: Maybe<CategoryProgress>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
  fullPath: Scalars['String'];
};


export type CategoryChildrensDataArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderDirection>;
};


export type CategoryChildrenDataV2Args = {
  search?: Maybe<CategoriesChildrenDataV2SearchInput>;
};


export type CategoryBriefsArgs = {
  search?: Maybe<BriefsFromCategorySearchInput>;
};


export type CategoryFlashcardsV2Args = {
  search?: Maybe<FlashcardsV2FromCategoryV2SearchInput>;
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};


export type CategoryFlashcardsV2RawArgs = {
  search?: Maybe<FlashcardsV2RawFromCategorySearchInput_2411>;
};


export type CategoryLessonsArgs = {
  search?: Maybe<LessonsFromCategoryV2SearchInput>;
};


export type CategoryPastPapersArgs = {
  search?: Maybe<TrainingPapersFromCategoryV2SearchInput>;
};


export type CategoryTrainingPapers_2406Args = {
  search?: Maybe<TrainingPapersFromCategorySearchInput_2406>;
};


export type CategoryQuizzesArgs = {
  search?: Maybe<QuizzesFromCategoryV2SearchInput>;
};


export type CategoryActivitiesArgs = {
  order?: Maybe<OrderDirection>;
};


export type CategoryActivitiesV2Args = {
  search?: Maybe<CategoryActivitiesV2SearchInput>;
  order?: Maybe<OrderDirection>;
};


export type CategoryElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};

export type CategoryActivitiesV2SearchInput = {
  status?: Maybe<Status>;
};

export type CategoryConstraint = {
  __typename?: 'CategoryConstraint';
  categories?: Maybe<Array<Scalars['String']>>;
  count?: Maybe<Scalars['Int']>;
  maxCount?: Maybe<Scalars['Int']>;
};

export type CategoryConstraintInput = {
  categories?: Maybe<Array<Scalars['String']>>;
  count?: Maybe<Scalars['Int']>;
  maxCount?: Maybe<Scalars['Int']>;
};

export type CategoryConstraintInput_2406 = {
  categories: Array<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  maxCount?: Maybe<Scalars['Int']>;
};

export type CategoryFilter = {
  status?: Maybe<Status>;
  lessons?: Maybe<Scalars['String']>;
  quizzes?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentPath?: Maybe<Scalars['String']>;
};

export type CategoryInput = {
  parentCategory: Scalars['String'];
  title: Scalars['String'];
  status: Status;
  platformAvailability: CategoryPlatformAvailabilityInput;
  topic?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validityTimeRange?: Maybe<ValidityTimeRangeInput>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type CategoryPlatformAvailability = {
  __typename?: 'CategoryPlatformAvailability';
  web: Availability;
  mobile: Availability;
};

export type CategoryPlatformAvailabilityInput = {
  web: Availability;
  mobile: Availability;
};

export enum CategoryPlatformFilter {
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export type CategoryProgress = {
  __typename?: 'CategoryProgress';
  progress: Scalars['Float'];
};

export type CategoryProgressInput = {
  categoryId: Scalars['String'];
};

export type CdrRoadSignFlashcard = FlashcardV2 & {
  __typename?: 'CdrRoadSignFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<CdrRoadSignFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type CdrRoadSignFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type CdrRoadSignFlashcardContent = {
  __typename?: 'CdrRoadSignFlashcardContent';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  roadSign?: Maybe<File>;
};

export type ChildUsersInput = {
  client: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type CleaAttempt = {
  __typename?: 'CleaAttempt';
  attempt: Scalars['Int'];
};

export type CleaAttemptCountdown = {
  __typename?: 'CleaAttemptCountdown';
  attemptCountdown: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CleaAttemptSuccess = {
  __typename?: 'CleaAttemptSuccess';
  attempt: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type CleaEditedCv = {
  __typename?: 'CleaEditedCv';
  content: Scalars['String'];
};

export type CleaHelpPopup = {
  __typename?: 'CleaHelpPopup';
  contactDisplayed: Scalars['Boolean'];
  mapDisplayed: Scalars['Boolean'];
  shareArticleDisplayed: Scalars['Boolean'];
  uploadDisplayed: Scalars['Boolean'];
};

export type CleaPagination = {
  __typename?: 'CleaPagination';
  next?: Maybe<Scalars['String']>;
  previous?: Maybe<Scalars['String']>;
};

export type CleaQuestions = {
  __typename?: 'CleaQuestions';
  codeFound: Scalars['Boolean'];
  codeMailOpened: Scalars['Boolean'];
  contactMailOpened: Scalars['Boolean'];
  companyInfo: CleaAttemptSuccess;
  companyName: CleaAttemptSuccess;
  contactJob: CleaAttemptSuccess;
  findContact: CleaAttemptSuccess;
  sharedArticle: CleaAttemptSuccess;
  wrongCVUploadCount: Scalars['Int'];
  editedCV: CleaEditedCv;
  helpPopup: CleaHelpPopup;
  introChat: CleaAttempt;
  trap: CleaTrap;
};

export type CleaReservation = {
  __typename?: 'CleaReservation';
  _id: Scalars['ID'];
  ssoId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  idCompte?: Maybe<Scalars['Int']>;
  idReservation?: Maybe<Scalars['Int']>;
  idSession?: Maybe<Scalars['Int']>;
  numeroDePassage?: Maybe<Scalars['String']>;
  civilite?: Maybe<Scalars['String']>;
  nom?: Maybe<Scalars['String']>;
  prenom?: Maybe<Scalars['String']>;
  dateNaissance?: Maybe<Scalars['String']>;
  emailDestinataireSupplementaire?: Maybe<Scalars['String']>;
  typeActivite?: Maybe<Scalars['String']>;
  statut?: Maybe<Scalars['String']>;
  birthName?: Maybe<Scalars['String']>;
  codePostale?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  studyLevel?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  birthCity?: Maybe<Scalars['String']>;
  funder?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  resolutionStatus?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['String']>;
};

export type CleaReservationPaginationInput = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type CleaResult = {
  __typename?: 'CleaResult';
  userId: Scalars['String'];
  _id: Scalars['String'];
  codeTrapDisplayed: Scalars['Boolean'];
  companyUid: Scalars['Int'];
  currentStep: Scalars['String'];
  effectiveEndTime: Scalars['String'];
  finalResponse: Scalars['String'];
  questions: CleaQuestions;
  responseCompanyName?: Maybe<CleaAttemptCountdown>;
  responseFindContact: CleaAttemptCountdown;
  success?: Maybe<Scalars['Boolean']>;
  theoreticalEndTime?: Maybe<Scalars['String']>;
  timedOut?: Maybe<Scalars['Boolean']>;
};

export type CleaTrap = {
  __typename?: 'CleaTrap';
  formValidated: Scalars['Boolean'];
  popupClicked: Scalars['Boolean'];
};

export enum Client {
  Codedelaroute = 'codedelaroute',
  Toeic = 'toeic',
  Brevet = 'brevet',
  Bac = 'bac',
  Feuvert = 'feuvert',
  Digipass = 'digipass',
  Education = 'education',
  Concours = 'concours',
  Fle = 'fle',
  Orthographe = 'orthographe',
  Culturegenerale = 'culturegenerale'
}

export type Code = {
  __typename?: 'Code';
  _id: Scalars['String'];
  code: Scalars['String'];
  deal: Deal;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  activatedAt?: Maybe<Scalars['String']>;
  activatedBy?: Maybe<Scalars['String']>;
  activatedByUser?: Maybe<User>;
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
};


export type CodeActivatedByUserArgs = {
  client?: Maybe<Scalars['String']>;
};

export type CodeInput = {
  codeCount: Scalars['Int'];
  deal: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<CodeInputVariable>>;
};

export type CodeInputVariable = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CodeInputVariable_2402 = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CodeOutput = {
  __typename?: 'CodeOutput';
  codeCount: Scalars['Int'];
  deal: Scalars['String'];
  createdAt: Scalars['String'];
};

export type CodePrepaidGoodSource = PrepaidGoodSource & {
  __typename?: 'CodePrepaidGoodSource';
  type: PrepaidGoodSourceType;
  codeId: Scalars['String'];
};

export type CodePrepaidGoodSource_2402 = PrepaidGoodSource_2402 & {
  __typename?: 'CodePrepaidGoodSource_2402';
  type: PrepaidGoodSourceType_2402;
  codeId: Scalars['String'];
};

export enum CodeSearchType {
  Exact = 'exact',
  Start = 'start',
  End = 'end',
  Middle = 'middle'
}

export enum CodeSearchType_2402 {
  Exact = 'exact',
  Start = 'start',
  End = 'end',
  Middle = 'middle'
}

export type Code_2402 = {
  __typename?: 'Code_2402';
  _id: Scalars['ID'];
  code: Scalars['String'];
  deal_2402: Deal_2402;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  activatedAt?: Maybe<Scalars['String']>;
  activatedBy?: Maybe<Scalars['String']>;
  activatedByUser: User;
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
};


export type Code_2402ActivatedByUserArgs = {
  client?: Maybe<Scalars['String']>;
};

export type CodedelarouteService = {
  __typename?: 'CodedelarouteService';
  neph?: Maybe<Scalars['String']>;
};

export type CodedelarouteServiceInput = {
  neph?: Maybe<Scalars['String']>;
};

export type CodesInput_2402 = {
  searchTerm?: Maybe<Scalars['String']>;
  searchType?: Maybe<CodeSearchType_2402>;
  isActivated?: Maybe<Scalars['Boolean']>;
  deals?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['String']>;
  activatedBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<SortRule>>;
};

export type CodesV3Input = {
  searchTerm?: Maybe<Scalars['String']>;
  searchType?: Maybe<CodeSearchType>;
  isActivated?: Maybe<Scalars['Boolean']>;
  deals?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['String']>;
  activatedBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<SortRule>>;
};

export type ConsumePrepaidGoodInput_2402 = {
  id: Scalars['ID'];
  reservationId: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  tags: Array<Maybe<ReportService>>;
  locale?: Maybe<ReportLocale>;
  service: ReportService;
  email: Scalars['String'];
  platform: ReportPlatform;
  type: Scalars['String'];
  subType: Scalars['String'];
  message: Scalars['String'];
  attachments: Array<Maybe<Attachment>>;
};

export type ContactInput = {
  tags: Array<Maybe<ReportService>>;
  service: ReportService;
  locale?: Maybe<ReportLocale>;
  email: Scalars['String'];
  platform: ReportPlatform;
  type: Scalars['String'];
  subType: Scalars['String'];
  message: Scalars['String'];
  attachments: Array<Maybe<AttachmentInput>>;
};

export type Convocation = {
  __typename?: 'Convocation';
  pdf: Scalars['String'];
};

export type Coupon = {
  __typename?: 'Coupon';
  _id: Scalars['String'];
  code: Scalars['String'];
  tenant: TenantType;
  description: Scalars['String'];
  amountOff?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  duration: CouponDuration;
  durationInDays?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  valid: Scalars['Boolean'];
};

export enum CouponDuration {
  Once = 'ONCE',
  Repeating = 'REPEATING',
  Forever = 'FOREVER'
}

export type CreateBriefInput = {
  title: Scalars['String'];
  slugV2?: Maybe<Scalars['String']>;
  /** Either `html` or `markdown` field must be provided */
  html?: Maybe<Scalars['String']>;
  /** Either `html` or `markdown` field must be provided */
  markdown?: Maybe<Scalars['String']>;
  status: Status;
  products: Array<GutenbergProduct>;
  parentCategories: Array<Scalars['String']>;
};

export type CreateCodesInput_2402 = {
  codeCount: Scalars['Int'];
  deal: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  variables?: Maybe<Array<CodeInputVariable_2402>>;
};

export type CreateCodesOutput_2402 = {
  __typename?: 'CreateCodesOutput_2402';
  codeCount: Scalars['Int'];
  deal: Scalars['ID'];
  createdAt: Scalars['String'];
};

export type CreateDealInput_2402 = {
  name: Scalars['String'];
  template: Scalars['String'];
  prices: Array<Scalars['ID']>;
  partner: Scalars['ID'];
  clients: Array<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type CreatePartnerInput = {
  name: Scalars['String'];
  mentors?: Maybe<Array<Scalars['String']>>;
};

export type CreatePartnerInput_2402 = {
  name: Scalars['String'];
  mentors?: Maybe<Array<Scalars['String']>>;
};

export type CreatePostInput = {
  title: Scalars['String'];
  text: Scalars['String'];
  facets: Array<FacetUnionInput>;
};

export type CreateResponseInput = {
  text: Scalars['String'];
  postId: Scalars['String'];
};

export type CreateTimeInput = {
  userID: Scalars['String'];
  learningProduct: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
  categoryId?: Maybe<Scalars['String']>;
  learningType?: Maybe<LearningType>;
  activityId?: Maybe<Scalars['String']>;
};

export enum CurrencyType {
  Eur = 'EUR',
  Usd = 'USD'
}

export enum CurrencyType_2402 {
  Eur = 'EUR',
  Usd = 'USD'
}

export type CursorPaginatedResponse = {
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type CursorPaginatedResponseLinks = {
  __typename?: 'CursorPaginatedResponseLinks';
  first: Scalars['String'];
  previous?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['String']>;
  last: Scalars['String'];
};

export type CursorPaginatedResponseMeta = {
  __typename?: 'CursorPaginatedResponseMeta';
  itemCount: Scalars['Int'];
  totalItemCount: Scalars['Int'];
  itemsPerPage: Scalars['Int'];
  totalPages: Scalars['Int'];
  currentPage: Scalars['Int'];
};

export type CustomGeneratedQuiz = {
  __typename?: 'CustomGeneratedQuiz';
  startScore: Scalars['Int'];
  title: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maximalScore: Scalars['Int'];
  successScore?: Maybe<Scalars['Int']>;
  questionCount: Scalars['Int'];
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  exerciseList?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseListData?: Maybe<Array<Maybe<Exercise>>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  criteria?: Maybe<GeneratedQuizCriteria>;
};

export type CustomGeneratedQuiz_2406 = {
  __typename?: 'CustomGeneratedQuiz_2406';
  title: Scalars['String'];
  slugV2: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  exercises: Array<Scalars['String']>;
  exercisesData: Array<Exercise_2406>;
  startScore: Scalars['Int'];
  successScore: Scalars['Int'];
  maximalScore: Scalars['Int'];
  accessType: AccessType;
  status: Status;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  criteria: GeneratedQuizCriteria;
};


export type Deal = {
  __typename?: 'Deal';
  _id: Scalars['String'];
  name: Scalars['String'];
  template: Scalars['String'];
  /** @deprecated Use `Deal_2402.prices` type instead. This always returns an empty array */
  gains: Array<Maybe<Scalars['String']>>;
  partner: Partner;
  clients: Array<Maybe<Scalars['String']>>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  batches?: Maybe<Array<DealBatch>>;
  codes?: Maybe<PaginatedCodesOutput>;
};


export type DealCodesArgs = {
  input?: Maybe<DealCodesInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type DealBatch = {
  __typename?: 'DealBatch';
  createdAt: Scalars['String'];
  codeCount: Scalars['Int'];
};

export type DealBatch_2402 = {
  __typename?: 'DealBatch_2402';
  createdAt: Scalars['String'];
  codeCount: Scalars['Int'];
};

export type DealCodesInput = {
  status?: Maybe<VoucherStatus>;
};

export type DealCodesInput_2402 = {
  status?: Maybe<VoucherStatus_2402>;
};

export type DealInput = {
  name: Scalars['String'];
  template: Scalars['String'];
  gains: Array<Maybe<Scalars['String']>>;
  partner: Scalars['String'];
  clients: Array<Maybe<Scalars['String']>>;
  comment?: Maybe<Scalars['String']>;
};

export type Deal_2402 = {
  __typename?: 'Deal_2402';
  _id: Scalars['ID'];
  name: Scalars['String'];
  template: Scalars['String'];
  prices_2402: Array<ActivationCodePrice_2402>;
  partner_2402: Partner_2402;
  clients: Array<Maybe<Scalars['String']>>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  batches_2402: Array<DealBatch_2402>;
  codes_2402: PaginatedCodesOutput_2402;
};


export type Deal_2402Codes_2402Args = {
  input?: Maybe<DealCodesInput_2402>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type DealsInput_2402 = {
  status?: Maybe<VoucherStatus>;
  partners?: Maybe<Array<Scalars['ID']>>;
  clients?: Maybe<Array<Scalars['String']>>;
};

export type DealsV2Input = {
  status?: Maybe<VoucherStatus>;
  partners?: Maybe<Array<Scalars['String']>>;
  clients?: Maybe<Array<Scalars['String']>>;
};

export type DeletePaymentMethodInput = {
  id: Scalars['String'];
};

export type DeletionProcessState = {
  __typename?: 'DeletionProcessState';
  firstWarningSentAt?: Maybe<Scalars['Int']>;
  secondWarningSentAt?: Maybe<Scalars['Int']>;
};

export type Diploma = {
  __typename?: 'Diploma';
  slug: Scalars['String'];
  label: Scalars['String'];
  specialities?: Maybe<Array<Maybe<Speciality>>>;
};

export type DiplomaV2 = {
  __typename?: 'DiplomaV2';
  slug: Scalars['String'];
  label: Scalars['String'];
  specialities?: Maybe<Array<Speciality>>;
};

export type Domain = {
  __typename?: 'Domain';
  slug: Scalars['String'];
  label: Scalars['String'];
  sectors: Array<Maybe<Sector>>;
};

export type DomainV2 = {
  __typename?: 'DomainV2';
  slug: Scalars['String'];
  label: Scalars['String'];
  sectors: Array<Sector>;
};

export type EphemeralKey = {
  __typename?: 'EphemeralKey';
  id?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  expires?: Maybe<Scalars['Int']>;
};

export type EtsCandidate_2408 = {
  __typename?: 'EtsCandidate_2408';
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  cpfRef?: Maybe<Scalars['String']>;
  cefrLevel?: Maybe<Scalars['String']>;
};

export enum EtsVoucherStatus_2408 {
  StatusActive = 'STATUS_ACTIVE',
  StatusUsed = 'STATUS_USED',
  StatusCancelled = 'STATUS_CANCELLED',
  StatusExpired = 'STATUS_EXPIRED',
  StatusCancelledWithPrepaid = 'STATUS_CANCELLED_WITH_PREPAID',
  StatusExpiredTwChorus = 'STATUS_EXPIRED_TW_CHORUS'
}

export type EtsVoucher_2408 = {
  __typename?: 'EtsVoucher_2408';
  id: Scalars['Int'];
  purchasedAt: Scalars['String'];
  statusName: Scalars['String'];
  candidate: EtsCandidate_2408;
  status: EtsVoucherStatus_2408;
};

export type EtsVouchersInput_2408 = {
  status?: Maybe<EtsVoucherStatus_2408>;
};

export type ExcludeReviewInput = {
  flashcardId: Scalars['String'];
  targetLanguage?: Maybe<Language>;
  product: ApiProduct;
};

export type Exercise = {
  __typename?: 'Exercise';
  _id: Scalars['String'];
  learningId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  difficulty: Scalars['Int'];
  displayType: Scalars['String'];
  audioText: Scalars['String'];
  maxDurationSeconds: Scalars['Int'];
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  questions?: Maybe<Array<Maybe<Scalars['String']>>>;
  questionsData?: Maybe<Array<Maybe<Question>>>;
  containsLatex: Scalars['Boolean'];
};

export type ExerciseCreateInput_2406 = {
  text?: Maybe<Scalars['String']>;
  questions: Array<QuestionCreateInput_2406>;
  difficulty?: Maybe<Scalars['Int']>;
  containsLatex?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Array<GutenbergProduct>>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type ExerciseInput = {
  difficulty: Scalars['Int'];
  products: Array<GutenbergProduct>;
  maxDurationSeconds: Scalars['Int'];
  questions: Array<QuestionInput>;
  _id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  containsLatex?: Maybe<Scalars['Boolean']>;
  displayType?: Maybe<Scalars['String']>;
  audioText?: Maybe<Scalars['String']>;
  toDelete?: Maybe<Scalars['Boolean']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type ExerciseUpdateInput_2406 = {
  text?: Maybe<Scalars['String']>;
  questions: Array<QuestionCreateInput_2406>;
  difficulty?: Maybe<Scalars['Int']>;
  containsLatex?: Maybe<Scalars['Boolean']>;
  products: Array<GutenbergProduct>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type Exercise_2406 = {
  __typename?: 'Exercise_2406';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  questions: Array<Scalars['String']>;
  questionsData: Array<Question_2406>;
  difficulty: Scalars['Int'];
  containsLatex: Scalars['Boolean'];
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
};

export type Explanation = {
  __typename?: 'Explanation';
  text: Scalars['String'];
  audioText?: Maybe<Scalars['String']>;
  /**
   * # Deprecated
   * Use `assetLinksV2`, is broken anyway
   * @deprecated Use `assetLinksV2`, is broken anyway
   */
  assetLinks: Array<Maybe<Scalars['String']>>;
  assetLinksV2: Array<AssetLink>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
};

export type ExplanationInput = {
  text?: Maybe<Scalars['String']>;
  audioText?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type ExplanationInput_2406 = {
  text?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type Explanation_2406 = {
  __typename?: 'Explanation_2406';
  text?: Maybe<Scalars['String']>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
};

export type ExternalReference = {
  __typename?: 'ExternalReference';
  stripe?: Maybe<Reference>;
  paypal?: Maybe<Reference>;
};

export type FacetFiltersInput = {
  types?: Maybe<Array<FacetType>>;
};

export enum FacetType {
  Level = 'LEVEL',
  Subject = 'SUBJECT',
  Sector = 'SECTOR',
  Orientation = 'ORIENTATION',
  HasResponse = 'HAS_RESPONSE',
  Type = 'TYPE'
}

export type FacetUnion = PostHasResponseFacet | PostTypeFacet | PostSectorFacet | PostSubjectFacet | PostLevelFacet | PostOrientationFacet;

export type FacetUnionInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  kind: FacetType;
  hasResponse?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PostType>;
};

export type FacetsInput = {
  filter?: Maybe<FacetFiltersInput>;
};

export type FacetsPaginated = {
  __typename?: 'FacetsPaginated';
  data: Array<FacetUnion>;
};

export type FeuVertRoadSignFlashcard = FlashcardV2 & {
  __typename?: 'FeuVertRoadSignFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<FeuVertRoadSignFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type FeuVertRoadSignFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type FeuVertRoadSignFlashcardContent = {
  __typename?: 'FeuVertRoadSignFlashcardContent';
  description?: Maybe<Scalars['String']>;
  roadSign?: Maybe<File>;
};

export type File = {
  __typename?: 'File';
  /** @deprecated Use `_id` */
  id: Scalars['String'];
  _id: Scalars['String'];
  version?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  original: Scalars['String'];
  s3Url: Scalars['String'];
  s3swhUrl: Scalars['String'];
  realm: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userMetadatas?: Maybe<Scalars['Json']>;
  userTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  automaticTags?: Maybe<Scalars['Json']>;
  imageTags?: Maybe<ImageTags>;
};

export type Flashcard = {
  __typename?: 'Flashcard';
  _id: Scalars['String'];
  title: Scalars['String'];
  /** @deprecated never set */
  author?: Maybe<RestrainedUser>;
  okulusIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status: Status;
  accessType: AccessType;
  /** @deprecated never set */
  relatedActivities?: Maybe<RelatedActivities>;
  /** @deprecated never set */
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  /** @deprecated Field no longer supported */
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
};

export type FlashcardProgress = {
  __typename?: 'FlashcardProgress';
  id: Scalars['Int'];
  gutenberg_uuid: Scalars['String'];
  product?: Maybe<BookmarkProduct>;
  assimilated_count?: Maybe<Scalars['Int']>;
};

export type FlashcardProgressInput = {
  gutenberg_uuid: Scalars['String'];
  product?: Maybe<BookmarkProduct>;
  assimilated_count?: Maybe<Scalars['Int']>;
};

export type FlashcardProgressSortInput = {
  id?: Maybe<Sort>;
  created_at?: Maybe<Sort>;
};

export enum FlashcardType {
  BacFrenchStylisticDevice = 'BAC_FRENCH_STYLISTIC_DEVICE',
  BacHistoryLandmark = 'BAC_HISTORY_LANDMARK',
  BacPhilosophyQuote = 'BAC_PHILOSOPHY_QUOTE',
  BrevetChemistryMolecule = 'BREVET_CHEMISTRY_MOLECULE',
  BrevetFrenchStylisticDevice = 'BREVET_FRENCH_STYLISTIC_DEVICE',
  BrevetHistoryLandmark = 'BREVET_HISTORY_LANDMARK',
  CdrRoadSign = 'CDR_ROAD_SIGN',
  FeuVertRoadSign = 'FEU_VERT_ROAD_SIGN',
  FleJobs = 'FLE_JOBS',
  FleVocabulary = 'FLE_VOCABULARY',
  FleAfasVocabulary = 'FLE_AFAS_VOCABULARY',
  FleCcfsVocabulary = 'FLE_CCFS_VOCABULARY',
  FleEfemVocabulary = 'FLE_EFEM_VOCABULARY',
  Mosalingua = 'MOSALINGUA'
}

export type FlashcardV2 = {
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type FlashcardV2ScheduledReviewArgs = {
  product: ApiProduct;
};

export type FlashcardV2RawAsset_2411 = {
  __typename?: 'FlashcardV2RawAsset_2411';
  label: Scalars['String'];
  asset: Media;
};

export type FlashcardV2RawCreateInput_2411 = {
  flashcardType: FlashcardType;
  status: Status;
  accessType: AccessType;
  texts: Array<FlashcardV2RawTextInput_2411>;
  numbers: Array<FlashcardV2RawNumberInput_2411>;
  relatedActivities: RelatedActivitiesInput_2406;
  parentCategories: Array<Scalars['String']>;
  languageAvailability?: Maybe<Array<Scalars['String']>>;
  mosalinguaType?: Maybe<MosaCardType>;
  mosalinguaTags?: Maybe<Array<Scalars['String']>>;
};

export type FlashcardV2RawNumberInput_2411 = {
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type FlashcardV2RawNumber_2411 = {
  __typename?: 'FlashcardV2RawNumber_2411';
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type FlashcardV2RawTextInput_2411 = {
  label: Scalars['String'];
  text: Scalars['String'];
};

export type FlashcardV2RawText_2411 = {
  __typename?: 'FlashcardV2RawText_2411';
  label: Scalars['String'];
  text: Scalars['String'];
  language?: Maybe<Language>;
};

export type FlashcardV2RawUpdateInput_2411 = {
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  texts?: Maybe<Array<FlashcardV2RawTextInput_2411>>;
  numbers?: Maybe<Array<FlashcardV2RawNumberInput_2411>>;
  relatedActivities?: Maybe<RelatedActivitiesInput_2406>;
  parentCategories?: Maybe<Array<Scalars['String']>>;
  languageAvailability?: Maybe<Array<Scalars['String']>>;
  mosalinguaType?: Maybe<MosaCardType>;
  mosalinguaTags?: Maybe<Array<Scalars['String']>>;
};

export type FlashcardV2Raw_2411 = {
  __typename?: 'FlashcardV2Raw_2411';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  status: Status;
  accessType: AccessType;
  texts: Array<FlashcardV2RawText_2411>;
  numbers: Array<FlashcardV2RawNumber_2411>;
  assets: Array<FlashcardV2RawAsset_2411>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2411: Array<RelatedActivitiesData_2411>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  parentCategories: Array<Category>;
  mosalinguaId?: Maybe<Scalars['Int']>;
  languageAvailability?: Maybe<Array<Scalars['String']>>;
  mosalinguaType?: Maybe<MosaCardType>;
  mosalinguaTags?: Maybe<Array<Scalars['String']>>;
};

export type FlashcardsV2FromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type FlashcardsV2Input_2406 = {
  status?: Maybe<Status>;
  flashcardType?: Maybe<FlashcardType>;
  excludedIds?: Maybe<Array<Scalars['String']>>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};

export type FlashcardsV2RawFromCategorySearchInput_2411 = {
  status?: Maybe<Status>;
};

export type FlashcardsV2RawInput_2411 = {
  status?: Maybe<Status>;
  flashcardType?: Maybe<FlashcardType>;
  excludedIds?: Maybe<Array<Scalars['String']>>;
};

export type FleAfasVocabularyFlashcard = FlashcardV2 & {
  __typename?: 'FleAfasVocabularyFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content: FleAfasVocabularyFlashcardContent;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type FleAfasVocabularyFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type FleAfasVocabularyFlashcardContent = {
  __typename?: 'FleAfasVocabularyFlashcardContent';
  notionFr: Scalars['String'];
  notionEn: Scalars['String'];
  hint?: Maybe<Scalars['String']>;
  picture: File;
  audio: File;
};

export type FleCcfsVocabularyFlashcard = FlashcardV2 & {
  __typename?: 'FleCcfsVocabularyFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content: FleCcfsVocabularyFlashcardContent;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type FleCcfsVocabularyFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type FleCcfsVocabularyFlashcardContent = {
  __typename?: 'FleCcfsVocabularyFlashcardContent';
  notion: Scalars['String'];
  picture: File;
  audio: File;
};

export type FleEfemVocabularyFlashcard = FlashcardV2 & {
  __typename?: 'FleEfemVocabularyFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content: FleEfemVocabularyFlashcardContent;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type FleEfemVocabularyFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type FleEfemVocabularyFlashcardContent = {
  __typename?: 'FleEfemVocabularyFlashcardContent';
  notion: Scalars['String'];
  picture: File;
  audio: File;
};

/** Deprecated: Prefer using FleVocabularyFlashcard */
export type FleJobsFlashcard = FlashcardV2 & {
  __typename?: 'FleJobsFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<FleJobsFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


/** Deprecated: Prefer using FleVocabularyFlashcard */
export type FleJobsFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

/** Deprecated: Prefer using FleVocabularyFlashcardContent */
export type FleJobsFlashcardContent = {
  __typename?: 'FleJobsFlashcardContent';
  job?: Maybe<Scalars['String']>;
  picture?: Maybe<File>;
};

export type FleVocabularyFlashcard = FlashcardV2 & {
  __typename?: 'FleVocabularyFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content?: Maybe<FleVocabularyFlashcardContent>;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<FlashcardProgress>>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type FleVocabularyFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type FleVocabularyFlashcardContent = {
  __typename?: 'FleVocabularyFlashcardContent';
  notion?: Maybe<Scalars['String']>;
  picture?: Maybe<File>;
  audio?: Maybe<File>;
};

export type Frequency = {
  __typename?: 'Frequency';
  intervalUnit: IntervalPriceType;
  intervalCount: Scalars['Int'];
};

export enum Gender {
  M = 'M',
  F = 'F'
}

export type GeneratedQuizCriteria = {
  __typename?: 'GeneratedQuizCriteria';
  size: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  startScore: Scalars['Int'];
  accessType?: Maybe<AccessType>;
  status?: Maybe<Status>;
  ttl: Scalars['Int'];
  constraints?: Maybe<GeneratedQuizCriteriaContraint>;
};

export type GeneratedQuizCriteriaContraint = {
  __typename?: 'GeneratedQuizCriteriaContraint';
  categories?: Maybe<Array<Maybe<CategoryConstraint>>>;
  videoCount?: Maybe<Scalars['Int']>;
  seriousFaultCount?: Maybe<Scalars['Int']>;
};

export type GeneratedQuizCriteriaContraintInput = {
  categories?: Maybe<Array<Maybe<CategoryConstraintInput>>>;
  videoCount?: Maybe<Scalars['Int']>;
  seriousFaultCount?: Maybe<Scalars['Int']>;
};

export type GeneratedQuizCriteriaContraintInput_2406 = {
  categories: Array<CategoryConstraintInput_2406>;
  videoCount?: Maybe<Scalars['Int']>;
  seriousFaultCount?: Maybe<Scalars['Int']>;
};

export type GeneratedQuizCriteriaInput = {
  size: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  startScore: Scalars['Int'];
  successScore: Scalars['Int'];
  isPermissiveSize?: Maybe<Scalars['Boolean']>;
  constraints?: Maybe<GeneratedQuizCriteriaContraintInput>;
};

export type GeneratedQuizCriteriaInput_2406 = {
  size: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  startScore: Scalars['Int'];
  successScore: Scalars['Int'];
  isPermissiveSize?: Maybe<Scalars['Boolean']>;
  constraints: GeneratedQuizCriteriaContraintInput_2406;
};

export type GetScheduledReviewInput = {
  flashcardId: Scalars['String'];
  targetLanguage?: Maybe<Language>;
  product: ApiProduct;
};

export type GutenbergPaginationInput = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  /** Not implemented on every query */
  order?: Maybe<OrderDirection>;
};

export enum GutenbergProduct {
  Exercice = 'EXERCICE',
  Primaire = 'PRIMAIRE',
  /** DEPRECATED: prefer using COLLEGE */
  Brevet = 'BREVET',
  College = 'COLLEGE',
  /** DEPRECATED: prefer using LYCEE */
  Bac = 'BAC',
  Lycee = 'LYCEE',
  /** DEPRECATED: prefer using BTS */
  Superieur = 'SUPERIEUR',
  Bts = 'BTS',
  Cdr = 'CDR',
  CodeBateau = 'CODE_BATEAU',
  CodeAuto = 'CODE_AUTO',
  CodeMoto = 'CODE_MOTO',
  FeuVert = 'FEU_VERT',
  Mosalingua = 'MOSALINGUA',
  Orthographe = 'ORTHOGRAPHE',
  Toeic = 'TOEIC',
  Toefl = 'TOEFL',
  ToeicWorld = 'TOEIC_WORLD',
  Alternance = 'ALTERNANCE',
  Clea = 'CLEA',
  Secours = 'SECOURS',
  FonctionPublique = 'FONCTION_PUBLIQUE',
  CultureGenerale = 'CULTURE_GENERALE',
  Voltaire = 'VOLTAIRE',
  Fle = 'FLE',
  FleAfas = 'FLE_AFAS',
  FleCcfs = 'FLE_CCFS',
  FleEfem = 'FLE_EFEM',
  Hsk = 'HSK',
  Orientation = 'ORIENTATION',
  Digischool = 'DIGISCHOOL',
  RfFormation = 'RF_FORMATION',
  ActuaCac = 'ACTUA_CAC',
  EnfantBleu = 'ENFANT_BLEU'
}

export type ImageTags = {
  __typename?: 'ImageTags';
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  density?: Maybe<Scalars['Int']>;
  format?: Maybe<Scalars['String']>;
};

export enum IntervalPriceType {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type IosSubscriptionPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: 'IosSubscriptionPrepaidGoodSource';
  type: PrepaidGoodSourceType;
  iosSubscriptionId: Scalars['String'];
};

export type IosSubscriptionPrepaidGoodSource_2402 = PrepaidGoodSource_2402 & {
  __typename?: 'IosSubscriptionPrepaidGoodSource_2402';
  type: PrepaidGoodSourceType_2402;
  iosSubscriptionId: Scalars['String'];
};


export type LabelList = {
  __typename?: 'LabelList';
  fr?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
};

export type LabelListInput = {
  fr: Scalars['String'];
  en?: Maybe<Scalars['String']>;
};

export enum Language {
  Fr = 'FR',
  En = 'EN',
  De = 'DE',
  Es = 'ES',
  Eo = 'EO',
  It = 'IT',
  Pt = 'PT',
  Ru = 'RU',
  Cn = 'CN'
}

export type LanguageCombination = {
  __typename?: 'LanguageCombination';
  source: Scalars['String'];
  target: Scalars['String'];
};

export type LanguageCombinationInput = {
  source: Language;
  target: Language;
};

export enum LearningType {
  Quiz = 'Quiz',
  Lesson = 'Lesson',
  MockExam = 'Mock_Exam',
  Session = 'Session',
  Flashcards = 'Flashcards',
  Superquiz = 'Superquiz',
  SuddenDeath = 'Sudden_Death',
  IrregularVerbs = 'Irregular_Verbs'
}

export type Lesson = {
  __typename?: 'Lesson';
  _id: Scalars['String'];
  learningId: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** @deprecated Always empty */
  slug: Scalars['String'];
  slugV2: Scalars['String'];
  html: Scalars['String'];
  pdfs?: Maybe<Array<Maybe<LessonPdf>>>;
  author?: Maybe<RestrainedUser>;
  authorId?: Maybe<Scalars['String']>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  estimatedTime?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  relatedActivities: RelatedActivities;
  relatedActivitiesData: Array<RelatedActivitiesData>;
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  elapsedTime?: Maybe<Time>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
  bookmark?: Maybe<LessonProgress>;
};


export type LessonRelatedActivitiesDataArgs = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};


export type LessonElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};

export type LessonBySlugFilter = {
  status?: Maybe<Status>;
  products?: Maybe<Array<GutenbergProduct>>;
};

export type LessonBySlugFilter_2406 = {
  status?: Maybe<Status>;
  products?: Maybe<Array<GutenbergProduct>>;
};

export type LessonCreateInput_2406 = {
  title: Scalars['String'];
  slugV2?: Maybe<Scalars['String']>;
  html: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  accessType: AccessType;
  authorId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<GutenbergProduct>>;
  isSearchable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  parentCategories: Array<Scalars['String']>;
};

/** **DEPRECATED** */
export type LessonInput = {
  title: Scalars['String'];
  html: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status: Status;
  accessType: AccessType;
  authorId: Scalars['String'];
  products: Array<GutenbergProduct>;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  parentCategories: Array<Scalars['String']>;
};

export type LessonPdf = {
  __typename?: 'LessonPdf';
  _id: Scalars['String'];
  slug: Scalars['String'];
  okulusId: Scalars['String'];
  srcPath?: Maybe<Scalars['String']>;
  okulusAsset: File;
};

export type LessonProgress = {
  __typename?: 'LessonProgress';
  id: Scalars['Int'];
  learning_id?: Maybe<Scalars['Int']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  status: BookmarkStatus;
  product?: Maybe<BookmarkProduct>;
  section?: Maybe<BookmarkSection>;
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  lesson?: Maybe<Lesson>;
};

export type LessonProgressInput = {
  learning_id?: Maybe<Scalars['Int']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  status: BookmarkStatus;
  product: BookmarkProduct;
  section?: Maybe<BookmarkSection>;
};

export type LessonUpdateInput_2406 = {
  title?: Maybe<Scalars['String']>;
  slugV2?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  authorId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<GutenbergProduct>>;
  isSearchable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  parentCategories?: Maybe<Array<Scalars['String']>>;
};

export type Lesson_2406 = {
  __typename?: 'Lesson_2406';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  slugV2: Scalars['String'];
  html: Scalars['String'];
  pdfs?: Maybe<Array<LessonPdf>>;
  authorId?: Maybe<Scalars['String']>;
  author?: Maybe<RestrainedUser>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
  status: Status;
  accessType: AccessType;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  relatedActivities_2406: RelatedActivities_2406;
  /** @deprecated Field no longer supported */
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  relatedActivitiesData_2411: Array<RelatedActivitiesData_2411>;
  parentCategories: Array<Category>;
  elapsedTime?: Maybe<Time>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
};


export type Lesson_2406RelatedActivitiesData_2406Args = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};


export type Lesson_2406RelatedActivitiesData_2411Args = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
  rawFlashcards?: Maybe<Scalars['Boolean']>;
};


export type Lesson_2406ElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};

export type LessonsFromCategorySearchInput_2406 = {
  status?: Maybe<Status>;
};

export type LessonsFromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type LessonsSearchInput_2406 = {
  ids?: Maybe<Array<Scalars['String']>>;
  learningIds?: Maybe<Array<Scalars['Int']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  product?: Maybe<GutenbergProduct>;
  tags?: Maybe<Array<Scalars['String']>>;
  createdAfter?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
};

export type LessonsV2SearchInput = {
  ids?: Maybe<Array<Scalars['String']>>;
  learningIds?: Maybe<Array<Scalars['Int']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  product?: Maybe<GutenbergProduct>;
  tags?: Maybe<Array<Scalars['String']>>;
  createdAfter?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
};

export type Level = {
  __typename?: 'Level';
  slug: Scalars['String'];
  label: Scalars['String'];
  diplomas: Array<Maybe<Diploma>>;
};

export type LevelV2 = {
  __typename?: 'LevelV2';
  slug: Scalars['String'];
  label: Scalars['String'];
  diplomas: Array<DiplomaV2>;
};

export type LilsisStatus = {
  __typename?: 'LilsisStatus';
  slug: Scalars['String'];
  label: Scalars['String'];
  levels: Array<Maybe<Level>>;
};

export type LilsisStatusV2 = {
  __typename?: 'LilsisStatusV2';
  slug: Scalars['String'];
  label: Scalars['String'];
  levels: Array<LevelV2>;
};

export type LilsisV2Address = {
  __typename?: 'LilsisV2Address';
  streetNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  shippingInstructions?: Maybe<Scalars['String']>;
};

export type LilsisV2AddressInput = {
  streetNumber?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  shippingInstructions?: Maybe<Scalars['String']>;
};

export type LilsisV2Situation = {
  __typename?: 'LilsisV2Situation';
  year: Scalars['Int'];
  version?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  diploma?: Maybe<Scalars['String']>;
  specialities?: Maybe<Array<Scalars['String']>>;
  domain?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Scalars['String']>>;
};

export type LilsisV2SituationInput = {
  year: Scalars['Int'];
  version?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  diploma?: Maybe<Scalars['String']>;
  specialities?: Maybe<Array<Scalars['String']>>;
  domain?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Scalars['String']>>;
};

export type LilsisV2User = {
  __typename?: 'LilsisV2User';
  id: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  address?: Maybe<LilsisV2Address>;
  phone?: Maybe<Scalars['String']>;
  situation?: Maybe<LilsisV2Situation>;
  createdAt?: Maybe<Scalars['String']>;
};

export type LilsisV2UserPatchInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<LilsisV2AddressInput>;
  situation?: Maybe<LilsisV2SituationInput>;
};

export type ListTrophyFilterInput = {
  tenant: TenantType;
  progress?: Maybe<Scalars['Boolean']>;
  hasSeenAllAcquiredLevels?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Array<GutenbergProduct>>;
};

export type ListTrophyIncludeInput = {
  progress?: Maybe<Scalars['Boolean']>;
};

export type ListTrophyInput = {
  filter: ListTrophyFilterInput;
  include?: Maybe<ListTrophyIncludeInput>;
  pagination?: Maybe<PaginationInput>;
};

export type ListTrophyResponse = {
  __typename?: 'ListTrophyResponse';
  data: Array<Trophy>;
  pagination: PaginatedResponse;
};

export enum Locale {
  En = 'en',
  Fr = 'fr'
}

export type MailValidationProcessState = {
  __typename?: 'MailValidationProcessState';
  firstMailSentAt?: Maybe<Scalars['Int']>;
  reminderSentAt?: Maybe<Scalars['Int']>;
};

export type Media = {
  __typename?: 'Media';
  learningId?: Maybe<Scalars['Int']>;
  okulusId?: Maybe<Scalars['String']>;
  okulusAsset?: Maybe<File>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Json']>;
};

export type MenteesInput = {
  client: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MetadataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum MosaCardType {
  PronunciationGuide = 'PRONUNCIATION_GUIDE',
  PronunciationPair = 'PRONUNCIATION_PAIR',
  PronunciationStress = 'PRONUNCIATION_STRESS',
  PronunciationIntonation = 'PRONUNCIATION_INTONATION',
  PronunciationSpelling = 'PRONUNCIATION_SPELLING',
  PronunciationContraction = 'PRONUNCIATION_CONTRACTION',
  PronunciationWordContraction = 'PRONUNCIATION_WORD_CONTRACTION',
  OriginalTranslation = 'ORIGINAL_TRANSLATION',
  Definition = 'DEFINITION',
  ConjugationLong = 'CONJUGATION_LONG',
  Conjugation = 'CONJUGATION',
  Generated = 'GENERATED',
  GeneratedRecap = 'GENERATED_RECAP'
}

export type MosalinguaFlashcard = FlashcardV2 & {
  __typename?: 'MosalinguaFlashcard';
  _id: Scalars['String'];
  flashcardType: FlashcardType;
  title: Scalars['String'];
  content: MosalinguaFlashcardContent;
  status: Status;
  accessType: AccessType;
  /** @deprecated Field no longer supported */
  relatedActivities: RelatedActivities;
  /** @deprecated Field no longer supported */
  relatedActivitiesData: Array<RelatedActivitiesData>;
  relatedActivities_2406: RelatedActivities_2406;
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark: Array<FlashcardProgress>;
  scheduledReview?: Maybe<ScheduledReview>;
  parentCategories: Array<Category>;
};


export type MosalinguaFlashcardScheduledReviewArgs = {
  product: ApiProduct;
};

export type MosalinguaFlashcardContent = {
  __typename?: 'MosalinguaFlashcardContent';
  notion: LanguageCombination;
  oralLevel: Scalars['Int'];
  writtenLevel: Scalars['Int'];
  teacherRecord?: Maybe<File>;
  mnemoImage?: Maybe<File>;
  mnemoText?: Maybe<LanguageCombination>;
};

export type MosalinguaService = {
  __typename?: 'MosalinguaService';
  firstConnectionAt?: Maybe<Scalars['Float']>;
  frFirstConnectionAt?: Maybe<Scalars['String']>;
  enFirstConnectionAt?: Maybe<Scalars['String']>;
  deFirstConnectionAt?: Maybe<Scalars['String']>;
  esFirstConnectionAt?: Maybe<Scalars['String']>;
  itFirstConnectionAt?: Maybe<Scalars['String']>;
  languageIAmLearning?: Maybe<Array<Scalars['String']>>;
};

export type MosalinguaServiceInput = {
  frFirstConnectionAt?: Maybe<Scalars['String']>;
  enFirstConnectionAt?: Maybe<Scalars['String']>;
  deFirstConnectionAt?: Maybe<Scalars['String']>;
  esFirstConnectionAt?: Maybe<Scalars['String']>;
  itFirstConnectionAt?: Maybe<Scalars['String']>;
  languageIAmLearning?: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  scheduleReview?: Maybe<ScheduledReview>;
  /** Returns the ID of the deleted review */
  excludeReview?: Maybe<ScheduledReview>;
  setCategoryProgress?: Maybe<CategoryProgress>;
  quizProgressPost?: Maybe<QuizProgress>;
  lessonProgressPost?: Maybe<LessonProgress>;
  quizProgressPostBulk?: Maybe<Array<Maybe<QuizProgress>>>;
  lessonProgressPostBulk?: Maybe<Array<Maybe<LessonProgress>>>;
  flashcardProgressPost?: Maybe<FlashcardProgress>;
  flashcardProgressPostBulk?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createPost: Post;
  addPostClaps?: Maybe<AddPostClapsOutput>;
  deletePost?: Maybe<Scalars['Json']>;
  createResponse?: Maybe<PostResponse>;
  addResponseClaps?: Maybe<PostResponse>;
  deleteResponse?: Maybe<Scalars['Json']>;
  lockToeicPromoCode?: Maybe<ToeicPromoCodesLockOutput>;
  importToeicPromoCodesAdmin?: Maybe<ToeicPromoCodesAdminImportOutput>;
  /** Mark a trophy progress as seen */
  trophyProgressSeenBulk: Scalars['Boolean'];
  createCategory?: Maybe<Category>;
  updateCategory?: Maybe<Category>;
  createBrief_2406?: Maybe<Brief>;
  updateBrief_2406?: Maybe<Brief>;
  createFlashcardV2Raw_2411: FlashcardV2Raw_2411;
  updateFlashcardV2Raw_2411: FlashcardV2Raw_2411;
  createLesson_2406: Lesson_2406;
  updateLesson_2406: Lesson_2406;
  /** @deprecated Use createLesson_2406 instead */
  createLesson?: Maybe<Lesson>;
  /** @deprecated Use UpdateLesson_2406 instead */
  updateLesson?: Maybe<Lesson>;
  createQuiz_2406: Quiz_2406;
  updateQuiz_2406: Quiz_2406;
  createExercise_2406: Exercise_2406;
  updateExercise_2406: Exercise_2406;
  /** @deprecated Use createQuiz_2406 instead */
  createQuiz?: Maybe<Quiz>;
  /** @deprecated Use updateQuiz_2406 instead */
  updateQuiz?: Maybe<Quiz>;
  createTrainingPaper_2406: TrainingPaper_2406;
  updateTrainingPaper_2406: TrainingPaper_2406;
  /** @deprecated Field no longer supported */
  createPastPaper?: Maybe<PastPaper>;
  /** @deprecated Field no longer supported */
  updatePastPaper?: Maybe<PastPaper>;
  /**
   * # Deprecated
   * Use `userPatchv2`, because this one does not use an input field and is missing some fields.
   * @deprecated Use `userPatchv2`, because this one does not use input.
   */
  userPatch?: Maybe<User>;
  /**
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  userPatchv2?: Maybe<User>;
  /**
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  userDelete?: Maybe<Scalars['Json']>;
  tag?: Maybe<UserTag>;
  tagDelete?: Maybe<Scalars['Json']>;
  userPatchTags?: Maybe<Scalars['Json']>;
  /** provide multi tenant support, used for CCFS and EFEM for now */
  externalUserPatch?: Maybe<LilsisV2User>;
  /** provide multi tenant support, used for CCFS and EFEM for now */
  externalUserDelete?: Maybe<Scalars['Json']>;
  createOkulusFiles: Array<File>;
  ephemeralKey?: Maybe<EphemeralKey>;
  /** @deprecated Use `cancelSubscriptionV2` instead */
  cancelSubscription?: Maybe<Scalars['Json']>;
  /** @deprecated Use `cartV2` instead */
  cart?: Maybe<Cart>;
  /** @deprecated Use `cartPatchV2` instead */
  cartPatch?: Maybe<Cart>;
  /** @deprecated Use `cartV2StripeIntentV2` instead */
  cartIntent?: Maybe<CartIntent>;
  cartV2?: Maybe<CartV2>;
  cartPatchV2?: Maybe<CartV2>;
  /** @deprecated Use `cartV2StripeIntentV2` instead */
  cartV2StripeIntent?: Maybe<CartStripeIntent>;
  cartV2StripeIntentV2: CartStripeIntentV2;
  cartV2PaypalApprove?: Maybe<CartPaypalApprove>;
  consumePrepaidGood_2402: PrepaidGood;
  /** @deprecated Use `consumePrepaidGood_2402` instead */
  consumePrepaidGood?: Maybe<PrepaidGood>;
  /** @deprecated Use `consumePrepaidGood_2402` instead */
  consumePrepaidGoodV2?: Maybe<PrepaidGood>;
  roleMapping?: Maybe<Scalars['Json']>;
  createPaymentMethodWithSetupIntent: StripeSetupIntent;
  updateDefaultStripePaymentMethod: StripePaymentMethod;
  deletePaymentMethod?: Maybe<Scalars['Json']>;
  cancelSubscriptionV2?: Maybe<Scalars['Json']>;
  activateCode_2402: ActivateCodeOutput_2402;
  createCodes_2402: CreateCodesOutput_2402;
  createDeal_2402: Deal_2402;
  createPartner_2402: Partner_2402;
  /** @deprecated Use `activateCode_2402` instead */
  activateCode?: Maybe<Scalars['Json']>;
  /** @deprecated Use `createCodes_2402` instead */
  codes?: Maybe<CodeOutput>;
  /** @deprecated Use `createDeal_2402` instead */
  deal?: Maybe<Deal>;
  /** @deprecated Use `createPartner_2402` instead */
  createPartner?: Maybe<Partner>;
  reasonPost?: Maybe<Scalars['Json']>;
  reasonPut?: Maybe<Scalars['Json']>;
  reportPost?: Maybe<Scalars['Json']>;
  reportSolve?: Maybe<ReportSolve>;
  contactPost?: Maybe<Contact>;
  reservationDelete?: Maybe<Scalars['Json']>;
  reservationPost?: Maybe<Reservation>;
  /**
   * # Deprecated
   * Note: this mutation never actually returns any value
   * @deprecated Use `createTime`, because this one does not use an input.
   */
  timePost?: Maybe<Time>;
  createTime?: Maybe<Time>;
};


export type MutationScheduleReviewArgs = {
  input: ScheduleReviewInput;
};


export type MutationExcludeReviewArgs = {
  input: ExcludeReviewInput;
};


export type MutationSetCategoryProgressArgs = {
  input: SetCategoryProgressInput;
};


export type MutationQuizProgressPostArgs = {
  data?: Maybe<QuizProgressInput>;
};


export type MutationLessonProgressPostArgs = {
  data?: Maybe<LessonProgressInput>;
};


export type MutationQuizProgressPostBulkArgs = {
  data?: Maybe<Array<Maybe<QuizProgressInput>>>;
};


export type MutationLessonProgressPostBulkArgs = {
  data?: Maybe<Array<Maybe<LessonProgressInput>>>;
};


export type MutationFlashcardProgressPostArgs = {
  input?: Maybe<FlashcardProgressInput>;
};


export type MutationFlashcardProgressPostBulkArgs = {
  input?: Maybe<Array<Maybe<FlashcardProgressInput>>>;
};


export type MutationCreatePostArgs = {
  params: CreatePostInput;
  files: Array<Scalars['Upload']>;
};


export type MutationAddPostClapsArgs = {
  id: Scalars['String'];
  count: Scalars['Int'];
};


export type MutationDeletePostArgs = {
  id: Scalars['String'];
};


export type MutationCreateResponseArgs = {
  params: CreateResponseInput;
  files: Array<Scalars['Upload']>;
};


export type MutationAddResponseClapsArgs = {
  id: Scalars['String'];
  count: Scalars['Int'];
};


export type MutationDeleteResponseArgs = {
  id: Scalars['String'];
};


export type MutationImportToeicPromoCodesAdminArgs = {
  importData?: Maybe<ToeicPromoCodesAdminImportInput>;
};


export type MutationTrophyProgressSeenBulkArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateCategoryArgs = {
  input: CategoryInput;
  files: Array<Scalars['Upload']>;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['String'];
  input: CategoryInput;
  files: Array<Scalars['Upload']>;
};


export type MutationCreateBrief_2406Args = {
  input: CreateBriefInput;
};


export type MutationUpdateBrief_2406Args = {
  id: Scalars['String'];
  input: UpdateBriefInput;
};


export type MutationCreateFlashcardV2Raw_2411Args = {
  input: FlashcardV2RawCreateInput_2411;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateFlashcardV2Raw_2411Args = {
  id: Scalars['String'];
  input: FlashcardV2RawUpdateInput_2411;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationCreateLesson_2406Args = {
  input: LessonCreateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateLesson_2406Args = {
  id: Scalars['String'];
  input: LessonUpdateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationCreateLessonArgs = {
  input: LessonInput;
  files: Array<Scalars['Upload']>;
};


export type MutationUpdateLessonArgs = {
  id: Scalars['String'];
  input: LessonInput;
  files: Array<Scalars['Upload']>;
};


export type MutationCreateQuiz_2406Args = {
  input: QuizCreateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateQuiz_2406Args = {
  id: Scalars['String'];
  input: QuizUpdateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationCreateExercise_2406Args = {
  input: ExerciseCreateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateExercise_2406Args = {
  id: Scalars['String'];
  input: ExerciseUpdateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationCreateQuizArgs = {
  input: QuizInput;
  files: Array<Scalars['Upload']>;
};


export type MutationUpdateQuizArgs = {
  id: Scalars['String'];
  input: QuizInput;
  files: Array<Scalars['Upload']>;
};


export type MutationCreateTrainingPaper_2406Args = {
  input: TrainingPaperCreateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateTrainingPaper_2406Args = {
  id: Scalars['String'];
  input: TrainingPaperUpdateInput_2406;
  files?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationCreatePastPaperArgs = {
  input: PastPaperInput;
  files: Array<Scalars['Upload']>;
};


export type MutationUpdatePastPaperArgs = {
  id: Scalars['String'];
  input: PastPaperInput;
  files: Array<Scalars['Upload']>;
};


export type MutationUserPatchArgs = {
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  situation?: Maybe<SituationInput>;
  newsletters?: Maybe<NewslettersInput>;
};


export type MutationUserPatchv2Args = {
  input: UserPatchInput;
  client?: Maybe<Scalars['String']>;
};


export type MutationUserDeleteArgs = {
  client?: Maybe<Scalars['String']>;
};


export type MutationTagArgs = {
  value: Scalars['String'];
};


export type MutationTagDeleteArgs = {
  id: Scalars['String'];
};


export type MutationUserPatchTagsArgs = {
  userId: Scalars['String'];
  input: PatchTagsInput;
};


export type MutationExternalUserPatchArgs = {
  input: LilsisV2UserPatchInput;
};


export type MutationCreateOkulusFilesArgs = {
  files: Array<Scalars['Upload']>;
  realm: Scalars['String'];
  tags: Array<Scalars['String']>;
};


export type MutationEphemeralKeyArgs = {
  customerId: Scalars['String'];
  apiVersion: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCartArgs = {
  input: CartInput;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCartPatchArgs = {
  input: CartPatchInput;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCartIntentArgs = {
  id: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCartV2Args = {
  input: CartV2Input;
  tenant: TenantType;
};


export type MutationCartPatchV2Args = {
  input: CartPatchV2Input;
  tenant: TenantType;
};


export type MutationCartV2StripeIntentArgs = {
  cartId: Scalars['String'];
  tenant: TenantType;
};


export type MutationCartV2StripeIntentV2Args = {
  cartId: Scalars['String'];
  tenant: TenantType;
  metadata?: Maybe<Array<MetadataInput>>;
};


export type MutationCartV2PaypalApproveArgs = {
  cartId: Scalars['String'];
  tenant: TenantType;
  redirectUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
};


export type MutationConsumePrepaidGood_2402Args = {
  tenant: TenantType;
  input: ConsumePrepaidGoodInput_2402;
};


export type MutationConsumePrepaidGoodArgs = {
  id: Scalars['String'];
  reservationId: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationConsumePrepaidGoodV2Args = {
  id: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationRoleMappingArgs = {
  input: RoleMappingInput;
};


export type MutationCreatePaymentMethodWithSetupIntentArgs = {
  client?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationUpdateDefaultStripePaymentMethodArgs = {
  input: StripePaymentMethodInput;
  client?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationDeletePaymentMethodArgs = {
  input?: Maybe<DeletePaymentMethodInput>;
  client?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCancelSubscriptionV2Args = {
  id: Scalars['String'];
};


export type MutationActivateCode_2402Args = {
  tenant: TenantType;
  code: Scalars['String'];
};


export type MutationCreateCodes_2402Args = {
  tenant: TenantType;
  input: CreateCodesInput_2402;
};


export type MutationCreateDeal_2402Args = {
  tenant: TenantType;
  input: CreateDealInput_2402;
};


export type MutationCreatePartner_2402Args = {
  tenant: TenantType;
  input: CreatePartnerInput_2402;
};


export type MutationActivateCodeArgs = {
  code: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCodesArgs = {
  input: CodeInput;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationDealArgs = {
  input?: Maybe<DealInput>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
  stripeAccount?: Maybe<StripeAccount>;
};


export type MutationReasonPostArgs = {
  input?: Maybe<ReasonInput>;
};


export type MutationReasonPutArgs = {
  input?: Maybe<ReasonInput>;
};


export type MutationReportPostArgs = {
  input?: Maybe<ReportInput>;
};


export type MutationReportSolveArgs = {
  input?: Maybe<ReportSolveInput>;
};


export type MutationContactPostArgs = {
  input?: Maybe<ContactInput>;
};


export type MutationReservationDeleteArgs = {
  id: Scalars['String'];
};


export type MutationReservationPostArgs = {
  input: ReservationInput;
};


export type MutationTimePostArgs = {
  userID: Scalars['String'];
  learningProduct: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
  learningCategorie?: Maybe<Scalars['String']>;
  learningType?: Maybe<LearningType>;
  learningID?: Maybe<Scalars['Int']>;
};


export type MutationCreateTimeArgs = {
  input: CreateTimeInput;
};

export type Newsletters = {
  __typename?: 'Newsletters';
  digiSchool?: Maybe<Scalars['Boolean']>;
  partners?: Maybe<Scalars['Boolean']>;
  feuvert?: Maybe<Scalars['Boolean']>;
  mosalingua?: Maybe<Scalars['Boolean']>;
};

export type NewslettersInput = {
  digiSchool?: Maybe<Scalars['Boolean']>;
  partners?: Maybe<Scalars['Boolean']>;
  feuvert?: Maybe<Scalars['Boolean']>;
  mosalingua?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaginatedResponse = {
  pagination: OffsetPagination;
};

export type OffsetPagination = {
  __typename?: 'OffsetPagination';
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  total: Scalars['Int'];
};

export type OffsetPaginationInput = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type OkulusListResponse = {
  __typename?: 'OkulusListResponse';
  data?: Maybe<Array<File>>;
};

export type OkulusSearchInput = {
  original?: Maybe<Scalars['String']>;
  mime?: Maybe<Scalars['String']>;
  realm?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  next?: Maybe<Scalars['Int']>;
};

export type OkulusSearchResponse = {
  __typename?: 'OkulusSearchResponse';
  next: Scalars['String'];
  medias?: Maybe<Array<File>>;
};

export type OneTimePrice = PriceInterface & {
  __typename?: 'OneTimePrice';
  _id: Scalars['String'];
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  enable: Scalars['Boolean'];
  amount: Scalars['Int'];
  currency: CurrencyType;
  externalReference: ExternalReference;
  productId?: Maybe<Scalars['String']>;
  product: Product;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type OneTimePrice_2402 = PriceInterface_2402 & {
  __typename?: 'OneTimePrice_2402';
  _id: Scalars['String'];
  kind: PriceKind_2402;
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  tenant: TenantType;
  amount: Scalars['Int'];
  currency: CurrencyType_2402;
  /** May only be `null` or `{ count: 1, unit: FOREVER }` */
  period?: Maybe<PricePeriod_2402>;
  externalReference: PriceExternalReference_2402;
  productId: Scalars['String'];
  product_2402: Product_2402;
  roles?: Maybe<Array<Role>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrientationService = {
  __typename?: 'OrientationService';
  targetCity?: Maybe<Scalars['String']>;
  targetDiploma?: Maybe<Scalars['String']>;
  targetProfession?: Maybe<Scalars['String']>;
  targetSector?: Maybe<Scalars['String']>;
  typeFormation?: Maybe<Scalars['String']>;
};

export type OrientationServiceInput = {
  targetCity?: Maybe<Scalars['String']>;
  targetDiploma?: Maybe<Scalars['String']>;
  targetProfession?: Maybe<Scalars['String']>;
  targetSector?: Maybe<Scalars['String']>;
  typeFormation?: Maybe<Scalars['String']>;
};

export type PaginatedBriefsOutput = CursorPaginatedResponse & {
  __typename?: 'PaginatedBriefsOutput';
  data: Array<Brief>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedCartsOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedCartsOutput';
  data: Array<Cart>;
  pagination: OffsetPagination;
};

export type PaginatedCartsV2Output = OffsetPaginatedResponse & {
  __typename?: 'PaginatedCartsV2Output';
  data: Array<CartV2>;
  pagination: OffsetPagination;
};

export type PaginatedCategoriesOutput = CursorPaginatedResponse & {
  __typename?: 'PaginatedCategoriesOutput';
  data?: Maybe<Array<Category>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedCleaReservation = {
  __typename?: 'PaginatedCleaReservation';
  id: Scalars['ID'];
  data: Array<Maybe<CleaReservation>>;
  cursor?: Maybe<CleaPagination>;
};

export type PaginatedCodesOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedCodesOutput';
  data: Array<Code>;
  pagination: OffsetPagination;
};

export type PaginatedCodesOutput_2402 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedCodesOutput_2402';
  data: Array<Code_2402>;
  pagination: OffsetPagination;
};

export type PaginatedDealsOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedDealsOutput';
  data: Array<Deal>;
  pagination: OffsetPagination;
};

export type PaginatedDealsOutput_2402 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedDealsOutput_2402';
  data: Array<Deal_2402>;
  pagination: OffsetPagination;
};

export type PaginatedEtsVouchersOutput_2408 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedEtsVouchersOutput_2408';
  data: Array<EtsVoucher_2408>;
  pagination: OffsetPagination;
};

export type PaginatedFlashcardsV2Output = CursorPaginatedResponse & {
  __typename?: 'PaginatedFlashcardsV2Output';
  data: Array<FlashcardV2>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedFlashcardsV2RawOutput_2411 = CursorPaginatedResponse & {
  __typename?: 'PaginatedFlashcardsV2RawOutput_2411';
  data: Array<FlashcardV2Raw_2411>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedLessonsOutput = CursorPaginatedResponse & {
  __typename?: 'PaginatedLessonsOutput';
  data: Array<Lesson>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedLessonsOutput_2406 = CursorPaginatedResponse & {
  __typename?: 'PaginatedLessonsOutput_2406';
  data: Array<Lesson_2406>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedPartnersOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedPartnersOutput';
  data: Array<Partner>;
  pagination: OffsetPagination;
};

export type PaginatedPartnersOutput_2402 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedPartnersOutput_2402';
  data: Array<Partner_2402>;
  pagination: OffsetPagination;
};

export type PaginatedPostsInput = {
  filter?: Maybe<PostFilter>;
  pagination?: Maybe<PaginationInput>;
  facets: Array<FacetUnionInput>;
  order?: Maybe<PostOrder>;
};

export type PaginatedPostsOutput = {
  __typename?: 'PaginatedPostsOutput';
  data: Array<Post>;
  pagination: PaginatedResponse;
};

export type PaginatedPrepaidGoodsOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedPrepaidGoodsOutput';
  data: Array<PrepaidGood>;
  pagination: OffsetPagination;
};

export type PaginatedPrepaidGoodsOutput_2402 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedPrepaidGoodsOutput_2402';
  data: Array<PrepaidGood_2402>;
  pagination: OffsetPagination;
};

export type PaginatedPricesOutput_2402 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedPricesOutput_2402';
  data: Array<PriceUnion_2402>;
  pagination: OffsetPagination;
};

export type PaginatedProductsOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedProductsOutput';
  data: Array<Product>;
  pagination: OffsetPagination;
};

export type PaginatedProductsOutput_2402 = OffsetPaginatedResponse & {
  __typename?: 'PaginatedProductsOutput_2402';
  data: Array<Product_2402>;
  pagination: OffsetPagination;
};

export type PaginatedQuizzesOutput = CursorPaginatedResponse & {
  __typename?: 'PaginatedQuizzesOutput';
  data: Array<Quiz>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedQuizzesOutput_2406 = CursorPaginatedResponse & {
  __typename?: 'PaginatedQuizzesOutput_2406';
  data: Array<Quiz_2406>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedReportSummariesOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedReportSummariesOutput';
  data: Array<Report>;
  pagination: OffsetPagination;
};

export type PaginatedResponse = {
  __typename?: 'PaginatedResponse';
  nextCursor?: Maybe<Scalars['String']>;
};

export type PaginatedResponsesInput = {
  pagination?: Maybe<PaginationInput>;
  filter?: Maybe<ResponsesFilters>;
};

export type PaginatedResponsesOutput = {
  __typename?: 'PaginatedResponsesOutput';
  data: Array<PostResponse>;
  pagination: PaginatedResponse;
};

export type PaginatedRightOfWayTestsOutput = CursorPaginatedResponse & {
  __typename?: 'PaginatedRightOfWayTestsOutput';
  data?: Maybe<Array<RightOfWayTest>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedRoleMappingsOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedRoleMappingsOutput';
  data: Array<RoleMapping>;
  pagination: OffsetPagination;
};

export type PaginatedToeicPromoCodesOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedToeicPromoCodesOutput';
  data: Array<ToeicPromoCode>;
  pagination: OffsetPagination;
};

export type PaginatedTrainingPapersOutput = CursorPaginatedResponse & {
  __typename?: 'PaginatedTrainingPapersOutput';
  data: Array<PastPaper>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedTrainingPapersOutput_2406 = CursorPaginatedResponse & {
  __typename?: 'PaginatedTrainingPapersOutput_2406';
  data: Array<TrainingPaper_2406>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedUsersOutput = OffsetPaginatedResponse & {
  __typename?: 'PaginatedUsersOutput';
  data: Array<User>;
  pagination: OffsetPagination;
};

export type PaginationInput = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Partner = {
  __typename?: 'Partner';
  _id: Scalars['String'];
  name: Scalars['String'];
  mentors?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deals?: Maybe<PaginatedDealsOutput>;
};


export type PartnerDealsArgs = {
  input?: Maybe<PartnerDealsInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type PartnerDealsInput = {
  status?: Maybe<VoucherStatus>;
  client?: Maybe<Scalars['String']>;
};

export type PartnerDealsInput_2402 = {
  status?: Maybe<VoucherStatus_2402>;
  client?: Maybe<Scalars['String']>;
};

export type Partner_2402 = {
  __typename?: 'Partner_2402';
  _id: Scalars['ID'];
  name: Scalars['String'];
  mentors?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deals_2402: PaginatedDealsOutput_2402;
};


export type Partner_2402Deals_2402Args = {
  input?: Maybe<PartnerDealsInput_2402>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type PartnersInput = {
  status?: Maybe<VoucherStatus>;
  mentor?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  searchType?: Maybe<CodeSearchType>;
};

export type PartnersInput_2402 = {
  status?: Maybe<VoucherStatus_2402>;
  mentor?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  searchType?: Maybe<CodeSearchType_2402>;
};

/** **DEPRECATED** */
export type PastPaper = {
  __typename?: 'PastPaper';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  /** @deprecated Always empty */
  slug: Scalars['String'];
  slugV2: Scalars['String'];
  document?: Maybe<Media>;
  year?: Maybe<Scalars['Int']>;
  type?: Maybe<PastPaperType>;
  isOfficial: Scalars['Boolean'];
  html?: Maybe<Scalars['String']>;
  author?: Maybe<RestrainedUser>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
};

export type PastPaperBySlugFilter = {
  products?: Maybe<Array<GutenbergProduct>>;
  status?: Maybe<Status>;
};

export enum PastPaperDocumentType {
  Embed = 'embed',
  Okulus = 'okulus'
}

export type PastPaperInput = {
  parentCategories: Array<Scalars['String']>;
  title: Scalars['String'];
  authorId: Scalars['String'];
  isOfficial: Scalars['Boolean'];
  status: Status;
  accessType: AccessType;
  products: Array<GutenbergProduct>;
  description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  document?: Maybe<AssetInput>;
  year?: Maybe<Scalars['Int']>;
  type?: Maybe<PastPaperType>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export enum PastPaperType {
  Paper = 'PAPER',
  Correction = 'CORRECTION',
  CorrectedPaper = 'CORRECTED_PAPER'
}

export type PatchTagsInput = {
  add?: Maybe<Array<Scalars['String']>>;
  remove?: Maybe<Array<Scalars['String']>>;
};

export type PathsCategories = {
  __typename?: 'PathsCategories';
  paths: Array<Scalars['String']>;
};

export enum PeriodUnit_2402 {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
  Forever = 'FOREVER'
}

export enum Platform {
  Desktop = 'Desktop',
  Android = 'Android',
  IOs = 'iOS',
  WindowsPhone = 'WindowsPhone'
}

export type Playlist = {
  __typename?: 'Playlist';
  data: Scalars['Json'];
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  type?: Maybe<PostType>;
  title: Scalars['String'];
  text: Scalars['String'];
  authorId: Scalars['String'];
  author?: Maybe<RestrainedUser>;
  imagePreview?: Maybe<Scalars['String']>;
  textPreview: Scalars['String'];
  slug: Scalars['String'];
  clapCount: Scalars['Int'];
  responses: PaginatedResponsesOutput;
  createdAt?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  responsesCount: Scalars['Int'];
  deadlineAt?: Maybe<Scalars['String']>;
  status: PostStatus;
  orientationsFacets: Array<PostOrientationFacet>;
  sectorFacets: Array<PostSectorFacet>;
  subjectFacets: Array<PostSubjectFacet>;
  levelFacets: Array<PostLevelFacet>;
  lastActivityAt: Scalars['String'];
  searchScore?: Maybe<Scalars['Float']>;
};


export type PostResponsesArgs = {
  params?: Maybe<PaginationInput>;
};

export type PostByIdParams = {
  id: Scalars['String'];
};

export type PostBySlugParams = {
  slug: Scalars['String'];
};

export type PostFacetsInput = {
  facets?: Maybe<Array<Maybe<FacetUnionInput>>>;
};

export type PostFacetsPaginated = {
  __typename?: 'PostFacetsPaginated';
  data: Array<FacetUnion>;
};

export type PostFilter = {
  authorId?: Maybe<Scalars['String']>;
  status?: Maybe<PostStatus>;
};

export type PostHasResponseFacet = {
  __typename?: 'PostHasResponseFacet';
  id: Scalars['ID'];
  label: Scalars['String'];
  hasResponse: Scalars['Boolean'];
  kind: FacetType;
};

export type PostLevelFacet = {
  __typename?: 'PostLevelFacet';
  id: Scalars['ID'];
  label: Scalars['String'];
  kind: FacetType;
};

export enum PostOrder {
  PublishedAt = 'PUBLISHED_AT',
  LastActivity = 'LAST_ACTIVITY'
}

export type PostOrientationFacet = {
  __typename?: 'PostOrientationFacet';
  id: Scalars['ID'];
  label: Scalars['String'];
  kind: FacetType;
};

export type PostResponse = {
  __typename?: 'PostResponse';
  id: Scalars['ID'];
  text: Scalars['String'];
  authorId: Scalars['String'];
  author?: Maybe<RestrainedUser>;
  clapCount: Scalars['Int'];
  createdAt: Scalars['String'];
  publishedAt?: Maybe<Scalars['String']>;
  postId: Scalars['String'];
  post: Post;
  status: ResponseStatus;
  searchScore?: Maybe<Scalars['Float']>;
};

export type PostSectorFacet = {
  __typename?: 'PostSectorFacet';
  id: Scalars['ID'];
  label: Scalars['String'];
  kind: FacetType;
};

export enum PostStatus {
  Published = 'PUBLISHED',
  Deleted = 'DELETED'
}

export type PostSubjectFacet = {
  __typename?: 'PostSubjectFacet';
  id: Scalars['ID'];
  label: Scalars['String'];
  kind: FacetType;
};

export enum PostType {
  Question = 'Question',
  Resource = 'Resource'
}

export type PostTypeFacet = {
  __typename?: 'PostTypeFacet';
  id: Scalars['ID'];
  label: Scalars['String'];
  type: PostType;
  kind: FacetType;
};

export type PostsSearchInput = {
  text: Scalars['String'];
  type?: Maybe<PostType>;
  status?: Maybe<PostStatus>;
  limit?: Maybe<Scalars['Int']>;
};

export type PrepaidGood = {
  __typename?: 'PrepaidGood';
  _id: Scalars['String'];
  priceId: Scalars['String'];
  ssoId: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  reservation?: Maybe<Reservation>;
  consumedAt?: Maybe<Scalars['String']>;
  source?: Maybe<PrepaidGoodSource>;
  account: StripeAccount;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PrepaidGoodSource = {
  type: PrepaidGoodSourceType;
};

export enum PrepaidGoodSourceType {
  Cart = 'CART',
  Code = 'CODE',
  SubscriptionAndroid = 'SUBSCRIPTION_ANDROID',
  SubscriptionIos = 'SUBSCRIPTION_IOS',
  SubscriptionStripe = 'SUBSCRIPTION_STRIPE'
}

export enum PrepaidGoodSourceType_2402 {
  Cart = 'CART',
  CartV2 = 'CART_V2',
  Code = 'CODE',
  SubscriptionAndroid = 'SUBSCRIPTION_ANDROID',
  SubscriptionIos = 'SUBSCRIPTION_IOS',
  SubscriptionStripe = 'SUBSCRIPTION_STRIPE'
}

export type PrepaidGoodSource_2402 = {
  type: PrepaidGoodSourceType_2402;
};

export type PrepaidGood_2402 = {
  __typename?: 'PrepaidGood_2402';
  _id: Scalars['ID'];
  priceId: Scalars['String'];
  price_2402: PriceUnion_2402;
  stripePriceId?: Maybe<Scalars['String']>;
  ssoId: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  reservation?: Maybe<Reservation>;
  consumedAt?: Maybe<Scalars['String']>;
  source: PrepaidGoodSource_2402;
  tenant: TenantType;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PrepaidGoodsInput_2402 = {
  priceId?: Maybe<Scalars['String']>;
  isConsumed?: Maybe<Scalars['Boolean']>;
};

export type PriceExternalReference_2402 = {
  __typename?: 'PriceExternalReference_2402';
  stripeId?: Maybe<Scalars['String']>;
  legacyActivationId?: Maybe<Scalars['String']>;
  paypalId?: Maybe<Scalars['String']>;
};

export type PriceInterface = {
  _id: Scalars['String'];
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  enable: Scalars['Boolean'];
  amount: Scalars['Int'];
  currency: CurrencyType;
  externalReference: ExternalReference;
  productId?: Maybe<Scalars['String']>;
  product: Product;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PriceInterface_2402 = {
  _id: Scalars['String'];
  kind: PriceKind_2402;
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  tenant: TenantType;
  amount: Scalars['Int'];
  currency: CurrencyType_2402;
  period?: Maybe<PricePeriod_2402>;
  externalReference: PriceExternalReference_2402;
  productId: Scalars['String'];
  product_2402: Product_2402;
  roles?: Maybe<Array<Role>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export enum PriceKind_2402 {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
  ActivationCode = 'ACTIVATION_CODE'
}

export type PricePeriod_2402 = {
  __typename?: 'PricePeriod_2402';
  count: Scalars['Int'];
  unit: PeriodUnit_2402;
};

export type PriceUnion = RecurringPrice | OneTimePrice;

export type PriceUnion_2402 = RecurringPrice_2402 | OneTimePrice_2402 | ActivationCodePrice_2402;

export type PricesInput_2402 = {
  productId?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<PriceKind_2402>>;
};

export type Product = {
  __typename?: 'Product';
  _id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  /** @deprecated This field should not be here and incorrectly returns null */
  kind: ProductKind;
  description: Scalars['String'];
  clients: Array<Client>;
  tenant: TenantType;
  enable: Scalars['Boolean'];
  externalReference: ExternalReference;
  roles: Array<Role>;
  prices: Array<PriceUnion>;
  isReservation: Scalars['Boolean'];
};

export type ProductExternalReference_2402 = {
  __typename?: 'ProductExternalReference_2402';
  stripeId?: Maybe<Scalars['String']>;
  paypalId?: Maybe<Scalars['String']>;
};

export enum ProductKind {
  Recurring = 'RECURRING',
  OneTime = 'ONE_TIME'
}

export type ProductList = {
  __typename?: 'ProductList';
  data: Scalars['Json'];
};

export type ProductPagination = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ProductPricesInput_2402 = {
  isEnabled?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<PriceKind_2402>>;
};

export type Product_2402 = {
  __typename?: 'Product_2402';
  _id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description: Scalars['String'];
  clients: Array<Client>;
  tenant: TenantType;
  isEnabled: Scalars['Boolean'];
  externalReference: ProductExternalReference_2402;
  roles: Array<Role>;
  prices_2402: Array<PriceUnion_2402>;
  isReservation: Scalars['Boolean'];
};


export type Product_2402Prices_2402Args = {
  input?: Maybe<ProductPricesInput_2402>;
};

export type ProductsInput_2402 = {
  clients?: Maybe<Array<Client>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  service: Service;
  availableReviews: Array<Maybe<ScheduledReview>>;
  getScheduledReview?: Maybe<ScheduledReview>;
  categoryProgress?: Maybe<CategoryProgress>;
  srsStats: Array<SrsStatsResponse>;
  srsLiveStats: SrsStatsResponse;
  /** **DEPRECATED:** use `quizProgressV2` query instead */
  quizProgress?: Maybe<Array<Maybe<QuizProgress>>>;
  quizProgressV2?: Maybe<QuizProgressPaginated>;
  lessonProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  flashcardProgress?: Maybe<Array<Maybe<FlashcardProgress>>>;
  adminQuizProgress?: Maybe<Array<Maybe<QuizProgress>>>;
  /** Returns current user statistics */
  quizzesProgressStatistics?: Maybe<UserQuizStatistics>;
  quizzesProgressStatisticsV2?: Maybe<UserQuizStatisticsV2>;
  adminLessonProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  cleaResult?: Maybe<Array<Maybe<CleaResult>>>;
  facets: FacetsPaginated;
  postFacets: PostFacetsPaginated;
  posts: PaginatedPostsOutput;
  postsSearch: Array<Post>;
  postById: Post;
  postBySlug: Post;
  responses: PaginatedResponsesOutput;
  responsesSearch: Array<PostResponse>;
  toeicPromoCodes?: Maybe<PaginatedToeicPromoCodesOutput>;
  toeicPromoCodesAdmin?: Maybe<PaginatedToeicPromoCodesOutput>;
  trophies: ListTrophyResponse;
  /** **DEPRECATED:** use `categoriesV2` query instead */
  categories?: Maybe<Array<Maybe<Category>>>;
  categoriesV2?: Maybe<PaginatedCategoriesOutput>;
  category?: Maybe<Category>;
  categoriesPaths?: Maybe<PathsCategories>;
  categoriesSearch?: Maybe<PaginatedCategoriesOutput>;
  activitiesSlugsV2?: Maybe<ActivitiesSlugsV2>;
  /** @deprecated Field no longer supported */
  activitiesSlugs?: Maybe<ActivitiesSlugs>;
  brief?: Maybe<Brief>;
  briefs: PaginatedBriefsOutput;
  briefsFromCategory: PaginatedBriefsOutput;
  /**
   * **DEPRECATED:** use `flashcardsV2` query instead
   * @deprecated use `flashcardsV2` query instead
   */
  flashcards?: Maybe<Array<Maybe<Flashcard>>>;
  /**
   * **DEPRECATED:** use `flashcardV2` query instead
   * @deprecated use `flashcardV2` query instead
   */
  flashcard?: Maybe<Flashcard>;
  /**
   * **DEPRECATED:** use `flashcardsV2FromCategory` instead
   * @deprecated use `flashcardsV2FromCategory` instead
   */
  flashcardsFromCategory?: Maybe<Array<Maybe<Flashcard>>>;
  flashcardV2?: Maybe<AnyFlashcardV2>;
  flashcardV2Raw_2411?: Maybe<FlashcardV2Raw_2411>;
  flashcardsV2_2406: PaginatedFlashcardsV2Output;
  flashcardsV2Raw_2411: PaginatedFlashcardsV2RawOutput_2411;
  flashcardsV2FromCategoryV2: PaginatedFlashcardsV2Output;
  flashcardsV2RawFromCategory_2411: PaginatedFlashcardsV2RawOutput_2411;
  /** @deprecated Field no longer supported */
  flashcardsV2: PaginatedFlashcardsV2Output;
  /**
   * DEPRECATED
   * prefer using flashcardsV2FromCategoryV2
   * @deprecated Field no longer supported
   */
  flashcardsV2FromCategory?: Maybe<Array<FlashcardV2>>;
  lesson_2406?: Maybe<Lesson_2406>;
  lessonBySlug_2406?: Maybe<Lesson_2406>;
  lessonsSearch_2406?: Maybe<PaginatedLessonsOutput_2406>;
  lessons_2406?: Maybe<PaginatedLessonsOutput_2406>;
  lessonsFromCategory_2406?: Maybe<PaginatedLessonsOutput_2406>;
  /** @deprecated Use lesson_2406 instead */
  lesson?: Maybe<Lesson>;
  /** @deprecated Use lessonBySlug_2406 instead */
  lessonBySlug?: Maybe<Lesson>;
  /**
   * **DEPRECATED:** use `lessonsSearchV2` instead
   * @deprecated Use lessonsSearch_2406 instead
   */
  lessonsSearch?: Maybe<PaginatedLessonsOutput>;
  /** @deprecated Use lessonsSearch_2406 instead */
  lessonsSearchV2?: Maybe<PaginatedLessonsOutput>;
  /** @deprecated Use lessons_2406 instead */
  lessonsV2?: Maybe<PaginatedLessonsOutput>;
  lessonsFromCategoryV2?: Maybe<PaginatedLessonsOutput>;
  /**
   * **DEPRECATED:** use `lessonsV2` instead
   * @deprecated Use lessons_2406 instead
   */
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  /**
   * **DEPRECATED:** use `lessonsFromCategoryV2` instead
   * @deprecated Use lessonFromCategory_2406 instead
   */
  lessonsFromCategory?: Maybe<Array<Maybe<Lesson>>>;
  quizzes_2406: PaginatedQuizzesOutput_2406;
  quizzesFromCategory_2406: PaginatedQuizzesOutput_2406;
  quiz_2406: Quiz_2406;
  quizBySlug_2406: Quiz_2406;
  exercise_2406: Exercise_2406;
  exercises_2406: Array<Exercise_2406>;
  randomQuizzes_2406: Array<Quiz_2406>;
  generatedQuiz_2406: Quiz_2406;
  customGeneratedQuiz_2406: CustomGeneratedQuiz_2406;
  quizzesSearch_2406: PaginatedQuizzesOutput_2406;
  /** @deprecated Use quizze_2406 instead */
  quizzesV2?: Maybe<PaginatedQuizzesOutput>;
  /** @deprecated Use randomQuizzes_2406 instead */
  randomQuizzes?: Maybe<Array<Maybe<Quiz>>>;
  /** @deprecated Use quizzesFromCategory_2406 instead */
  quizzesFromCategoryV2?: Maybe<PaginatedQuizzesOutput>;
  /** @deprecated Use quiz_2406 instead */
  quiz?: Maybe<Quiz>;
  /** @deprecated Use quizBySlug_2406 instead */
  quizBySlug?: Maybe<Quiz>;
  /** @deprecated Use exercise_2406 instead */
  exercise?: Maybe<Exercise>;
  /** @deprecated Use exercises_2406 instead */
  exercises: Array<Exercise>;
  /** @deprecated Use question_2406 instead */
  question?: Maybe<Question>;
  /** @deprecated Use generatedQuiz_2406 instead */
  generatedQuiz: Quiz;
  /** @deprecated Use customGeneratedQuiz_2406 instead */
  customGeneratedQuiz?: Maybe<CustomGeneratedQuiz>;
  /** @deprecated Use quizzesSearch_2406 instead */
  quizzesSearch?: Maybe<PaginatedQuizzesOutput>;
  /** @deprecated Use quizzesSearch_2406 instead */
  quizzesSearchV2?: Maybe<PaginatedQuizzesOutput>;
  /** @deprecated Use quizzes_2406 instead */
  quizzes?: Maybe<Array<Maybe<Quiz>>>;
  /** @deprecated Use quizzesFromCategory_2406 instead */
  quizzesFromCategory?: Maybe<Array<Maybe<Quiz>>>;
  rightOfWayTests?: Maybe<Array<Maybe<RightOfWayTest>>>;
  rightOfWayTest?: Maybe<RightOfWayTest>;
  /** **DEPRECATED:** use `rightOfWayTestsFromCategoryV2` instead */
  rightOfWayTestsFromCategory?: Maybe<Array<Maybe<RightOfWayTest>>>;
  rightOfWayTestsFromCategoryV2?: Maybe<PaginatedRightOfWayTestsOutput>;
  trainingPapers_2406: PaginatedTrainingPapersOutput_2406;
  trainingPaper_2406: TrainingPaper_2406;
  trainingPaperBySlug_2406: TrainingPaper_2406;
  trainingPapersFromCategory_2406: PaginatedTrainingPapersOutput_2406;
  trainingPapersSearch_2406: PaginatedTrainingPapersOutput_2406;
  /** @deprecated Field no longer supported */
  trainingPapersV2?: Maybe<PaginatedTrainingPapersOutput>;
  /** @deprecated Field no longer supported */
  trainingPaper?: Maybe<PastPaper>;
  /** @deprecated Field no longer supported */
  trainingPaperBySlug?: Maybe<PastPaper>;
  /** @deprecated Field no longer supported */
  trainingPapersFromCategoryV2?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * **DEPRECATED:** use `trainingPapersSearchV2` query instead
   * @deprecated Field no longer supported
   */
  trainingPapersSearch?: Maybe<PaginatedTrainingPapersOutput>;
  /** @deprecated Field no longer supported */
  trainingPapersSearchV2?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * **DEPRECATED:** use `trainingPapersV2` query instead
   * @deprecated Field no longer supported
   */
  trainingPapers?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * **DEPRECATED:** use `trainingPapersFromCategoryV2` instead
   * @deprecated Field no longer supported
   */
  trainingPapersFromCategory?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * **DEPRECATED:** use `trainingPapers` query instead
   * @deprecated Field no longer supported
   */
  pastPapers?: Maybe<Array<Maybe<PastPaper>>>;
  /**
   * **DEPRECATED:** use `trainingPaper` query instead
   * @deprecated Field no longer supported
   */
  pastPaper?: Maybe<PastPaper>;
  /**
   * **DEPRECATED:** use `trainingPapersFromCategory` instead
   * @deprecated Field no longer supported
   */
  pastPapersFromCategory?: Maybe<Array<Maybe<PastPaper>>>;
  /**
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  users?: Maybe<Array<Maybe<User>>>;
  /**
   * If userId is specified, the token in the authorization header must be admin.
   * If no userId is specified the autorization token will be used as ID.
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  user?: Maybe<User>;
  childUsers?: Maybe<PaginatedUsersOutput>;
  childUser?: Maybe<User>;
  mentees?: Maybe<PaginatedUsersOutput>;
  mentee?: Maybe<User>;
  schools?: Maybe<Array<Maybe<School>>>;
  school?: Maybe<School>;
  /**
   * # Deprecated
   * Use `situationConstraintsv2` for better null handling
   * @deprecated Use `situationConstraintsv2` for better null handling
   */
  situationConstraints?: Maybe<SituationConstraints>;
  situationConstraintsv2?: Maybe<SituationConstraintsV2>;
  tag?: Maybe<UserTag>;
  tags: Array<UserTag>;
  /**
   * provide multi tenant support, used for CCFS and EFEM for now.
   * Get my profile if no userId defined, get user by id if defined.
   */
  externalUser?: Maybe<LilsisV2User>;
  files?: Maybe<OkulusListResponse>;
  file?: Maybe<File>;
  searchFiles: OkulusSearchResponse;
  /**
   * `filesIdAndCommand` can be an array of okulus ids like 025807e9-38c4-4bd0-9e96-e3d95422eb5b but also can have optionnal resize flags like
   * 025807e9-38c4-4bd0-9e96-e3d95422eb5b_w1125h2436 for example
   */
  offlineMedias?: Maybe<Scalars['String']>;
  /** @deprecated Use `subscriptionsV2` instead. This only supports Stripe subscriptions */
  subscriptions?: Maybe<Array<Maybe<StripeSubscription>>>;
  /** @deprecated Use `cartV2` instead */
  cart?: Maybe<Cart>;
  /** @deprecated Use `cartsV2` instead */
  carts?: Maybe<PaginatedCartsOutput>;
  cartV2?: Maybe<CartV2>;
  cartsV2: PaginatedCartsV2Output;
  etsVouchers_2408: PaginatedEtsVouchersOutput_2408;
  invoiceCodeDeLaRoute: ResultBase64;
  prepaidGoods_2402: PaginatedPrepaidGoodsOutput_2402;
  /** @deprecated Use `prepaidGoods_2402` instead */
  prepaidGoods?: Maybe<PaginatedPrepaidGoodsOutput>;
  price_2402: PriceUnion_2402;
  prices_2402: PaginatedPricesOutput_2402;
  /** @deprecated Use `price_2402` instead */
  price: PriceUnion;
  product_2402: Product_2402;
  productBySlug_2402: Product_2402;
  products_2402: PaginatedProductsOutput_2402;
  /** @deprecated Use `products_2402` instead */
  products?: Maybe<PaginatedProductsOutput>;
  /** @deprecated Use `productBySlug_2402` instead */
  productBySlug?: Maybe<Product>;
  roleMappings?: Maybe<PaginatedRoleMappingsOutput>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  defaultStripePaymentMethod?: Maybe<StripePaymentMethod>;
  /**
   * This returns activation code subscriptions as stripe ones
   * @deprecated Use subscriptions_24022() instead
   */
  subscriptionsV2?: Maybe<SubscriptionListOutput>;
  /**
   * This returns activation code subscriptions as stripe ones
   * @deprecated Use adminSubscriptions_2402() instead
   */
  adminSubscriptionsV2?: Maybe<SubscriptionListOutput>;
  subscriptions_2402?: Maybe<SubscriptionListOutput>;
  adminSubscriptions_2402?: Maybe<SubscriptionListOutput>;
  codes_2402: PaginatedCodesOutput_2402;
  deals_2402: PaginatedDealsOutput_2402;
  deal_2402: Deal_2402;
  partners_2402: PaginatedPartnersOutput_2402;
  partner_2402: Partner_2402;
  /** @deprecated Use `codes_2402` instead */
  codesV3?: Maybe<PaginatedCodesOutput>;
  /** @deprecated Use `deals_2402` instead */
  dealsV2?: Maybe<PaginatedDealsOutput>;
  /** @deprecated Use `deal_2402` instead */
  deal?: Maybe<Deal>;
  /** @deprecated Use `partners_2402` instead */
  partners?: Maybe<PaginatedPartnersOutput>;
  /** @deprecated Use `partner_2402` instead */
  partner?: Maybe<Partner>;
  /**
   * # Deprecated
   * Use `codes_2402` instead
   * @deprecated Use `codes_2402` instead
   */
  codes?: Maybe<Array<Maybe<Code>>>;
  /** @deprecated Use `` instead */
  codesv2?: Maybe<PaginatedCodesOutput>;
  /** @deprecated Use `deals_2402` instead */
  deals?: Maybe<Array<Maybe<Deal>>>;
  pdf: Array<Maybe<LessonPdf>>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
  reasonsAdmin?: Maybe<Array<Maybe<Reason>>>;
  reports?: Maybe<Array<Maybe<Report>>>;
  reportsv2?: Maybe<PaginatedReportSummariesOutput>;
  session?: Maybe<Session>;
  sessions?: Maybe<Array<Session>>;
  sessionsBySites?: Maybe<Array<Session>>;
  sites?: Maybe<Array<Maybe<Site>>>;
  site?: Maybe<Site>;
  convocation?: Maybe<Convocation>;
  resultPDF?: Maybe<ResultPdf>;
  adminReservations?: Maybe<Array<Maybe<Reservation>>>;
  /** Returns the reservations for the currently logged in user, can be accessed inside the `user` query too */
  reservations?: Maybe<Array<Reservation>>;
  reservation?: Maybe<Reservation>;
  cleaReservation?: Maybe<CleaReservation>;
  cleaReservations?: Maybe<PaginatedCleaReservation>;
  productList?: Maybe<ProductList>;
  /**
   * # Deprecated
   * Use `timev2`, because this one does not use an input field and is missing some fields.
   * @deprecated Use `timev2`, because this one does not use an input.
   */
  time?: Maybe<Time>;
  timev2?: Maybe<Time>;
  streak?: Maybe<Streak>;
  youtubePlaylist?: Maybe<Playlist>;
};


export type QueryAvailableReviewsArgs = {
  input: AvailableReviewsInput;
};


export type QueryGetScheduledReviewArgs = {
  input: GetScheduledReviewInput;
};


export type QueryCategoryProgressArgs = {
  input: CategoryProgressInput;
};


export type QuerySrsStatsArgs = {
  input: SrsStatsInput;
};


export type QuerySrsLiveStatsArgs = {
  input: SrsLiveStatsInput;
};


export type QueryQuizProgressArgs = {
  created_after?: Maybe<Scalars['String']>;
  updated_after?: Maybe<Scalars['String']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  section?: Maybe<BookmarkSection>;
  learning_id?: Maybe<Scalars['Int']>;
  product?: Maybe<BookmarkProduct>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars['Boolean']>;
  best_scores?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BookmarkType>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryQuizProgressV2Args = {
  created_after?: Maybe<Scalars['String']>;
  updated_after?: Maybe<Scalars['String']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  section?: Maybe<BookmarkSection>;
  learning_id?: Maybe<Scalars['Int']>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars['Boolean']>;
  best_scores?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BookmarkType>;
  types?: Maybe<Array<BookmarkType>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<QuizProgressV2SortInput>;
  result?: Maybe<BookmarkResult>;
};


export type QueryLessonProgressArgs = {
  created_after?: Maybe<Scalars['String']>;
  updated_after?: Maybe<Scalars['String']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  learning_id?: Maybe<Scalars['Int']>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  status?: Maybe<BookmarkStatus>;
  section?: Maybe<BookmarkSection>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryFlashcardProgressArgs = {
  gutenberg_uuid?: Maybe<Scalars['String']>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<FlashcardProgressSortInput>;
};


export type QueryAdminQuizProgressArgs = {
  sso_id?: Maybe<Scalars['String']>;
  created_after?: Maybe<Scalars['String']>;
  updated_after?: Maybe<Scalars['String']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  section?: Maybe<BookmarkSection>;
  learning_id?: Maybe<Scalars['Int']>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars['Boolean']>;
  best_scores?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BookmarkType>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<QuizProgressV2SortInput>;
};


export type QueryQuizzesProgressStatisticsArgs = {
  sso_id: Scalars['String'];
  gutenberg_uuid: Scalars['String'];
  type?: Maybe<BookmarkType>;
};


export type QueryQuizzesProgressStatisticsV2Args = {
  sso_id: Scalars['String'];
  gutenberg_uuid: Scalars['String'];
  over: Scalars['Int'];
  type?: Maybe<BookmarkType>;
};


export type QueryAdminLessonProgressArgs = {
  sso_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_after?: Maybe<Scalars['String']>;
  updated_after?: Maybe<Scalars['String']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  learning_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  product?: Maybe<BookmarkProduct>;
  status?: Maybe<BookmarkStatus>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


export type QueryCleaResultArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryFacetsArgs = {
  params?: Maybe<FacetsInput>;
};


export type QueryPostFacetsArgs = {
  params?: Maybe<PostFacetsInput>;
};


export type QueryPostsArgs = {
  params?: Maybe<PaginatedPostsInput>;
};


export type QueryPostsSearchArgs = {
  params: PostsSearchInput;
};


export type QueryPostByIdArgs = {
  params: PostByIdParams;
};


export type QueryPostBySlugArgs = {
  params: PostBySlugParams;
};


export type QueryResponsesArgs = {
  params?: Maybe<PaginatedResponsesInput>;
};


export type QueryResponsesSearchArgs = {
  params: ResponsesSearchInput;
};


export type QueryToeicPromoCodesArgs = {
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryToeicPromoCodesAdminArgs = {
  search?: Maybe<ToeicPromoCodesAdminSearchInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryTrophiesArgs = {
  params: ListTrophyInput;
};


export type QueryCategoriesArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  filter?: Maybe<CategoryFilter>;
  learningIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type QueryCategoriesV2Args = {
  search?: Maybe<CategoriesV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryCategoryArgs = {
  idOrPath: Scalars['String'];
};


export type QueryCategoriesPathsArgs = {
  basePath?: Maybe<Scalars['String']>;
  platform?: Maybe<CategoryPlatformFilter>;
};


export type QueryCategoriesSearchArgs = {
  query: Scalars['String'];
  startingPaths: Array<Scalars['String']>;
  status?: Maybe<Status>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryActivitiesSlugsV2Args = {
  activity: ActivityType;
  products: Array<GutenbergProduct>;
  status?: Maybe<Status>;
  platform?: Maybe<CategoryPlatformFilter>;
};


export type QueryActivitiesSlugsArgs = {
  activity: ActivityType;
  categoryPathsRecursive?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  platform?: Maybe<CategoryPlatformFilter>;
};


export type QueryBriefArgs = {
  id: Scalars['String'];
};


export type QueryBriefsArgs = {
  search?: Maybe<BriefsSearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryBriefsFromCategoryArgs = {
  idOrPath: Scalars['String'];
  search?: Maybe<BriefsFromCategorySearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryFlashcardsArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  excluded_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  bookmarkProduct?: Maybe<BookmarkProduct>;
};


export type QueryFlashcardArgs = {
  id: Scalars['String'];
};


export type QueryFlashcardsFromCategoryArgs = {
  path?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryFlashcardV2Args = {
  id: Scalars['String'];
  languageCombination?: Maybe<LanguageCombinationInput>;
};


export type QueryFlashcardV2Raw_2411Args = {
  id: Scalars['String'];
};


export type QueryFlashcardsV2_2406Args = {
  input?: Maybe<FlashcardsV2Input_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryFlashcardsV2Raw_2411Args = {
  input?: Maybe<FlashcardsV2RawInput_2411>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryFlashcardsV2FromCategoryV2Args = {
  path: Scalars['String'];
  search?: Maybe<FlashcardsV2FromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};


export type QueryFlashcardsV2RawFromCategory_2411Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<FlashcardsV2RawFromCategorySearchInput_2411>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryFlashcardsV2Args = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  flashcardType?: Maybe<FlashcardType>;
  excluded_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};


export type QueryFlashcardsV2FromCategoryArgs = {
  path?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};


export type QueryLesson_2406Args = {
  id: Scalars['String'];
};


export type QueryLessonBySlug_2406Args = {
  slug: Scalars['String'];
  filter?: Maybe<LessonBySlugFilter_2406>;
};


export type QueryLessonsSearch_2406Args = {
  query: Scalars['String'];
  products: Array<GutenbergProduct>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryLessons_2406Args = {
  search?: Maybe<LessonsSearchInput_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryLessonsFromCategory_2406Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<LessonsFromCategorySearchInput_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryLessonArgs = {
  id: Scalars['String'];
};


export type QueryLessonBySlugArgs = {
  slug: Scalars['String'];
  filter?: Maybe<LessonBySlugFilter>;
};


export type QueryLessonsSearchArgs = {
  query: Scalars['String'];
  product: GutenbergProduct;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryLessonsSearchV2Args = {
  query: Scalars['String'];
  products: Array<GutenbergProduct>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryLessonsV2Args = {
  search?: Maybe<LessonsV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryLessonsFromCategoryV2Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<LessonsFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryLessonsArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  learningIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  slugs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryLessonsFromCategoryArgs = {
  path?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryQuizzes_2406Args = {
  search?: Maybe<QuizzesSearchInput_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryQuizzesFromCategory_2406Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<QuizzesFromCategorySearchInput_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryQuiz_2406Args = {
  id: Scalars['String'];
};


export type QueryQuizBySlug_2406Args = {
  slug: Scalars['String'];
  filter?: Maybe<QuizBySlugFilter_2406>;
};


export type QueryExercise_2406Args = {
  id: Scalars['String'];
};


export type QueryExercises_2406Args = {
  ids: Array<Scalars['String']>;
};


export type QueryRandomQuizzes_2406Args = {
  size: Scalars['Int'];
  categoryIds?: Maybe<Array<Scalars['String']>>;
};


export type QueryGeneratedQuiz_2406Args = {
  criteriaId: Scalars['String'];
};


export type QueryCustomGeneratedQuiz_2406Args = {
  criteria: GeneratedQuizCriteriaInput_2406;
};


export type QueryQuizzesSearch_2406Args = {
  query: Scalars['String'];
  products: Array<GutenbergProduct>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryQuizzesV2Args = {
  search?: Maybe<QuizzesV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryRandomQuizzesArgs = {
  size?: Maybe<Scalars['Int']>;
  categoryIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryQuizzesFromCategoryV2Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<QuizzesFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryQuizArgs = {
  id: Scalars['String'];
};


export type QueryQuizBySlugArgs = {
  slug: Scalars['String'];
  filter?: Maybe<QuizBySlugFilter>;
};


export type QueryExerciseArgs = {
  id: Scalars['String'];
};


export type QueryExercisesArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryQuestionArgs = {
  id: Scalars['String'];
};


export type QueryGeneratedQuizArgs = {
  criteriaId: Scalars['String'];
};


export type QueryCustomGeneratedQuizArgs = {
  criteria: GeneratedQuizCriteriaInput;
};


export type QueryQuizzesSearchArgs = {
  query: Scalars['String'];
  product: GutenbergProduct;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryQuizzesSearchV2Args = {
  query: Scalars['String'];
  products: Array<GutenbergProduct>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryQuizzesArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  learningIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  slugs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryQuizzesFromCategoryArgs = {
  path?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryRightOfWayTestsArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  slugs?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryRightOfWayTestArgs = {
  id: Scalars['String'];
};


export type QueryRightOfWayTestsFromCategoryArgs = {
  path: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryRightOfWayTestsFromCategoryV2Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<RightOfWayTestsFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryTrainingPapers_2406Args = {
  search?: Maybe<TrainingPapersSearchInput_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryTrainingPaper_2406Args = {
  id: Scalars['String'];
};


export type QueryTrainingPaperBySlug_2406Args = {
  slug: Scalars['String'];
  filter?: Maybe<TrainingPaperBySlugFilter_2406>;
};


export type QueryTrainingPapersFromCategory_2406Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<TrainingPapersFromCategorySearchInput_2406>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryTrainingPapersSearch_2406Args = {
  query: Scalars['String'];
  products: Array<GutenbergProduct>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryTrainingPapersV2Args = {
  search?: Maybe<TrainingPapersSearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryTrainingPaperArgs = {
  id: Scalars['String'];
};


export type QueryTrainingPaperBySlugArgs = {
  slug: Scalars['String'];
  filter?: Maybe<PastPaperBySlugFilter>;
};


export type QueryTrainingPapersFromCategoryV2Args = {
  idOrPath: Scalars['String'];
  search?: Maybe<TrainingPapersFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};


export type QueryTrainingPapersSearchArgs = {
  query: Scalars['String'];
  product: GutenbergProduct;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryTrainingPapersSearchV2Args = {
  query: Scalars['String'];
  products: Array<GutenbergProduct>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryTrainingPapersArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  isOfficial?: Maybe<Scalars['Boolean']>;
};


export type QueryTrainingPapersFromCategoryArgs = {
  idOrPath: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  isOfficial?: Maybe<Scalars['Boolean']>;
};


export type QueryPastPapersArgs = {
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
};


export type QueryPastPaperArgs = {
  id: Scalars['String'];
};


export type QueryPastPapersFromCategoryArgs = {
  path: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  client: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  neph?: Maybe<Scalars['String']>;
  cleaId?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  client?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryChildUsersArgs = {
  input: ChildUsersInput;
};


export type QueryChildUserArgs = {
  userId: Scalars['String'];
  client: Scalars['String'];
};


export type QueryMenteesArgs = {
  input: MenteesInput;
};


export type QueryMenteeArgs = {
  userId: Scalars['String'];
  client: Scalars['String'];
};


export type QuerySchoolsArgs = {
  query: Scalars['String'];
  schoolType: SchoolType;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QuerySchoolArgs = {
  id: Scalars['String'];
};


export type QuerySituationConstraintsArgs = {
  version?: Maybe<Scalars['String']>;
};


export type QuerySituationConstraintsv2Args = {
  version?: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['String'];
};


export type QueryExternalUserArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryFilesArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryFileArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QuerySearchFilesArgs = {
  input: OkulusSearchInput;
};


export type QueryOfflineMediasArgs = {
  filesIdAndCommand: Array<Scalars['String']>;
};


export type QuerySubscriptionsArgs = {
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryCartArgs = {
  id: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryCartsArgs = {
  input?: Maybe<CartsInput>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryCartV2Args = {
  id: Scalars['String'];
  tenant: TenantType;
};


export type QueryCartsV2Args = {
  tenant: TenantType;
  input?: Maybe<CartsV2Input>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryEtsVouchers_2408Args = {
  input?: Maybe<EtsVouchersInput_2408>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryInvoiceCodeDeLaRouteArgs = {
  date: Scalars['String'];
};


export type QueryPrepaidGoods_2402Args = {
  tenant: TenantType;
  input?: Maybe<PrepaidGoodsInput_2402>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryPrepaidGoodsArgs = {
  priceId?: Maybe<Scalars['String']>;
  isConsumed?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryPrice_2402Args = {
  tenant: TenantType;
  id: Scalars['String'];
};


export type QueryPrices_2402Args = {
  tenant: TenantType;
  input?: Maybe<PricesInput_2402>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryPriceArgs = {
  tenant: TenantType;
  id: Scalars['String'];
};


export type QueryProduct_2402Args = {
  tenant: TenantType;
  id: Scalars['String'];
};


export type QueryProductBySlug_2402Args = {
  tenant: TenantType;
  slug: Scalars['String'];
};


export type QueryProducts_2402Args = {
  tenant: TenantType;
  input?: Maybe<ProductsInput_2402>;
  pagination?: Maybe<ProductPagination>;
};


export type QueryProductsArgs = {
  tenant: TenantType;
  clients?: Maybe<Array<Client>>;
  pagination?: Maybe<ProductPagination>;
  enable?: Maybe<Scalars['Boolean']>;
};


export type QueryProductBySlugArgs = {
  tenant: TenantType;
  slug: Scalars['String'];
};


export type QueryRoleMappingsArgs = {
  platform?: Maybe<RoleMappingPlatform>;
  productId?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryStripePaymentMethodsArgs = {
  client?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryDefaultStripePaymentMethodArgs = {
  client?: Maybe<Scalars['String']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QuerySubscriptionsV2Args = {
  pagination?: Maybe<SimpleCursorPaginationInput>;
};


export type QueryAdminSubscriptionsV2Args = {
  input?: Maybe<AdminSubscriptionListInput>;
  pagination?: Maybe<SimpleCursorPaginationInput>;
};


export type QuerySubscriptions_2402Args = {
  pagination?: Maybe<SimpleCursorPaginationInput>;
};


export type QueryAdminSubscriptions_2402Args = {
  input?: Maybe<AdminSubscriptionListInput>;
  pagination?: Maybe<SimpleCursorPaginationInput>;
};


export type QueryCodes_2402Args = {
  tenant: TenantType;
  input?: Maybe<CodesInput_2402>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryDeals_2402Args = {
  tenant: TenantType;
  input?: Maybe<DealsInput_2402>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryDeal_2402Args = {
  tenant: TenantType;
  id: Scalars['ID'];
};


export type QueryPartners_2402Args = {
  tenant: TenantType;
  input?: Maybe<PartnersInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};


export type QueryPartner_2402Args = {
  tenant: TenantType;
  id: Scalars['ID'];
};


export type QueryCodesV3Args = {
  input?: Maybe<CodesV3Input>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryDealsV2Args = {
  input?: Maybe<DealsV2Input>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryDealArgs = {
  id: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryPartnersArgs = {
  input?: Maybe<PartnersInput>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryPartnerArgs = {
  id: Scalars['String'];
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryCodesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryCodesv2Args = {
  createdAt?: Maybe<Scalars['String']>;
  deal?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  stripeAccount?: Maybe<StripeAccount>;
  activatedBy?: Maybe<Scalars['String']>;
};


export type QueryDealsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  stripeAccount?: Maybe<StripeAccount>;
};


export type QueryPdfArgs = {
  slug: Scalars['String'];
};


export type QueryReasonsArgs = {
  locale?: Maybe<Locale>;
  availableFor?: Maybe<ReportAvailability>;
};


export type QueryReportsArgs = {
  input?: Maybe<ReportSummariesInput>;
};


export type QueryReportsv2Args = {
  input?: Maybe<ReportSummariesInput>;
};


export type QuerySessionArgs = {
  input: SessionByIdInput;
};


export type QuerySessionsArgs = {
  input: SessionInput;
};


export type QuerySessionsBySitesArgs = {
  input: SessionBySitesInput;
};


export type QuerySitesArgs = {
  provider: ReservationProviders;
  departement?: Maybe<Scalars['String']>;
};


export type QuerySiteArgs = {
  id: Scalars['String'];
  provider: ReservationProviders;
};


export type QueryConvocationArgs = {
  id: Scalars['String'];
};


export type QueryResultPdfArgs = {
  id: Scalars['String'];
};


export type QueryAdminReservationsArgs = {
  input?: Maybe<AdminReservationInput>;
};


export type QueryReservationsArgs = {
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
};


export type QueryReservationArgs = {
  id: Scalars['String'];
};


export type QueryCleaReservationArgs = {
  id: Scalars['String'];
};


export type QueryCleaReservationsArgs = {
  inputs?: Maybe<CleaReservationPaginationInput>;
};


export type QueryTimeArgs = {
  userID?: Maybe<Scalars['String']>;
  learningCategorie?: Maybe<Scalars['String']>;
  learningProduct?: Maybe<Scalars['String']>;
  learningType?: Maybe<LearningType>;
  learningID?: Maybe<Scalars['Int']>;
};


export type QueryTimev2Args = {
  input?: Maybe<TimeInput>;
};


export type QueryStreakArgs = {
  input?: Maybe<StreakInput>;
};


export type QueryYoutubePlaylistArgs = {
  maxResults: Scalars['Int'];
  part: Scalars['String'];
  playlistId?: Maybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  _id: Scalars['String'];
  learningId: Scalars['Int'];
  score: Score;
  text?: Maybe<Scalars['String']>;
  audioText?: Maybe<Scalars['String']>;
  explanation: Explanation;
  type: QuestionType;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  possibleAnswers?: Maybe<Array<Maybe<Answer>>>;
  isCaseSensitive?: Maybe<Scalars['Boolean']>;
};

export type QuestionCreateInput_2406 = {
  _id?: Maybe<Scalars['String']>;
  type: QuestionType;
  text?: Maybe<Scalars['String']>;
  score: ScoreInput;
  explanation?: Maybe<ExplanationInput>;
  answers: Array<AnswerInput>;
  isCaseSensitive?: Maybe<Scalars['Boolean']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type QuestionInput = {
  score: ScoreInput;
  type: QuestionType;
  explanation: ExplanationInput;
  possibleAnswers: Array<AnswerInput>;
  _id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  audioText?: Maybe<Scalars['String']>;
  isCaseSensitive?: Maybe<Scalars['Boolean']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export enum QuestionType {
  QcmSingle = 'QCM_SINGLE',
  QcmMultiple = 'QCM_MULTIPLE',
  MissingWordUnique = 'MISSING_WORD_UNIQUE',
  MissingWordMultiple = 'MISSING_WORD_MULTIPLE',
  MissingWordFreetext = 'MISSING_WORD_FREETEXT',
  MixedSentence = 'MIXED_SENTENCE'
}

export type QuestionUpdateInput_2406 = {
  _id?: Maybe<Scalars['String']>;
  type: QuestionType;
  text?: Maybe<Scalars['String']>;
  score: ScoreInput;
  explanation?: Maybe<ExplanationInput>;
  answers: Array<AnswerInput>;
  isCaseSensitive?: Maybe<Scalars['Boolean']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
};

export type Question_2406 = {
  __typename?: 'Question_2406';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  type: QuestionType;
  text?: Maybe<Scalars['String']>;
  score: Score;
  explanation: Explanation_2406;
  answers: Array<Answer_2406>;
  isCaseSensitive?: Maybe<Scalars['Boolean']>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
};

export type Quiz = {
  __typename?: 'Quiz';
  _id: Scalars['String'];
  learningId: Scalars['Int'];
  startScore: Scalars['Int'];
  title: Scalars['String'];
  /** @deprecated Always empty */
  slug: Scalars['String'];
  slugV2: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maximalScore: Scalars['Int'];
  successScore: Scalars['Int'];
  questionCount: Scalars['Int'];
  difficulty?: Maybe<QuizDifficulty>;
  authorId?: Maybe<Scalars['String']>;
  author?: Maybe<RestrainedUser>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  exerciseList?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseListData?: Maybe<Array<Maybe<Exercise>>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData: Array<RelatedActivitiesData>;
  elapsedTime?: Maybe<Time>;
  bookmark?: Maybe<Array<Maybe<QuizProgress>>>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
  progress?: Maybe<PaginatedQuizzesOutput>;
};


export type QuizRelatedActivitiesDataArgs = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};


export type QuizElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};


export type QuizProgressArgs = {
  params?: Maybe<QuizProgressFilter>;
};

export type QuizBySlugFilter = {
  products?: Maybe<Array<GutenbergProduct>>;
  status?: Maybe<Status>;
};

export type QuizBySlugFilter_2406 = {
  products?: Maybe<Array<GutenbergProduct>>;
  status?: Maybe<Status>;
};

export type QuizCreateInput_2406 = {
  title: Scalars['String'];
  slugV2?: Maybe<Scalars['String']>;
  difficulty?: Maybe<QuizDifficulty>;
  description?: Maybe<Scalars['String']>;
  exercises: Array<Scalars['String']>;
  startScore?: Maybe<Scalars['Int']>;
  successScore: Scalars['Int'];
  maximalScore: Scalars['Int'];
  accessType: AccessType;
  status: Status;
  authorId?: Maybe<Scalars['String']>;
  products: Array<GutenbergProduct>;
  isSearchable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  parentCategories: Array<Scalars['String']>;
};

export enum QuizDifficulty {
  Novice = 'NOVICE',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Confirmed = 'CONFIRMED',
  Expert = 'EXPERT'
}

export type QuizInput = {
  parentCategories: Array<Scalars['String']>;
  authorId: Scalars['String'];
  title: Scalars['String'];
  maximalScore: Scalars['Int'];
  successScore: Scalars['Int'];
  startScore: Scalars['Int'];
  status: Status;
  accessType: AccessType;
  products: Array<GutenbergProduct>;
  exercises: Array<ExerciseInput>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<QuizDifficulty>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
};

export type QuizProgress = {
  __typename?: 'QuizProgress';
  id: Scalars['Int'];
  learning_id?: Maybe<Scalars['Int']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  section?: Maybe<BookmarkSection>;
  type?: Maybe<BookmarkType>;
  status: BookmarkStatus;
  product?: Maybe<BookmarkProduct>;
  current_question_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  success_score?: Maybe<Scalars['Int']>;
  result?: Maybe<BookmarkResult>;
  created_at: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  quiz?: Maybe<Quiz>;
};

export type QuizProgressFilter = {
  created_after?: Maybe<Scalars['String']>;
  updated_after?: Maybe<Scalars['String']>;
  section?: Maybe<BookmarkSection>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars['Boolean']>;
  best_scores?: Maybe<Scalars['Boolean']>;
  type?: Maybe<BookmarkType>;
  types?: Maybe<Array<BookmarkType>>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  sort?: Maybe<QuizProgressV2SortInput>;
  result?: Maybe<BookmarkResult>;
};

export type QuizProgressInput = {
  learning_id?: Maybe<Scalars['Int']>;
  gutenberg_uuid?: Maybe<Scalars['String']>;
  type: BookmarkType;
  section?: Maybe<BookmarkSection>;
  status: BookmarkStatus;
  product: BookmarkProduct;
  current_question_id?: Maybe<Scalars['Int']>;
  score: Scalars['Int'];
  success_score?: Maybe<Scalars['Int']>;
};

export type QuizProgressPaginated = OffsetPaginatedResponse & {
  __typename?: 'QuizProgressPaginated';
  data: Array<QuizProgress>;
  pagination: OffsetPagination;
};

export type QuizProgressV2SortInput = {
  id?: Maybe<Sort>;
  created_at?: Maybe<Sort>;
};

export type QuizUpdateInput_2406 = {
  title?: Maybe<Scalars['String']>;
  slugV2?: Maybe<Scalars['String']>;
  difficulty?: Maybe<QuizDifficulty>;
  description?: Maybe<Scalars['String']>;
  exercises?: Maybe<Array<Scalars['String']>>;
  startScore?: Maybe<Scalars['Int']>;
  successScore?: Maybe<Scalars['Int']>;
  maximalScore?: Maybe<Scalars['Int']>;
  accessType?: Maybe<AccessType>;
  status?: Maybe<Status>;
  authorId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<GutenbergProduct>>;
  isSearchable?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  relatedActivities?: Maybe<RelatedActivitiesInput>;
  parentCategories?: Maybe<Array<Scalars['String']>>;
};

export type Quiz_2406 = {
  __typename?: 'Quiz_2406';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  slugV2: Scalars['String'];
  difficulty?: Maybe<QuizDifficulty>;
  description?: Maybe<Scalars['String']>;
  exercises: Array<Scalars['String']>;
  exercisesData: Array<Exercise_2406>;
  questionCount: Scalars['Int'];
  startScore: Scalars['Int'];
  successScore: Scalars['Int'];
  maximalScore: Scalars['Int'];
  accessType: AccessType;
  status: Status;
  authorId?: Maybe<Scalars['String']>;
  author?: Maybe<RestrainedUser>;
  products: Array<GutenbergProduct>;
  isSearchable: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  estimatedTime?: Maybe<Scalars['Int']>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
  relatedActivities_2406: RelatedActivities_2406;
  /** @deprecated Field no longer supported */
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  relatedActivitiesData_2411: Array<RelatedActivitiesData_2411>;
  parentCategories: Array<Category>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  bookmark?: Maybe<Array<Maybe<QuizProgress>>>;
  elapsedTime?: Maybe<Time>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
  progress?: Maybe<PaginatedQuizzesOutput>;
};


export type Quiz_2406RelatedActivitiesData_2406Args = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};


export type Quiz_2406RelatedActivitiesData_2411Args = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
  rawFlashcards?: Maybe<Scalars['Boolean']>;
};


export type Quiz_2406ElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};


export type Quiz_2406ProgressArgs = {
  params?: Maybe<QuizProgressFilter>;
};

export type QuizzesFromCategorySearchInput_2406 = {
  status?: Maybe<Status>;
};

export type QuizzesFromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type QuizzesSearchInput_2406 = {
  ids?: Maybe<Array<Scalars['String']>>;
  learningIds?: Maybe<Array<Scalars['Int']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  products?: Maybe<Array<GutenbergProduct>>;
  tags?: Maybe<Array<Scalars['String']>>;
  createdAfter?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
};

export type QuizzesV2SearchInput = {
  ids?: Maybe<Array<Scalars['String']>>;
  learningIds?: Maybe<Array<Scalars['Int']>>;
  slugs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  product?: Maybe<GutenbergProduct>;
  tags?: Maybe<Array<Scalars['String']>>;
  createdAfter?: Maybe<Scalars['String']>;
  updatedAfter?: Maybe<Scalars['String']>;
};

export type Reason = {
  __typename?: 'Reason';
  slug: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  /**
   * # Admin only
   * Only returned when using the `reasonsAdmin` query
   */
  labelList?: Maybe<LabelList>;
  /**
   * # Admin only
   * Only returned when using the `reasonsAdmin` query
   */
  availableFor?: Maybe<Array<ReportAvailability>>;
};

export type ReasonInput = {
  slug: Scalars['String'];
  label: LabelListInput;
  availableFor: Array<ReportAvailability>;
};

export type RecurringPrice = PriceInterface & {
  __typename?: 'RecurringPrice';
  _id: Scalars['String'];
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  enable: Scalars['Boolean'];
  amount: Scalars['Int'];
  currency: CurrencyType;
  frequency: Frequency;
  externalReference: ExternalReference;
  productId?: Maybe<Scalars['String']>;
  product: Product;
  trial?: Maybe<Trial>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RecurringPrice_2402 = PriceInterface_2402 & {
  __typename?: 'RecurringPrice_2402';
  _id: Scalars['String'];
  kind: PriceKind_2402;
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  tenant: TenantType;
  amount: Scalars['Int'];
  currency: CurrencyType_2402;
  period: PricePeriod_2402;
  externalReference: PriceExternalReference_2402;
  productId: Scalars['String'];
  product_2402: Product_2402;
  roles?: Maybe<Array<Role>>;
  trial?: Maybe<Trial>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RecursiveCount = {
  __typename?: 'RecursiveCount';
  briefs: Scalars['Int'];
  /** @deprecated Always 0 */
  flashcards: Scalars['Int'];
  flashcardsV2: Scalars['Int'];
  lessons: Scalars['Int'];
  /** @deprecated Alias for `trainingPapers` */
  pastPapers: Scalars['Int'];
  trainingPapers: Scalars['Int'];
  quizzes: Scalars['Int'];
  /** @deprecated Always 0 */
  rightOfWayTests: Scalars['Int'];
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['String'];
};

export type RelatedActivities = {
  __typename?: 'RelatedActivities';
  _id?: Maybe<Scalars['String']>;
  lessons?: Maybe<Array<Maybe<Scalars['String']>>>;
  quizzes?: Maybe<Array<Maybe<Scalars['String']>>>;
  flashcards?: Maybe<Array<Maybe<Scalars['String']>>>;
  flashcardsV2?: Maybe<Array<Maybe<Scalars['String']>>>;
  pastPapers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RelatedActivitiesData = Flashcard | BacFrenchStylisticDeviceFlashcard | BacHistoryLandmarkFlashcard | BacPhilosophyQuoteFlashcard | BrevetChemistryMoleculeFlashcard | BrevetFrenchStylisticDeviceFlashcard | BrevetHistoryLandmarkFlashcard | CdrRoadSignFlashcard | FeuVertRoadSignFlashcard | FleAfasVocabularyFlashcard | FleCcfsVocabularyFlashcard | FleEfemVocabularyFlashcard | FleJobsFlashcard | FleVocabularyFlashcard | MosalinguaFlashcard | Lesson | PastPaper | Quiz;

export type RelatedActivitiesData_2406 = BacFrenchStylisticDeviceFlashcard | BacHistoryLandmarkFlashcard | BacPhilosophyQuoteFlashcard | BrevetChemistryMoleculeFlashcard | BrevetFrenchStylisticDeviceFlashcard | BrevetHistoryLandmarkFlashcard | CdrRoadSignFlashcard | FeuVertRoadSignFlashcard | FleAfasVocabularyFlashcard | FleCcfsVocabularyFlashcard | FleEfemVocabularyFlashcard | FleJobsFlashcard | FleVocabularyFlashcard | MosalinguaFlashcard | Lesson_2406 | Quiz_2406 | TrainingPaper_2406;

export type RelatedActivitiesData_2411 = BacFrenchStylisticDeviceFlashcard | BacHistoryLandmarkFlashcard | BacPhilosophyQuoteFlashcard | BrevetChemistryMoleculeFlashcard | BrevetFrenchStylisticDeviceFlashcard | BrevetHistoryLandmarkFlashcard | CdrRoadSignFlashcard | FeuVertRoadSignFlashcard | FleAfasVocabularyFlashcard | FleCcfsVocabularyFlashcard | FleEfemVocabularyFlashcard | FleJobsFlashcard | FleVocabularyFlashcard | MosalinguaFlashcard | FlashcardV2Raw_2411 | Lesson_2406 | Quiz_2406 | TrainingPaper_2406;

export type RelatedActivitiesInput = {
  lessons?: Maybe<Array<Scalars['String']>>;
  quizzes?: Maybe<Array<Scalars['String']>>;
  flashcards?: Maybe<Array<Scalars['String']>>;
  flashcardsV2?: Maybe<Array<Scalars['String']>>;
  pastPapers?: Maybe<Array<Scalars['String']>>;
};

export type RelatedActivitiesInput_2406 = {
  flashcardsV2?: Maybe<Array<Scalars['String']>>;
  lessons?: Maybe<Array<Scalars['String']>>;
  quizzes?: Maybe<Array<Scalars['String']>>;
  trainingPapers?: Maybe<Array<Scalars['String']>>;
};

export type RelatedActivities_2406 = {
  __typename?: 'RelatedActivities_2406';
  flashcardsV2: Array<Scalars['String']>;
  lessons: Array<Scalars['String']>;
  quizzes: Array<Scalars['String']>;
  trainingPapers: Array<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  contentId: Scalars['String'];
  contentTitle: Scalars['String'];
  contentType: ReportContentType;
  /**
   * # Deprecated
   * Use `questionId` for Gutenberg content
   */
  client: Scalars['String'];
  count: Scalars['Int'];
  lastReportedAt?: Maybe<Scalars['String']>;
  exercices?: Maybe<Array<ReportExercise>>;
  reasons?: Maybe<Array<ReportReason>>;
};

export enum ReportAvailability {
  Activities = 'activities',
  Comunity = 'comunity',
  Subscription = 'subscription'
}

export type ReportComment = {
  __typename?: 'ReportComment';
  comment?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum ReportContentType {
  Quiz = 'quiz',
  Lesson = 'lesson',
  Post = 'post',
  Response = 'response',
  Subscription = 'subscription'
}

export type ReportExercise = {
  __typename?: 'ReportExercise';
  exerciseId?: Maybe<Scalars['String']>;
  exerciseLearningId?: Maybe<Scalars['String']>;
  reasons: Array<Maybe<ReportReason>>;
  count: Scalars['Int'];
  lastReportedAt: Scalars['String'];
};

export type ReportInput = {
  client: Scalars['String'];
  reporterSsoId?: Maybe<Scalars['String']>;
  platform: ReportPlatform;
  contentId: Scalars['String'];
  contentTitle: Scalars['String'];
  contentType: ReportContentType;
  questionId?: Maybe<Scalars['String']>;
  /**
   * # Deprecated
   * Use `reasonSlug` for reason
   */
  reasonSlug?: Maybe<Scalars['String']>;
  reasonSlugs?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment?: Maybe<Scalars['String']>;
};

export enum ReportLocale {
  Fr = 'fr',
  En = 'en',
  De = 'de',
  Es = 'es',
  It = 'it',
  Pt = 'pt'
}

export enum ReportPlatform {
  Ios = 'ios',
  Android = 'android',
  Web = 'web'
}

export type ReportReason = {
  __typename?: 'ReportReason';
  reasonSlug: Scalars['String'];
  reasonLabel?: Maybe<Scalars['String']>;
  reports: Array<Maybe<ReportComment>>;
  count: Scalars['Int'];
  lastReportedAt: Scalars['String'];
};

export enum ReportService {
  Bac = 'BAC',
  Cdr = 'CDR',
  Brevet = 'BREVET',
  Orthographe = 'ORTHOGRAPHE',
  Feuvert = 'FEUVERT',
  Toeic = 'TOEIC',
  Exercice = 'EXERCICE',
  Digischool = 'DIGISCHOOL',
  Orientation = 'ORIENTATION',
  Alternance = 'ALTERNANCE',
  Clea = 'CLEA',
  Secours = 'SECOURS',
  FonctionPublique = 'FONCTION_PUBLIQUE',
  Primaire = 'PRIMAIRE',
  College = 'COLLEGE',
  Lycee = 'LYCEE',
  Bts = 'BTS',
  Mosalingua = 'MOSALINGUA',
  GrfPedagogique = 'GRF_PEDAGOGIQUE',
  GrfCommercial = 'GRF_COMMERCIAL'
}

export type ReportSolve = {
  __typename?: 'ReportSolve';
  solvedCount: Scalars['Int'];
};

export type ReportSolveInput = {
  contentId: Scalars['String'];
  contentType: ReportContentType;
  /**
   * # Deprecated
   * Use `questionId` for Gutenberg content
   */
  questionNumber?: Maybe<Scalars['Int']>;
  questionId?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  comments?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ReportSortType {
  Date = 'date',
  Count = 'count'
}

export type ReportSummariesInput = {
  client?: Maybe<Array<Maybe<Scalars['String']>>>;
  platform?: Maybe<Array<Maybe<ReportPlatform>>>;
  reason?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentId?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<ReportSortType>;
};

export type Reservation = {
  __typename?: 'Reservation';
  _id: Scalars['String'];
  reservationProviderId?: Maybe<Scalars['String']>;
  session?: Maybe<Session>;
  sessionProviderId: Scalars['String'];
  sessionBeginAt: Scalars['String'];
  gender: Gender;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  email: Scalars['String'];
  continentDeNaissance?: Maybe<Scalars['String']>;
  birthday: Scalars['String'];
  phoneNumber: Scalars['String'];
  ssoId: Scalars['String'];
  status: ReservationStatus;
  result: ReservationResult;
  provider: ReservationProviders;
  neph: Scalars['String'];
  category: ReservationCategory;
  resolvedAt?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  hasReducedMobility?: Maybe<Scalars['Boolean']>;
  canceledAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
};

export type ReservationCandidatInput = {
  gender: Gender;
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  birthday: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  neph: Scalars['String'];
  hasReducedMobility: Scalars['Boolean'];
};

export enum ReservationCategory {
  Auto = 'AUTO',
  Moto = 'MOTO'
}

export type ReservationInput = {
  session: ReservationSessionInput;
  candidat: ReservationCandidatInput;
  provider: ReservationProviders;
};

/** **ALL** is only useable on listing queries like `sites` or `sessions` and can never be returned as a query response */
export enum ReservationProviders {
  Laposte = 'LAPOSTE',
  Sgs = 'SGS',
  Sgs2 = 'SGS2',
  All = 'ALL'
}

export enum ReservationResult {
  Unknown = 'UNKNOWN',
  Sucess = 'SUCESS',
  Failed = 'FAILED'
}

export type ReservationSessionInput = {
  id: Scalars['String'];
  category: ReservationCategory;
  beginAt: Scalars['String'];
};

export enum ReservationStatus {
  Initialized = 'INITIALIZED',
  Pending = 'PENDING',
  Canceled = 'CANCELED',
  Resolved = 'RESOLVED',
  Failed = 'FAILED'
}

export type ResponseLevel = {
  __typename?: 'ResponseLevel';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export enum ResponseStatus {
  Published = 'PUBLISHED',
  Deleted = 'DELETED'
}

export type ResponseSubject = {
  __typename?: 'ResponseSubject';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ResponsesFilters = {
  postId?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  status?: Maybe<ResponseStatus>;
};

export type ResponsesSearchInput = {
  text: Scalars['String'];
  status?: Maybe<ResponseStatus>;
  limit?: Maybe<Scalars['Int']>;
};

export type RestrainedUser = {
  __typename?: 'RestrainedUser';
  id: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export type ResultBase64 = {
  __typename?: 'ResultBase64';
  base64: Scalars['String'];
};

export type ResultPdf = {
  __typename?: 'ResultPDF';
  pdf: Scalars['String'];
};

export type RightOfWayTest = {
  __typename?: 'RightOfWayTest';
  _id: Scalars['String'];
  title: Scalars['String'];
  explanation: Scalars['String'];
  video: Media;
  okulusAssets: Array<File>;
  chapters: Array<VideoChapter>;
  areas: Array<Area>;
};

export type RightOfWayTestsFromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type Role = {
  __typename?: 'Role';
  clientOrRealm: Scalars['String'];
  name: Scalars['String'];
};

export type RoleMapping = {
  __typename?: 'RoleMapping';
  platform: RoleMappingPlatform;
  productId: Scalars['String'];
  platformId?: Maybe<Scalars['String']>;
  role: RoleMappingRole;
};

export type RoleMappingInput = {
  platform: RoleMappingPlatform;
  productId: Scalars['String'];
  platformId?: Maybe<Scalars['String']>;
  role: RoleMappingRoleInput;
};

export enum RoleMappingPlatform {
  Android = 'android',
  Ios = 'ios',
  Stripe = 'stripe'
}

export type RoleMappingRole = {
  __typename?: 'RoleMappingRole';
  clientOrRealm: Scalars['String'];
  name: Scalars['String'];
};

export type RoleMappingRoleInput = {
  clientOrRealm: Scalars['String'];
  name: Scalars['String'];
};

export type Roles = {
  __typename?: 'Roles';
  realm?: Maybe<Array<Maybe<Scalars['String']>>>;
  client?: Maybe<Scalars['Json']>;
};

export type ScheduleReviewInput = {
  flashcardId: Scalars['String'];
  targetLanguage?: Maybe<Language>;
  /** Between 0 and 5 included */
  grade: Scalars['Int'];
  product: ApiProduct;
};

export type ScheduledReview = {
  __typename?: 'ScheduledReview';
  _id: Scalars['String'];
  flashcardId: Scalars['String'];
  targetLanguage?: Maybe<Language>;
  isMemorized: Scalars['Boolean'];
  reviewDate: Scalars['String'];
  flashcard?: Maybe<FlashcardV2>;
  product: ApiProduct;
};


export type ScheduledReviewFlashcardArgs = {
  languageCombination: LanguageCombinationInput;
};

export type School = {
  __typename?: 'School';
  schoolId: Scalars['String'];
  name: Scalars['String'];
  schoolType: SchoolType;
  departmentCode: Scalars['String'];
  town: Scalars['String'];
};

export enum SchoolType {
  Ecole = 'ECOLE',
  College = 'COLLEGE',
  Lycee = 'LYCEE',
  Erea = 'EREA'
}

export type Score = {
  __typename?: 'Score';
  success: Scalars['Float'];
  failure: Scalars['Float'];
};

export type ScoreInput = {
  success: Scalars['Float'];
  failure: Scalars['Float'];
};

export type ScoreInput_2406 = {
  success: Scalars['Float'];
  failure: Scalars['Float'];
};

export type Sector = {
  __typename?: 'Sector';
  slug: Scalars['String'];
  label: Scalars['String'];
};

export type Service = {
  __typename?: 'Service';
  name: Scalars['String'];
  version: Scalars['String'];
  schema: Scalars['String'];
};

export type Services = {
  __typename?: 'Services';
  codedelaroute?: Maybe<CodedelarouteService>;
  mosalingua?: Maybe<MosalinguaService>;
  orientation?: Maybe<OrientationService>;
};

export type ServicesPatchInput = {
  codedelaroute?: Maybe<CodedelarouteServiceInput>;
  mosalingua?: Maybe<MosalinguaServiceInput>;
  orientation?: Maybe<OrientationServiceInput>;
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['String'];
  provider: ReservationProviders;
  siteId?: Maybe<Scalars['String']>;
  site?: Maybe<Site>;
  beginAt: Scalars['String'];
  endAt?: Maybe<Scalars['String']>;
  deadlineReservation?: Maybe<Scalars['String']>;
  totalPlaces?: Maybe<Scalars['Int']>;
  remainingPlaces: Scalars['Int'];
  price: Scalars['Int'];
};

export type SessionByIdInput = {
  id: Scalars['String'];
  provider: ReservationProviders;
};

export type SessionBySitesInput = {
  provider?: Maybe<ReservationProviders>;
  providers?: Maybe<Array<ReservationProviders>>;
  minimumDate: Scalars['String'];
  maximumDate: Scalars['String'];
  siteIds: Array<Maybe<Scalars['String']>>;
};

export type SessionInput = {
  radius: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  minimumDate: Scalars['String'];
  maximumDate: Scalars['String'];
  provider?: Maybe<ReservationProviders>;
  providers?: Maybe<Array<ReservationProviders>>;
  hasReducedMobility?: Maybe<Scalars['Boolean']>;
  category?: Maybe<ReservationCategory>;
};

export type SetCategoryProgressInput = {
  categoryId: Scalars['String'];
  /** Progress percentage between 0 and 1 */
  progress: Scalars['Float'];
};

export type SimpleCursorPaginatedResponse = {
  __typename?: 'SimpleCursorPaginatedResponse';
  nextCursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type SimpleCursorPaginationInput = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['String'];
  provider: ReservationProviders;
  name: Scalars['String'];
  accessibleForDisabled: Scalars['Boolean'];
  capacity: Scalars['Int'];
  openAt?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
};

export type Situation = {
  __typename?: 'Situation';
  year: Scalars['Int'];
  version?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  diploma?: Maybe<Scalars['String']>;
  specialities?: Maybe<Array<Maybe<Scalars['String']>>>;
  domain?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalCandidate?: Maybe<Scalars['Boolean']>;
};

export type SituationConstraints = {
  __typename?: 'SituationConstraints';
  version: Scalars['String'];
  statuses: Array<Maybe<LilsisStatus>>;
  domains: Array<Maybe<Domain>>;
};

export type SituationConstraintsV2 = {
  __typename?: 'SituationConstraintsV2';
  version: Scalars['String'];
  statuses: Array<LilsisStatusV2>;
  domains: Array<DomainV2>;
};

export type SituationInput = {
  year: Scalars['Int'];
  version?: Maybe<Scalars['String']>;
  school?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  diploma?: Maybe<Scalars['String']>;
  specialities?: Maybe<Array<Maybe<Scalars['String']>>>;
  domain?: Maybe<Scalars['String']>;
  sectors?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalCandidate?: Maybe<Scalars['Boolean']>;
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortRule = {
  field: Scalars['String'];
  /** default: ASC */
  order?: Maybe<SortOrder>;
};

export type Speciality = {
  __typename?: 'Speciality';
  slug: Scalars['String'];
  label: Scalars['String'];
};

export type SrsLiveStatsInput = {
  product: ApiProduct;
  targetLanguage?: Maybe<Language>;
};

export type SrsStatsInput = {
  /** Only MOSALINGUA is currently supported by the API for now */
  product: ApiProduct;
  targetLanguage?: Maybe<Language>;
  until: Scalars['String'];
  period: SrsStatsPeriod;
  periodCount: Scalars['Int'];
};

export enum SrsStatsPeriod {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type SrsStatsResponse = {
  __typename?: 'SrsStatsResponse';
  userId: Scalars['String'];
  product: ApiProduct;
  targetLanguage?: Maybe<Language>;
  date: Scalars['String'];
  studiedFlashcardsCumulativeCount: Scalars['Int'];
  memorizedFlashcardsCumulativeCount: Scalars['Int'];
};

export enum Status {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Validated = 'VALIDATED',
  Rejected = 'REJECTED',
  Published = 'PUBLISHED',
  Obsolete = 'OBSOLETE',
  Archived = 'ARCHIVED'
}

export type Streak = {
  __typename?: 'Streak';
  userId: Scalars['String'];
  /** The keycloak client name */
  product: Scalars['String'];
  /** How many successive days the user has spent time on activiteis */
  streakLength: Scalars['Int'];
  /** Whether the latest session of the streak was yesterday or today */
  isTodayIncluded: Scalars['Boolean'];
};

export type StreakInput = {
  /** The keycloak client name */
  product: Scalars['String'];
};

/** Renamed "Tenant" in backend */
export enum StripeAccount {
  Digischool = 'digischool',
  Feuvert = 'feuvert'
}

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type StripeBillingDetails = {
  __typename?: 'StripeBillingDetails';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<StripeAddress>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  generated_from?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  wallet?: Maybe<Scalars['String']>;
  three_d_secure_usage?: Maybe<StripeThreeDSecureUsage>;
  networks?: Maybe<StripeNetworks>;
  checks?: Maybe<StripeChecks>;
};

export type StripeChecks = {
  __typename?: 'StripeChecks';
  address_line1_check?: Maybe<Scalars['String']>;
  address_postal_code_check?: Maybe<Scalars['String']>;
  cvc_check?: Maybe<Scalars['String']>;
};

export type StripeNetworks = {
  __typename?: 'StripeNetworks';
  preferred?: Maybe<Scalars['String']>;
  available?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  card?: Maybe<StripeCard>;
  billing_details?: Maybe<StripeBillingDetails>;
};

export type StripePaymentMethodInput = {
  paymentMethodId: Scalars['String'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  cancel_at?: Maybe<Scalars['Int']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  current_period_start?: Maybe<Scalars['Int']>;
  current_period_end?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  items?: Maybe<StripeSubscriptionItem>;
};

export type StripeSubscriptionItem = {
  __typename?: 'StripeSubscriptionItem';
  data?: Maybe<Array<Maybe<StripeSubscriptionItemData>>>;
};

export type StripeSubscriptionItemData = {
  __typename?: 'StripeSubscriptionItemData';
  id?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  price?: Maybe<StripeSubscriptionItemDataPrice>;
  subscription?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionItemDataPrice = {
  __typename?: 'StripeSubscriptionItemDataPrice';
  id?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  metadata?: Maybe<StripeSubscriptionItemDataPriceMetadata>;
};

export type StripeSubscriptionItemDataPriceMetadata = {
  __typename?: 'StripeSubscriptionItemDataPriceMetadata';
  activation_code?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: 'StripeSubscriptionPrepaidGoodSource';
  type: PrepaidGoodSourceType;
  stripeSubscriptionId: Scalars['String'];
};

export type StripeSubscriptionPrepaidGoodSource_2402 = PrepaidGoodSource_2402 & {
  __typename?: 'StripeSubscriptionPrepaidGoodSource_2402';
  type: PrepaidGoodSourceType_2402;
  stripeSubscriptionId: Scalars['String'];
};

export type StripeThreeDSecureUsage = {
  __typename?: 'StripeThreeDSecureUsage';
  supported?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionData = {
  __typename?: 'SubscriptionData';
  _id: Scalars['ID'];
  ssoId: Scalars['String'];
  provider: SubscriptionProviderType;
  providerSubscriptionId: Scalars['String'];
  priceId: Scalars['String'];
  /** @deprecated Use price_2402 field instead */
  price: PriceUnion;
  price_2402: PriceUnion_2402;
  /** When user canceled his subscription */
  canceledAt?: Maybe<Scalars['String']>;
  /**
   * When roles should be removed
   * @deprecated Use expiresAt field instead
   */
  endAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  tenant: TenantType;
  cartId?: Maybe<Scalars['String']>;
  cart?: Maybe<CartV2>;
  /** When roles were removed */
  endedAt?: Maybe<Scalars['String']>;
  trialEndsAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type SubscriptionListOutput = {
  __typename?: 'SubscriptionListOutput';
  data: Array<SubscriptionData>;
  pagination: SimpleCursorPaginatedResponse;
};

export enum SubscriptionProviderType {
  Stripe = 'STRIPE',
  Paypal = 'PAYPAL',
  Activation = 'ACTIVATION'
}

export enum TenantType {
  Digischool = 'digischool',
  Feuvert = 'feuvert',
  Afas = 'afas',
  Ccfs = 'ccfs',
  Efem = 'efem'
}

export type Time = {
  __typename?: 'Time';
  /** *DEPRECATED:* always null, use `userID` */
  _id?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
  /** @deprecated Use `categoryId` for gutenberg uuid instead of learning id */
  learningCategorie?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId: Array<Scalars['String']>;
  learningProduct: Array<Maybe<Scalars['String']>>;
  learningType?: Maybe<Array<Maybe<LearningType>>>;
  /** @deprecated Use `activityId` for gutenberg uuid instead of learning id */
  learningID?: Maybe<Array<Maybe<Scalars['Int']>>>;
  activityId: Array<Scalars['String']>;
  duration: Scalars['Int'];
  childrens?: Maybe<Array<Maybe<Time>>>;
  startTimestamp?: Maybe<Scalars['Int']>;
  endTimestamp?: Maybe<Scalars['Int']>;
};

export type TimeInput = {
  userID?: Maybe<Scalars['String']>;
  timescale?: Maybe<Timescale>;
  fromTimestamp?: Maybe<Scalars['Int']>;
  toTimestamp?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Scalars['String']>>;
  learningType?: Maybe<LearningType>;
  activityId?: Maybe<Scalars['String']>;
};

export enum Timescale {
  Today = 'TODAY',
  ThisWeek = 'THIS_WEEK',
  ThisMonth = 'THIS_MONTH'
}

export type ToeicPromoCode = {
  __typename?: 'ToeicPromoCode';
  _id: Scalars['String'];
  code: Scalars['String'];
  userSsoId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  transaction?: Maybe<ToeicPromoCodeTransaction>;
  cartId?: Maybe<Scalars['String']>;
  lock?: Maybe<ToeicPromoCodeLock>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
};

export type ToeicPromoCodeLock = {
  __typename?: 'ToeicPromoCodeLock';
  userSsoId: Scalars['String'];
  until: Scalars['String'];
};

export type ToeicPromoCodeTransaction = {
  __typename?: 'ToeicPromoCodeTransaction';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type ToeicPromoCodesAdminImportInput = {
  codes: Array<Scalars['String']>;
};

export type ToeicPromoCodesAdminImportOutput = {
  __typename?: 'ToeicPromoCodesAdminImportOutput';
  insertedCount: Scalars['Int'];
};

export type ToeicPromoCodesAdminSearchInput = {
  /** Retrieve only the active/deleted codes */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Retrieve only the assigned/available codes */
  isAssigned?: Maybe<Scalars['Boolean']>;
  /** Retrieve only the locked/free codes */
  isLocked?: Maybe<Scalars['Boolean']>;
};

export type ToeicPromoCodesLockOutput = {
  __typename?: 'ToeicPromoCodesLockOutput';
  lockedUntil: Scalars['String'];
};

export type TrainingPaperBySlugFilter_2406 = {
  products?: Maybe<Array<GutenbergProduct>>;
  status?: Maybe<Status>;
};

export type TrainingPaperCreateInput_2406 = {
  title: Scalars['String'];
  slugV2?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  accessType: AccessType;
  isSearchable?: Maybe<Scalars['Boolean']>;
  type: TrainingPaperType;
  isOfficial: Scalars['Boolean'];
  products?: Maybe<Array<GutenbergProduct>>;
  document?: Maybe<AssetInput>;
  html?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  relatedActivities?: Maybe<RelatedActivitiesInput_2406>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  parentCategories: Array<Scalars['String']>;
};

export enum TrainingPaperDocumentType {
  Embed = 'embed',
  Okulus = 'okulus'
}

export enum TrainingPaperType {
  Paper = 'PAPER',
  Correction = 'CORRECTION',
  CorrectedPaper = 'CORRECTED_PAPER'
}

export type TrainingPaperUpdateInput_2406 = {
  title?: Maybe<Scalars['String']>;
  slugV2?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  isSearchable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TrainingPaperType>;
  isOfficial?: Maybe<Scalars['Boolean']>;
  products?: Maybe<Array<GutenbergProduct>>;
  document?: Maybe<AssetInput>;
  html?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  relatedActivities?: Maybe<RelatedActivitiesInput_2406>;
  assetLinks?: Maybe<Array<AssetWrapperInput>>;
  parentCategories?: Maybe<Array<Scalars['String']>>;
};

export type TrainingPaper_2406 = {
  __typename?: 'TrainingPaper_2406';
  _id: Scalars['String'];
  learningId?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  slugV2: Scalars['String'];
  type?: Maybe<TrainingPaperType>;
  isOfficial: Scalars['Boolean'];
  document?: Maybe<Media>;
  html?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['String']>;
  author?: Maybe<RestrainedUser>;
  assetLinks: Array<AssetLink>;
  okulusAssets: Array<File>;
  status: Status;
  accessType: AccessType;
  relatedActivities_2406: RelatedActivities_2406;
  /** @deprecated Field no longer supported */
  relatedActivitiesData_2406: Array<RelatedActivitiesData_2406>;
  relatedActivitiesData_2411: Array<RelatedActivitiesData_2411>;
  parentCategories: Array<Category>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Only set when using search */
  searchScore?: Maybe<Scalars['Float']>;
};


export type TrainingPaper_2406RelatedActivitiesData_2406Args = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};


export type TrainingPaper_2406RelatedActivitiesData_2411Args = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
  rawFlashcards?: Maybe<Scalars['Boolean']>;
};

export type TrainingPapersFromCategorySearchInput_2406 = {
  isOfficial?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Status>;
};

export type TrainingPapersFromCategoryV2SearchInput = {
  isOfficial?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Status>;
};

export type TrainingPapersSearchInput = {
  slugs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  isOfficial?: Maybe<Scalars['Boolean']>;
};

export type TrainingPapersSearchInput_2406 = {
  slugs?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Status>;
  isOfficial?: Maybe<Scalars['Boolean']>;
};

export type Trial = {
  __typename?: 'Trial';
  count: Scalars['Int'];
  unit: PeriodUnit_2402;
  eligibility?: Maybe<TrialEligibility>;
};

export enum TrialEligibility {
  NotEligible = 'NOT_ELIGIBLE',
  Eligible = 'ELIGIBLE',
  Unknown = 'UNKNOWN'
}

export type Trophy = {
  __typename?: 'Trophy';
  id: Scalars['ID'];
  name: Scalars['String'];
  levels: Array<TrophyLevel>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  products: Array<GutenbergProduct>;
  /**
   * Current user progress
   * Only available if includeUserProgress is true and user is authenticated
   */
  progress?: Maybe<TrophyProgress>;
};

export type TrophyImage = {
  __typename?: 'TrophyImage';
  url: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type TrophyLevel = {
  __typename?: 'TrophyLevel';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  count: Scalars['Int'];
  image: TrophyImage;
};

export type TrophyProgress = {
  __typename?: 'TrophyProgress';
  id: Scalars['ID'];
  count: Scalars['Int'];
  lastSeenAt?: Maybe<Scalars['DateTime']>;
  hasSeenAllAcquiredLevels: Scalars['Boolean'];
  acquiredLevels: Array<AcquiredLevel>;
  notSeenAcquiredLevels: Array<AcquiredLevel>;
  products: Array<GutenbergProduct>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateBriefInput = {
  title?: Maybe<Scalars['String']>;
  slugV2?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  products?: Maybe<Array<GutenbergProduct>>;
  parentCategories?: Maybe<Array<Scalars['String']>>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailValid: Scalars['Boolean'];
  emailBounced: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  parentalConsent?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Gender>;
  locale?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  billingAddress?: Maybe<BillingAddress>;
  phone?: Maybe<Scalars['String']>;
  situation?: Maybe<Situation>;
  newsletters: Newsletters;
  connectedAt?: Maybe<Scalars['String']>;
  tagsIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<UserTag>>>;
  createdAt?: Maybe<Scalars['String']>;
  neph?: Maybe<Scalars['String']>;
  services?: Maybe<Services>;
  roles?: Maybe<Roles>;
  elapsedTime?: Maybe<Time>;
  /** Returns the quizzesProgress for the currently logged in user (does not work when giving a userId, return for the logged user either way) */
  quizzesProgress?: Maybe<Array<Maybe<QuizProgress>>>;
  /** Returns the lessonsProgress for the currently logged in user (does not work when giving a userId, return for the logged user either way) */
  lessonsProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  /** Returns the reservations for the currently logged in user (does not work when giving a userId, return for the logged user either way) */
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  /**
   * **DEPRECATED:** use `subscriptionsV2` query instead
   * Returns stripe's subscriptions for the currently logged in user (does not work when giving a userId, return for the logged user either way)
   * @deprecated on stripe subscriptions is supported, use `subscriptionsV2` instead
   */
  subscriptions?: Maybe<Array<Maybe<StripeSubscription>>>;
  /** Returns subscriptions for the currently logged in user. */
  subscriptionsV2: SubscriptionListOutput;
  /** Returns the user activated promotionnal codes */
  activationCodes?: Maybe<PaginatedCodesOutput>;
  /** Returns current user statistics */
  quizzesProgressStatistics?: Maybe<UserQuizStatistics>;
};


export type UserRolesArgs = {
  client?: Maybe<Scalars['String']>;
};


export type UserElapsedTimeArgs = {
  client?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Scalars['String']>>;
  learningType?: Maybe<LearningType>;
  timescale?: Maybe<Timescale>;
};


export type UserQuizzesProgressArgs = {
  section?: Maybe<Scalars['String']>;
  product?: Maybe<BookmarkProduct>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type UserLessonsProgressArgs = {
  product?: Maybe<BookmarkProduct>;
  page?: Maybe<Scalars['Int']>;
  per_page?: Maybe<Scalars['Int']>;
};


export type UserReservationsArgs = {
  input?: Maybe<UserReservationInput>;
};


export type UserSubscriptionsArgs = {
  stripeAccount?: Maybe<StripeAccount>;
};


export type UserSubscriptionsV2Args = {
  pagination?: Maybe<SimpleCursorPaginationInput>;
};


export type UserActivationCodesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  stripeAccount?: Maybe<StripeAccount>;
  activatedBy?: Maybe<Scalars['String']>;
};


export type UserQuizzesProgressStatisticsArgs = {
  gutenberg_uuid: Scalars['String'];
  type?: Maybe<BookmarkType>;
};

export type UserPatchInput = {
  userName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
  situation?: Maybe<SituationInput>;
  newsletters?: Maybe<NewslettersInput>;
  billingAddress?: Maybe<BillingAddressInput>;
  services?: Maybe<ServicesPatchInput>;
  locale?: Maybe<Scalars['String']>;
};

export type UserQuizStatistics = {
  __typename?: 'UserQuizStatistics';
  total: Scalars['Int'];
  over_35: Scalars['Int'];
};

export type UserQuizStatisticsV2 = {
  __typename?: 'UserQuizStatisticsV2';
  total: Scalars['Int'];
  over: Scalars['Int'];
};

export type UserReservationInput = {
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserTag = {
  __typename?: 'UserTag';
  _id: Scalars['String'];
  value: Scalars['String'];
  referentId: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type ValidityTimeRange = {
  __typename?: 'ValidityTimeRange';
  start: Scalars['String'];
  end: Scalars['String'];
};

export type ValidityTimeRangeInput = {
  start: Scalars['String'];
  end: Scalars['String'];
};

export type VideoChapter = {
  __typename?: 'VideoChapter';
  name: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  answers: Array<Scalars['String']>;
  start: Scalars['Int'];
  end: Scalars['Int'];
};

export enum VoucherStatus {
  Active = 'active',
  Deleted = 'deleted'
}

export enum VoucherStatus_2402 {
  Active = 'active',
  Deleted = 'deleted'
}

export type CategoryBaseFieldsFragment = (
  { __typename?: 'Category' }
  & Pick<Category, '_id' | 'learningId' | 'title' | 'slug' | 'path' | 'status' | 'description'>
  & { platformAvailability: (
    { __typename?: 'CategoryPlatformAvailability' }
    & Pick<CategoryPlatformAvailability, 'web' | 'mobile'>
  ) }
);

export type QuizBaseFieldsFragment = (
  { __typename?: 'Quiz' }
  & Pick<Quiz, '_id' | 'title' | 'slug' | 'slugV2' | 'estimatedTime' | 'learningId' | 'tags' | 'createdAt' | 'updatedAt' | 'maximalScore' | 'startScore' | 'successScore' | 'questionCount'>
  & { bookmark?: Maybe<Array<Maybe<(
    { __typename?: 'QuizProgress' }
    & BookmarkBaseFieldsFragment
  )>>> }
);

export type LessonBaseFieldsFragment = (
  { __typename?: 'Lesson' }
  & Pick<Lesson, '_id' | 'title' | 'slug' | 'slugV2' | 'estimatedTime' | 'learningId' | 'html' | 'tags' | 'createdAt' | 'updatedAt'>
  & { relatedActivities: (
    { __typename?: 'RelatedActivities' }
    & Pick<RelatedActivities, '_id'>
  ) }
);

export type FlashcardBaseFieldsFragment = (
  { __typename?: 'Flashcard' }
  & Pick<Flashcard, '_id' | 'title' | 'status' | 'accessType' | 'createdAt' | 'updatedAt'>
);

export type PastPaperBaseFieldsFragment = (
  { __typename?: 'PastPaper' }
  & Pick<PastPaper, '_id' | 'title' | 'slug' | 'accessType'>
);

export type TimeBaseFieldsFragment = (
  { __typename?: 'Time' }
  & Pick<Time, 'userID' | 'learningProduct' | 'duration'>
);

export type BookmarkBaseFieldsFragment = (
  { __typename?: 'QuizProgress' }
  & Pick<QuizProgress, 'id' | 'type' | 'status' | 'current_question_id' | 'score' | 'created_at' | 'updated_at'>
);

export type ModuleFieldsFragment = (
  { __typename?: 'Category' }
  & { elapsedTime?: Maybe<(
    { __typename?: 'Time' }
    & TimeBaseFieldsFragment
  )>, validityTimeRange?: Maybe<(
    { __typename?: 'ValidityTimeRange' }
    & Pick<ValidityTimeRange, 'start' | 'end'>
  )>, assetLinks?: Maybe<Array<Maybe<(
    { __typename?: 'AssetLink' }
    & Pick<AssetLink, 'type'>
    & { media?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'okulusId' | 'url'>
    )> }
  )>>>, childrenDataV2?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & { childrenDataV2?: Maybe<Array<Maybe<(
      { __typename?: 'Category' }
      & { quizzes?: Maybe<Array<Maybe<(
        { __typename?: 'Quiz' }
        & { elapsedTime?: Maybe<(
          { __typename?: 'Time' }
          & TimeBaseFieldsFragment
        )> }
        & QuizBaseFieldsFragment
      )>>>, lessons?: Maybe<Array<Maybe<(
        { __typename?: 'Lesson' }
        & { elapsedTime?: Maybe<(
          { __typename?: 'Time' }
          & TimeBaseFieldsFragment
        )> }
        & LessonBaseFieldsFragment
      )>>> }
      & CategoryBaseFieldsFragment
    )>>> }
    & CategoryBaseFieldsFragment
  )>>>, briefs?: Maybe<Array<Maybe<(
    { __typename?: 'Brief' }
    & Pick<Brief, '_id' | 'html' | 'title'>
  )>>> }
  & CategoryBaseFieldsFragment
);

export type CurrentYearConfirmedTimeQueryVariables = Exact<{
  fromTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
}>;


export type CurrentYearConfirmedTimeQuery = (
  { __typename?: 'Query' }
  & { timev2?: Maybe<(
    { __typename?: 'Time' }
    & Pick<Time, 'duration'>
  )> }
);

export type ThemeDataFragment = (
  { __typename?: 'Category' }
  & { quizzes?: Maybe<Array<Maybe<(
    { __typename?: 'Quiz' }
    & { elapsedTime?: Maybe<(
      { __typename?: 'Time' }
      & TimeBaseFieldsFragment
    )> }
    & QuizBaseFieldsFragment
  )>>>, lessons?: Maybe<Array<Maybe<(
    { __typename?: 'Lesson' }
    & { elapsedTime?: Maybe<(
      { __typename?: 'Time' }
      & TimeBaseFieldsFragment
    )> }
    & LessonBaseFieldsFragment
  )>>> }
  & CategoryBaseFieldsFragment
);

export type BlocDataFragment = (
  { __typename?: 'Category' }
  & { childrenDataV2?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & ThemeDataFragment
  )>>> }
  & CategoryBaseFieldsFragment
);

export type CategoryDataFragment = (
  { __typename?: 'Category' }
  & Pick<Category, '_id' | 'learningId' | 'title' | 'slug' | 'path' | 'status' | 'description'>
  & { platformAvailability: (
    { __typename?: 'CategoryPlatformAvailability' }
    & Pick<CategoryPlatformAvailability, 'web' | 'mobile'>
  ), validityTimeRange?: Maybe<(
    { __typename?: 'ValidityTimeRange' }
    & Pick<ValidityTimeRange, 'start' | 'end'>
  )>, briefs?: Maybe<Array<Maybe<(
    { __typename?: 'Brief' }
    & Pick<Brief, '_id' | 'html' | 'title'>
  )>>>, assetLinks?: Maybe<Array<Maybe<(
    { __typename?: 'AssetLink' }
    & Pick<AssetLink, 'type'>
    & { media?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'okulusId' | 'url'>
    )> }
  )>>>, childrenDataV2?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & BlocDataFragment
  )>>> }
);

export type ModuleListQueryVariables = Exact<{
  page: Scalars['Int'];
  per_page: Scalars['Int'];
  status?: Maybe<Status>;
}>;


export type ModuleListQuery = (
  { __typename?: 'Query' }
  & { categoriesV2?: Maybe<(
    { __typename?: 'PaginatedCategoriesOutput' }
    & { data?: Maybe<Array<(
      { __typename?: 'Category' }
      & CategoryDataFragment
    )>> }
  )> }
);

export type ModuleListAndUserTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type ModuleListAndUserTimeQuery = (
  { __typename?: 'Query' }
  & { rootCategory?: Maybe<(
    { __typename?: 'Category' }
    & { childrenDataV2?: Maybe<Array<Maybe<(
      { __typename?: 'Category' }
      & { validityTimeRange?: Maybe<(
        { __typename?: 'ValidityTimeRange' }
        & Pick<ValidityTimeRange, 'start' | 'end'>
      )> }
      & CategoryBaseFieldsFragment
    )>>> }
    & CategoryBaseFieldsFragment
  )> }
);

export type ModuleQueryVariables = Exact<{
  idOrPath: Scalars['String'];
}>;


export type ModuleQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & ModuleFieldsFragment
  )> }
);

export type LessonDetailsFragment = (
  { __typename?: 'Lesson' }
  & { elapsedTime?: Maybe<(
    { __typename?: 'Time' }
    & TimeBaseFieldsFragment
  )>, parentCategories?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & { parent?: Maybe<(
      { __typename?: 'Category' }
      & { parent?: Maybe<(
        { __typename?: 'Category' }
        & ModuleFieldsFragment
      )> }
      & CategoryBaseFieldsFragment
    )> }
    & CategoryBaseFieldsFragment
  )>>>, assetLinks?: Maybe<Array<Maybe<(
    { __typename?: 'AssetLink' }
    & Pick<AssetLink, 'type'>
    & { media?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'name' | 'mimeType' | 'provider' | 'url' | 'type' | 'contentType' | 'data' | 'okulusId'>
    )> }
  )>>>, relatedActivities: (
    { __typename?: 'RelatedActivities' }
    & Pick<RelatedActivities, '_id'>
  ) }
  & LessonBaseFieldsFragment
);

export type QuizDetailsFragment = (
  { __typename?: 'Quiz' }
  & { elapsedTime?: Maybe<(
    { __typename?: 'Time' }
    & TimeBaseFieldsFragment
  )>, bookmark?: Maybe<Array<Maybe<(
    { __typename?: 'QuizProgress' }
    & BookmarkBaseFieldsFragment
  )>>>, parentCategories?: Maybe<Array<Maybe<(
    { __typename?: 'Category' }
    & { parent?: Maybe<(
      { __typename?: 'Category' }
      & { parent?: Maybe<(
        { __typename?: 'Category' }
        & ModuleFieldsFragment
      )> }
      & CategoryBaseFieldsFragment
    )> }
    & CategoryBaseFieldsFragment
  )>>>, assetLinks?: Maybe<Array<Maybe<(
    { __typename?: 'AssetLink' }
    & Pick<AssetLink, 'type'>
    & { media?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'name' | 'mimeType' | 'provider' | 'url' | 'type' | 'contentType' | 'data' | 'okulusId'>
    )> }
  )>>>, exerciseListData?: Maybe<Array<Maybe<(
    { __typename?: 'Exercise' }
    & Pick<Exercise, '_id' | 'text' | 'learningId' | 'difficulty' | 'displayType' | 'audioText' | 'maxDurationSeconds' | 'containsLatex'>
    & { assetLinks?: Maybe<Array<Maybe<(
      { __typename?: 'AssetLink' }
      & Pick<AssetLink, 'type'>
      & { media?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, 'name' | 'mimeType' | 'provider' | 'url' | 'type' | 'contentType' | 'data' | 'okulusId'>
      )> }
    )>>>, questionsData?: Maybe<Array<Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'learningId' | 'type' | '_id' | 'text' | 'audioText'>
      & { score: (
        { __typename?: 'Score' }
        & Pick<Score, 'success' | 'failure'>
      ), assetLinks?: Maybe<Array<Maybe<(
        { __typename?: 'AssetLink' }
        & Pick<AssetLink, 'type'>
        & { media?: Maybe<(
          { __typename?: 'Media' }
          & Pick<Media, 'name' | 'mimeType' | 'provider' | 'url' | 'type' | 'contentType' | 'data' | 'okulusId'>
        )> }
      )>>>, explanation: (
        { __typename?: 'Explanation' }
        & Pick<Explanation, 'text' | 'audioText' | 'assetLinks'>
      ), possibleAnswers?: Maybe<Array<Maybe<(
        { __typename?: 'Answer' }
        & Pick<Answer, '_id' | 'learningId' | 'audioText' | 'text' | 'isCorrect'>
      )>>> }
    )>>> }
  )>>> }
  & QuizBaseFieldsFragment
);

export type ActivityQueryVariables = Exact<{
  id: Scalars['String'];
  isQuiz: Scalars['Boolean'];
  fetchModule: Scalars['Boolean'];
}>;


export type ActivityQuery = (
  { __typename?: 'Query' }
  & { lesson?: Maybe<(
    { __typename?: 'Lesson' }
    & LessonDetailsFragment
  )>, quiz?: Maybe<(
    { __typename?: 'Quiz' }
    & QuizDetailsFragment
  )> }
);

export type QuizProgressStandardQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuizProgressStandardQuery = (
  { __typename?: 'Query' }
  & { quizProgress?: Maybe<Array<Maybe<(
    { __typename?: 'QuizProgress' }
    & Pick<QuizProgress, 'id' | 'current_question_id' | 'score'>
  )>>> }
);

export type CertificateListQueryVariables = Exact<{ [key: string]: never; }>;


export type CertificateListQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & { childrensData?: Maybe<Array<Maybe<(
      { __typename?: 'Category' }
      & { validityTimeRange?: Maybe<(
        { __typename?: 'ValidityTimeRange' }
        & Pick<ValidityTimeRange, 'start' | 'end'>
      )>, elapsedTime?: Maybe<(
        { __typename?: 'Time' }
        & Pick<Time, 'startTimestamp' | 'endTimestamp'>
        & TimeBaseFieldsFragment
      )> }
      & CategoryBaseFieldsFragment
    )>>> }
    & CategoryBaseFieldsFragment
  )> }
);

export type CertificateListChrisQueryVariables = Exact<{ [key: string]: never; }>;


export type CertificateListChrisQuery = (
  { __typename?: 'Query' }
  & { categoriesV2?: Maybe<(
    { __typename?: 'PaginatedCategoriesOutput' }
    & { data?: Maybe<Array<(
      { __typename?: 'Category' }
      & { childrenDataV2?: Maybe<Array<Maybe<(
        { __typename?: 'Category' }
        & { childrenDataV2?: Maybe<Array<Maybe<(
          { __typename?: 'Category' }
          & Pick<Category, 'title'>
          & { quizzes?: Maybe<Array<Maybe<(
            { __typename?: 'Quiz' }
            & Pick<Quiz, 'estimatedTime'>
            & { elapsedTime?: Maybe<(
              { __typename?: 'Time' }
              & Pick<Time, 'duration'>
            )> }
          )>>>, lessons?: Maybe<Array<Maybe<(
            { __typename?: 'Lesson' }
            & Pick<Lesson, 'estimatedTime'>
            & { elapsedTime?: Maybe<(
              { __typename?: 'Time' }
              & Pick<Time, 'duration'>
            )> }
          )>>> }
        )>>> }
      )>>> }
    )>> }
  )> }
);

export type QuizProgressPostMutationVariables = Exact<{
  learningId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  type: BookmarkType;
  status: BookmarkStatus;
  score: Scalars['Int'];
  currentQuestionId?: Maybe<Scalars['Int']>;
}>;


export type QuizProgressPostMutation = (
  { __typename?: 'Mutation' }
  & { quizProgressPost?: Maybe<(
    { __typename?: 'QuizProgress' }
    & Pick<QuizProgress, 'learning_id' | 'gutenberg_uuid' | 'type'>
  )> }
);

export type LessonTimePostMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
  categoryId?: Maybe<Scalars['String']>;
}>;


export type LessonTimePostMutation = (
  { __typename?: 'Mutation' }
  & { createTime?: Maybe<(
    { __typename?: 'Time' }
    & Pick<Time, 'duration'>
  )> }
);

export type QuizDoneAndTimePostMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  type: BookmarkType;
  score: Scalars['Int'];
  userID: Scalars['String'];
  startTimestamp: Scalars['Int'];
  endTimestamp: Scalars['Int'];
  categoryId?: Maybe<Scalars['String']>;
}>;


export type QuizDoneAndTimePostMutation = (
  { __typename?: 'Mutation' }
  & { progress?: Maybe<(
    { __typename?: 'QuizProgress' }
    & Pick<QuizProgress, 'gutenberg_uuid' | 'type'>
  )>, time?: Maybe<(
    { __typename?: 'Time' }
    & Pick<Time, 'duration'>
  )> }
);

export type ContactPostMutationVariables = Exact<{
  service: ReportService;
  email: Scalars['String'];
  subType: Scalars['String'];
  message: Scalars['String'];
}>;


export type ContactPostMutation = (
  { __typename?: 'Mutation' }
  & { contactPost?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'message'>
  )> }
);

export const FlashcardBaseFieldsFragmentDoc = gql`
    fragment FlashcardBaseFields on Flashcard {
  _id
  title
  status
  accessType
  createdAt
  updatedAt
}
    `;
export const PastPaperBaseFieldsFragmentDoc = gql`
    fragment PastPaperBaseFields on PastPaper {
  _id
  title
  slug
  accessType
}
    `;
export const CategoryBaseFieldsFragmentDoc = gql`
    fragment CategoryBaseFields on Category {
  _id
  learningId
  title
  slug
  path
  status
  description
  platformAvailability {
    web
    mobile
  }
}
    `;
export const BookmarkBaseFieldsFragmentDoc = gql`
    fragment BookmarkBaseFields on QuizProgress {
  id
  type
  status
  current_question_id
  score
  created_at
  updated_at
}
    `;
export const QuizBaseFieldsFragmentDoc = gql`
    fragment QuizBaseFields on Quiz {
  _id
  title
  slug
  slugV2
  estimatedTime
  learningId
  tags
  createdAt
  updatedAt
  maximalScore
  startScore
  successScore
  questionCount
  bookmark {
    ...BookmarkBaseFields
  }
}
    ${BookmarkBaseFieldsFragmentDoc}`;
export const TimeBaseFieldsFragmentDoc = gql`
    fragment TimeBaseFields on Time {
  userID
  learningProduct
  duration
}
    `;
export const LessonBaseFieldsFragmentDoc = gql`
    fragment LessonBaseFields on Lesson {
  _id
  title
  slug
  slugV2
  estimatedTime
  learningId
  html
  tags
  relatedActivities {
    _id
  }
  createdAt
  updatedAt
}
    `;
export const ThemeDataFragmentDoc = gql`
    fragment ThemeData on Category {
  ...CategoryBaseFields
  quizzes {
    ...QuizBaseFields
    elapsedTime(search: {products: ["ACTUA_CAC"]}) {
      ...TimeBaseFields
    }
  }
  lessons {
    ...LessonBaseFields
    elapsedTime(search: {products: ["ACTUA_CAC"]}) {
      ...TimeBaseFields
    }
  }
}
    ${CategoryBaseFieldsFragmentDoc}
${QuizBaseFieldsFragmentDoc}
${TimeBaseFieldsFragmentDoc}
${LessonBaseFieldsFragmentDoc}`;
export const BlocDataFragmentDoc = gql`
    fragment BlocData on Category {
  ...CategoryBaseFields
  childrenDataV2 {
    ...ThemeData
  }
}
    ${CategoryBaseFieldsFragmentDoc}
${ThemeDataFragmentDoc}`;
export const CategoryDataFragmentDoc = gql`
    fragment CategoryData on Category {
  _id
  learningId
  title
  slug
  path
  status
  description
  platformAvailability {
    web
    mobile
  }
  validityTimeRange {
    start
    end
  }
  briefs {
    _id
    html
    title
  }
  assetLinks {
    type
    media {
      okulusId
      url
    }
  }
  childrenDataV2 {
    ...BlocData
  }
}
    ${BlocDataFragmentDoc}`;
export const ModuleFieldsFragmentDoc = gql`
    fragment ModuleFields on Category {
  ...CategoryBaseFields
  elapsedTime(search: {products: ["ACTUA_CAC"]}) {
    ...TimeBaseFields
  }
  validityTimeRange {
    start
    end
  }
  assetLinks {
    type
    media {
      okulusId
      url
    }
  }
  childrenDataV2 {
    ...CategoryBaseFields
    childrenDataV2 {
      ...CategoryBaseFields
      quizzes {
        elapsedTime(search: {products: ["ACTUA_CAC"]}) {
          ...TimeBaseFields
        }
        ...QuizBaseFields
      }
      lessons {
        elapsedTime(search: {products: ["ACTUA_CAC"]}) {
          ...TimeBaseFields
        }
        ...LessonBaseFields
      }
    }
  }
  briefs {
    _id
    html
    title
  }
}
    ${CategoryBaseFieldsFragmentDoc}
${TimeBaseFieldsFragmentDoc}
${QuizBaseFieldsFragmentDoc}
${LessonBaseFieldsFragmentDoc}`;
export const LessonDetailsFragmentDoc = gql`
    fragment lessonDetails on Lesson {
  ...LessonBaseFields
  elapsedTime(search: {products: ["ACTUA_CAC"]}) {
    ...TimeBaseFields
  }
  parentCategories {
    ...CategoryBaseFields
    parent @include(if: $fetchModule) {
      ...CategoryBaseFields
      parent {
        ...ModuleFields
      }
    }
  }
  assetLinks {
    type
    media {
      name
      mimeType
      provider
      url
      type
      contentType
      data
      okulusId
    }
  }
  relatedActivities {
    _id
  }
}
    ${LessonBaseFieldsFragmentDoc}
${TimeBaseFieldsFragmentDoc}
${CategoryBaseFieldsFragmentDoc}
${ModuleFieldsFragmentDoc}`;
export const QuizDetailsFragmentDoc = gql`
    fragment quizDetails on Quiz {
  ...QuizBaseFields
  elapsedTime(search: {products: ["ACTUA_CAC"]}) {
    ...TimeBaseFields
  }
  bookmark {
    ...BookmarkBaseFields
  }
  parentCategories {
    ...CategoryBaseFields
    parent @include(if: $fetchModule) {
      ...CategoryBaseFields
      parent {
        ...ModuleFields
      }
    }
  }
  assetLinks {
    type
    media {
      name
      mimeType
      provider
      url
      type
      contentType
      data
      okulusId
    }
  }
  exerciseListData {
    _id
    text
    learningId
    difficulty
    displayType
    audioText
    maxDurationSeconds
    containsLatex
    assetLinks {
      type
      media {
        name
        mimeType
        provider
        url
        type
        contentType
        data
        okulusId
      }
    }
    questionsData {
      learningId
      score {
        success
        failure
      }
      type
      assetLinks {
        type
        media {
          name
          mimeType
          provider
          url
          type
          contentType
          data
          okulusId
        }
      }
      _id
      text
      audioText
      explanation {
        text
        audioText
        assetLinks
      }
      possibleAnswers {
        _id
        learningId
        audioText
        text
        isCorrect
      }
    }
  }
}
    ${QuizBaseFieldsFragmentDoc}
${TimeBaseFieldsFragmentDoc}
${BookmarkBaseFieldsFragmentDoc}
${CategoryBaseFieldsFragmentDoc}
${ModuleFieldsFragmentDoc}`;
export const CurrentYearConfirmedTimeDocument = gql`
    query currentYearConfirmedTime($fromTimestamp: Int!, $endTimestamp: Int!) {
  timev2(
    input: {fromTimestamp: $fromTimestamp, toTimestamp: $endTimestamp, products: ["ACTUA_CAC"]}
  ) {
    duration
  }
}
    `;

/**
 * __useCurrentYearConfirmedTimeQuery__
 *
 * To run a query within a React component, call `useCurrentYearConfirmedTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentYearConfirmedTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentYearConfirmedTimeQuery({
 *   variables: {
 *      fromTimestamp: // value for 'fromTimestamp'
 *      endTimestamp: // value for 'endTimestamp'
 *   },
 * });
 */
export function useCurrentYearConfirmedTimeQuery(baseOptions: Apollo.QueryHookOptions<CurrentYearConfirmedTimeQuery, CurrentYearConfirmedTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentYearConfirmedTimeQuery, CurrentYearConfirmedTimeQueryVariables>(CurrentYearConfirmedTimeDocument, options);
      }
export function useCurrentYearConfirmedTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentYearConfirmedTimeQuery, CurrentYearConfirmedTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentYearConfirmedTimeQuery, CurrentYearConfirmedTimeQueryVariables>(CurrentYearConfirmedTimeDocument, options);
        }
export type CurrentYearConfirmedTimeQueryHookResult = ReturnType<typeof useCurrentYearConfirmedTimeQuery>;
export type CurrentYearConfirmedTimeLazyQueryHookResult = ReturnType<typeof useCurrentYearConfirmedTimeLazyQuery>;
export type CurrentYearConfirmedTimeQueryResult = Apollo.QueryResult<CurrentYearConfirmedTimeQuery, CurrentYearConfirmedTimeQueryVariables>;
export const ModuleListDocument = gql`
    query moduleList($page: Int!, $per_page: Int!, $status: Status) {
  categoriesV2(
    search: {parentIdOrPath: ",grf,actuacac", status: $status}
    pagination: {page: $page, per_page: $per_page, order: DESC}
  ) {
    data {
      ...CategoryData
    }
  }
}
    ${CategoryDataFragmentDoc}`;

/**
 * __useModuleListQuery__
 *
 * To run a query within a React component, call `useModuleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      per_page: // value for 'per_page'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useModuleListQuery(baseOptions: Apollo.QueryHookOptions<ModuleListQuery, ModuleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleListQuery, ModuleListQueryVariables>(ModuleListDocument, options);
      }
export function useModuleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleListQuery, ModuleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleListQuery, ModuleListQueryVariables>(ModuleListDocument, options);
        }
export type ModuleListQueryHookResult = ReturnType<typeof useModuleListQuery>;
export type ModuleListLazyQueryHookResult = ReturnType<typeof useModuleListLazyQuery>;
export type ModuleListQueryResult = Apollo.QueryResult<ModuleListQuery, ModuleListQueryVariables>;
export const ModuleListAndUserTimeDocument = gql`
    query moduleListAndUserTime {
  rootCategory: category(idOrPath: ",grf,actuacac") {
    ...CategoryBaseFields
    childrenDataV2 {
      ...CategoryBaseFields
      validityTimeRange {
        start
        end
      }
    }
  }
}
    ${CategoryBaseFieldsFragmentDoc}`;

/**
 * __useModuleListAndUserTimeQuery__
 *
 * To run a query within a React component, call `useModuleListAndUserTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleListAndUserTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleListAndUserTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useModuleListAndUserTimeQuery(baseOptions?: Apollo.QueryHookOptions<ModuleListAndUserTimeQuery, ModuleListAndUserTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleListAndUserTimeQuery, ModuleListAndUserTimeQueryVariables>(ModuleListAndUserTimeDocument, options);
      }
export function useModuleListAndUserTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleListAndUserTimeQuery, ModuleListAndUserTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleListAndUserTimeQuery, ModuleListAndUserTimeQueryVariables>(ModuleListAndUserTimeDocument, options);
        }
export type ModuleListAndUserTimeQueryHookResult = ReturnType<typeof useModuleListAndUserTimeQuery>;
export type ModuleListAndUserTimeLazyQueryHookResult = ReturnType<typeof useModuleListAndUserTimeLazyQuery>;
export type ModuleListAndUserTimeQueryResult = Apollo.QueryResult<ModuleListAndUserTimeQuery, ModuleListAndUserTimeQueryVariables>;
export const ModuleDocument = gql`
    query module($idOrPath: String!) {
  category(idOrPath: $idOrPath) {
    ...ModuleFields
  }
}
    ${ModuleFieldsFragmentDoc}`;

/**
 * __useModuleQuery__
 *
 * To run a query within a React component, call `useModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleQuery({
 *   variables: {
 *      idOrPath: // value for 'idOrPath'
 *   },
 * });
 */
export function useModuleQuery(baseOptions: Apollo.QueryHookOptions<ModuleQuery, ModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleQuery, ModuleQueryVariables>(ModuleDocument, options);
      }
export function useModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleQuery, ModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleQuery, ModuleQueryVariables>(ModuleDocument, options);
        }
export type ModuleQueryHookResult = ReturnType<typeof useModuleQuery>;
export type ModuleLazyQueryHookResult = ReturnType<typeof useModuleLazyQuery>;
export type ModuleQueryResult = Apollo.QueryResult<ModuleQuery, ModuleQueryVariables>;
export const ActivityDocument = gql`
    query activity($id: String!, $isQuiz: Boolean!, $fetchModule: Boolean!) {
  lesson(id: $id) @skip(if: $isQuiz) {
    ...lessonDetails
  }
  quiz(id: $id) @include(if: $isQuiz) {
    ...quizDetails
  }
}
    ${LessonDetailsFragmentDoc}
${QuizDetailsFragmentDoc}`;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isQuiz: // value for 'isQuiz'
 *      fetchModule: // value for 'fetchModule'
 *   },
 * });
 */
export function useActivityQuery(baseOptions: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
      }
export function useActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
        }
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<typeof useActivityLazyQuery>;
export type ActivityQueryResult = Apollo.QueryResult<ActivityQuery, ActivityQueryVariables>;
export const QuizProgressStandardDocument = gql`
    query quizProgressStandard($id: String!) {
  quizProgress(gutenberg_uuid: $id, product: actuacac, type: standard) {
    id
    current_question_id
    score
  }
}
    `;

/**
 * __useQuizProgressStandardQuery__
 *
 * To run a query within a React component, call `useQuizProgressStandardQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizProgressStandardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizProgressStandardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuizProgressStandardQuery(baseOptions: Apollo.QueryHookOptions<QuizProgressStandardQuery, QuizProgressStandardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuizProgressStandardQuery, QuizProgressStandardQueryVariables>(QuizProgressStandardDocument, options);
      }
export function useQuizProgressStandardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizProgressStandardQuery, QuizProgressStandardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuizProgressStandardQuery, QuizProgressStandardQueryVariables>(QuizProgressStandardDocument, options);
        }
export type QuizProgressStandardQueryHookResult = ReturnType<typeof useQuizProgressStandardQuery>;
export type QuizProgressStandardLazyQueryHookResult = ReturnType<typeof useQuizProgressStandardLazyQuery>;
export type QuizProgressStandardQueryResult = Apollo.QueryResult<QuizProgressStandardQuery, QuizProgressStandardQueryVariables>;
export const CertificateListDocument = gql`
    query certificateList {
  category(idOrPath: ",grf,actuacac") {
    ...CategoryBaseFields
    childrensData(order: DESC) {
      validityTimeRange {
        start
        end
      }
      ...CategoryBaseFields
      elapsedTime(search: {products: ["ACTUA_CAC"]}) {
        ...TimeBaseFields
        startTimestamp
        endTimestamp
      }
    }
  }
}
    ${CategoryBaseFieldsFragmentDoc}
${TimeBaseFieldsFragmentDoc}`;

/**
 * __useCertificateListQuery__
 *
 * To run a query within a React component, call `useCertificateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificateListQuery(baseOptions?: Apollo.QueryHookOptions<CertificateListQuery, CertificateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertificateListQuery, CertificateListQueryVariables>(CertificateListDocument, options);
      }
export function useCertificateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificateListQuery, CertificateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertificateListQuery, CertificateListQueryVariables>(CertificateListDocument, options);
        }
export type CertificateListQueryHookResult = ReturnType<typeof useCertificateListQuery>;
export type CertificateListLazyQueryHookResult = ReturnType<typeof useCertificateListLazyQuery>;
export type CertificateListQueryResult = Apollo.QueryResult<CertificateListQuery, CertificateListQueryVariables>;
export const CertificateListChrisDocument = gql`
    query certificateListChris {
  categoriesV2(
    search: {parentIdOrPath: ",grf,actuacac", status: PUBLISHED}
    pagination: {page: 1, per_page: -1, order: DESC}
  ) {
    data {
      childrenDataV2 {
        childrenDataV2 {
          title
          quizzes {
            estimatedTime
            elapsedTime(search: {products: ["ACTUA_CAC"]}) {
              duration
            }
          }
          lessons {
            estimatedTime
            elapsedTime(search: {products: ["ACTUA_CAC"]}) {
              duration
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCertificateListChrisQuery__
 *
 * To run a query within a React component, call `useCertificateListChrisQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateListChrisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateListChrisQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificateListChrisQuery(baseOptions?: Apollo.QueryHookOptions<CertificateListChrisQuery, CertificateListChrisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertificateListChrisQuery, CertificateListChrisQueryVariables>(CertificateListChrisDocument, options);
      }
export function useCertificateListChrisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificateListChrisQuery, CertificateListChrisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertificateListChrisQuery, CertificateListChrisQueryVariables>(CertificateListChrisDocument, options);
        }
export type CertificateListChrisQueryHookResult = ReturnType<typeof useCertificateListChrisQuery>;
export type CertificateListChrisLazyQueryHookResult = ReturnType<typeof useCertificateListChrisLazyQuery>;
export type CertificateListChrisQueryResult = Apollo.QueryResult<CertificateListChrisQuery, CertificateListChrisQueryVariables>;
export const QuizProgressPostDocument = gql`
    mutation quizProgressPost($learningId: Int, $id: String, $type: BookmarkType!, $status: BookmarkStatus!, $score: Int!, $currentQuestionId: Int) {
  quizProgressPost(
    data: {learning_id: $learningId, gutenberg_uuid: $id, type: $type, status: $status, product: actuacac, current_question_id: $currentQuestionId, score: $score}
  ) {
    learning_id
    gutenberg_uuid
    type
  }
}
    `;
export type QuizProgressPostMutationFn = Apollo.MutationFunction<QuizProgressPostMutation, QuizProgressPostMutationVariables>;

/**
 * __useQuizProgressPostMutation__
 *
 * To run a mutation, you first call `useQuizProgressPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuizProgressPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizProgressPostMutation, { data, loading, error }] = useQuizProgressPostMutation({
 *   variables: {
 *      learningId: // value for 'learningId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      score: // value for 'score'
 *      currentQuestionId: // value for 'currentQuestionId'
 *   },
 * });
 */
export function useQuizProgressPostMutation(baseOptions?: Apollo.MutationHookOptions<QuizProgressPostMutation, QuizProgressPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuizProgressPostMutation, QuizProgressPostMutationVariables>(QuizProgressPostDocument, options);
      }
export type QuizProgressPostMutationHookResult = ReturnType<typeof useQuizProgressPostMutation>;
export type QuizProgressPostMutationResult = Apollo.MutationResult<QuizProgressPostMutation>;
export type QuizProgressPostMutationOptions = Apollo.BaseMutationOptions<QuizProgressPostMutation, QuizProgressPostMutationVariables>;
export const LessonTimePostDocument = gql`
    mutation lessonTimePost($id: String, $userID: String!, $startTimestamp: Int!, $endTimestamp: Int!, $categoryId: String) {
  createTime(
    input: {userID: $userID, learningProduct: "ACTUA_CAC", startTimestamp: $startTimestamp, endTimestamp: $endTimestamp, learningType: Lesson, activityId: $id, categoryId: $categoryId}
  ) {
    duration
  }
}
    `;
export type LessonTimePostMutationFn = Apollo.MutationFunction<LessonTimePostMutation, LessonTimePostMutationVariables>;

/**
 * __useLessonTimePostMutation__
 *
 * To run a mutation, you first call `useLessonTimePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonTimePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonTimePostMutation, { data, loading, error }] = useLessonTimePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *      startTimestamp: // value for 'startTimestamp'
 *      endTimestamp: // value for 'endTimestamp'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useLessonTimePostMutation(baseOptions?: Apollo.MutationHookOptions<LessonTimePostMutation, LessonTimePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LessonTimePostMutation, LessonTimePostMutationVariables>(LessonTimePostDocument, options);
      }
export type LessonTimePostMutationHookResult = ReturnType<typeof useLessonTimePostMutation>;
export type LessonTimePostMutationResult = Apollo.MutationResult<LessonTimePostMutation>;
export type LessonTimePostMutationOptions = Apollo.BaseMutationOptions<LessonTimePostMutation, LessonTimePostMutationVariables>;
export const QuizDoneAndTimePostDocument = gql`
    mutation quizDoneAndTimePost($id: String, $type: BookmarkType!, $score: Int!, $userID: String!, $startTimestamp: Int!, $endTimestamp: Int!, $categoryId: String) {
  progress: quizProgressPost(
    data: {gutenberg_uuid: $id, type: $type, status: done, product: actuacac, score: $score}
  ) {
    gutenberg_uuid
    type
  }
  time: createTime(
    input: {userID: $userID, learningProduct: "ACTUA_CAC", startTimestamp: $startTimestamp, endTimestamp: $endTimestamp, learningType: Quiz, activityId: $id, categoryId: $categoryId}
  ) {
    duration
  }
}
    `;
export type QuizDoneAndTimePostMutationFn = Apollo.MutationFunction<QuizDoneAndTimePostMutation, QuizDoneAndTimePostMutationVariables>;

/**
 * __useQuizDoneAndTimePostMutation__
 *
 * To run a mutation, you first call `useQuizDoneAndTimePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuizDoneAndTimePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizDoneAndTimePostMutation, { data, loading, error }] = useQuizDoneAndTimePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      score: // value for 'score'
 *      userID: // value for 'userID'
 *      startTimestamp: // value for 'startTimestamp'
 *      endTimestamp: // value for 'endTimestamp'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useQuizDoneAndTimePostMutation(baseOptions?: Apollo.MutationHookOptions<QuizDoneAndTimePostMutation, QuizDoneAndTimePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuizDoneAndTimePostMutation, QuizDoneAndTimePostMutationVariables>(QuizDoneAndTimePostDocument, options);
      }
export type QuizDoneAndTimePostMutationHookResult = ReturnType<typeof useQuizDoneAndTimePostMutation>;
export type QuizDoneAndTimePostMutationResult = Apollo.MutationResult<QuizDoneAndTimePostMutation>;
export type QuizDoneAndTimePostMutationOptions = Apollo.BaseMutationOptions<QuizDoneAndTimePostMutation, QuizDoneAndTimePostMutationVariables>;
export const ContactPostDocument = gql`
    mutation contactPost($service: ReportService!, $email: String!, $subType: String!, $message: String!) {
  contactPost(
    input: {tags: [$service], service: $service, email: $email, platform: web, type: "Contact client RF e-Learning CAC", subType: $subType, message: $message, attachments: []}
  ) {
    message
  }
}
    `;
export type ContactPostMutationFn = Apollo.MutationFunction<ContactPostMutation, ContactPostMutationVariables>;

/**
 * __useContactPostMutation__
 *
 * To run a mutation, you first call `useContactPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactPostMutation, { data, loading, error }] = useContactPostMutation({
 *   variables: {
 *      service: // value for 'service'
 *      email: // value for 'email'
 *      subType: // value for 'subType'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactPostMutation(baseOptions?: Apollo.MutationHookOptions<ContactPostMutation, ContactPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactPostMutation, ContactPostMutationVariables>(ContactPostDocument, options);
      }
export type ContactPostMutationHookResult = ReturnType<typeof useContactPostMutation>;
export type ContactPostMutationResult = Apollo.MutationResult<ContactPostMutation>;
export type ContactPostMutationOptions = Apollo.BaseMutationOptions<ContactPostMutation, ContactPostMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AnyFlashcardV2": [
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "CdrRoadSignFlashcard",
      "FeuVertRoadSignFlashcard",
      "FleAfasVocabularyFlashcard",
      "FleCcfsVocabularyFlashcard",
      "FleEfemVocabularyFlashcard",
      "FleJobsFlashcard",
      "FleVocabularyFlashcard",
      "MosalinguaFlashcard"
    ],
    "CartStripeIntentV2": [
      "CartStripePaymentIntent",
      "CartStripeSetupIntent"
    ],
    "CursorPaginatedResponse": [
      "PaginatedBriefsOutput",
      "PaginatedCategoriesOutput",
      "PaginatedFlashcardsV2Output",
      "PaginatedFlashcardsV2RawOutput_2411",
      "PaginatedLessonsOutput",
      "PaginatedLessonsOutput_2406",
      "PaginatedQuizzesOutput",
      "PaginatedQuizzesOutput_2406",
      "PaginatedRightOfWayTestsOutput",
      "PaginatedTrainingPapersOutput",
      "PaginatedTrainingPapersOutput_2406"
    ],
    "FacetUnion": [
      "PostHasResponseFacet",
      "PostTypeFacet",
      "PostSectorFacet",
      "PostSubjectFacet",
      "PostLevelFacet",
      "PostOrientationFacet"
    ],
    "FlashcardV2": [
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "CdrRoadSignFlashcard",
      "FeuVertRoadSignFlashcard",
      "FleAfasVocabularyFlashcard",
      "FleCcfsVocabularyFlashcard",
      "FleEfemVocabularyFlashcard",
      "FleJobsFlashcard",
      "FleVocabularyFlashcard",
      "MosalinguaFlashcard"
    ],
    "OffsetPaginatedResponse": [
      "PaginatedCartsOutput",
      "PaginatedCartsV2Output",
      "PaginatedCodesOutput",
      "PaginatedCodesOutput_2402",
      "PaginatedDealsOutput",
      "PaginatedDealsOutput_2402",
      "PaginatedEtsVouchersOutput_2408",
      "PaginatedPartnersOutput",
      "PaginatedPartnersOutput_2402",
      "PaginatedPrepaidGoodsOutput",
      "PaginatedPrepaidGoodsOutput_2402",
      "PaginatedPricesOutput_2402",
      "PaginatedProductsOutput",
      "PaginatedProductsOutput_2402",
      "PaginatedReportSummariesOutput",
      "PaginatedRoleMappingsOutput",
      "PaginatedToeicPromoCodesOutput",
      "PaginatedUsersOutput",
      "QuizProgressPaginated"
    ],
    "PrepaidGoodSource": [
      "AndroidSubscriptionPrepaidGoodSource",
      "CartPrepaidGoodSource",
      "CodePrepaidGoodSource",
      "IosSubscriptionPrepaidGoodSource",
      "StripeSubscriptionPrepaidGoodSource"
    ],
    "PrepaidGoodSource_2402": [
      "AndroidSubscriptionPrepaidGoodSource_2402",
      "CartPrepaidGoodSource_2402",
      "CartV2PrepaidGoodSource_2402",
      "CodePrepaidGoodSource_2402",
      "IosSubscriptionPrepaidGoodSource_2402",
      "StripeSubscriptionPrepaidGoodSource_2402"
    ],
    "PriceInterface": [
      "OneTimePrice",
      "RecurringPrice"
    ],
    "PriceInterface_2402": [
      "ActivationCodePrice_2402",
      "OneTimePrice_2402",
      "RecurringPrice_2402"
    ],
    "PriceUnion": [
      "RecurringPrice",
      "OneTimePrice"
    ],
    "PriceUnion_2402": [
      "RecurringPrice_2402",
      "OneTimePrice_2402",
      "ActivationCodePrice_2402"
    ],
    "RelatedActivitiesData": [
      "Flashcard",
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "CdrRoadSignFlashcard",
      "FeuVertRoadSignFlashcard",
      "FleAfasVocabularyFlashcard",
      "FleCcfsVocabularyFlashcard",
      "FleEfemVocabularyFlashcard",
      "FleJobsFlashcard",
      "FleVocabularyFlashcard",
      "MosalinguaFlashcard",
      "Lesson",
      "PastPaper",
      "Quiz"
    ],
    "RelatedActivitiesData_2406": [
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "CdrRoadSignFlashcard",
      "FeuVertRoadSignFlashcard",
      "FleAfasVocabularyFlashcard",
      "FleCcfsVocabularyFlashcard",
      "FleEfemVocabularyFlashcard",
      "FleJobsFlashcard",
      "FleVocabularyFlashcard",
      "MosalinguaFlashcard",
      "Lesson_2406",
      "Quiz_2406",
      "TrainingPaper_2406"
    ],
    "RelatedActivitiesData_2411": [
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "CdrRoadSignFlashcard",
      "FeuVertRoadSignFlashcard",
      "FleAfasVocabularyFlashcard",
      "FleCcfsVocabularyFlashcard",
      "FleEfemVocabularyFlashcard",
      "FleJobsFlashcard",
      "FleVocabularyFlashcard",
      "MosalinguaFlashcard",
      "FlashcardV2Raw_2411",
      "Lesson_2406",
      "Quiz_2406",
      "TrainingPaper_2406"
    ]
  }
};
      export default result;
    