// React
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

// Colors
import { colorCAC } from "../../styles/color";

// Images
import emptyAttestationIcon from "../../assets/ic-attestation-empty.svg";

/**
 * Use styles
 */
const useStyles = makeStyles(() => ({
  subtitle: {
    maxWidth: 581,
    textAlign: "center",
    fontSize: 16,
    letterSpacing: 0.5,
    color: colorCAC.black,
  },
  title: {
    fontWeight: 600,
    letterSpacing: "normal",
    maxWidth: 620,
    textAlign: "center",
  },
}));

/**
 * No result certification props
 */
interface INoResultProps {
  isCertificate?: boolean;
  titleCustom?: string;
  subTitleCustom?: string;
}

/**
 * No Result Certification
 */
const NoResult = (props: INoResultProps) => {
  const classes = useStyles();
  const { isCertificate, titleCustom, subTitleCustom } = props;

  return (
    <Grid
      container={true}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {isCertificate ? (
        <img src={emptyAttestationIcon} alt="information icone" />
      ) : null}
      <Typography variant="h6" gutterBottom={true} className={classes.title}>
        {titleCustom}
      </Typography>
      <Typography component="p" className={classes.subtitle}>
        {subTitleCustom}
      </Typography>
    </Grid>
  );
};

export default React.memo(NoResult);
