// Polyfill
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// Core
import { createBrowserHistory, History } from "history";
import React from "react";
import ReactDOM from "react-dom";

// Material ui
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";

// Google analytics
import ReactGA from "react-ga";
import { Router } from "react-router-dom";

// Contantes
import { CONST } from "./config/constant";

// ServiceWorker
import * as serviceWorker from "./serviceWorker";

// Providers
import { ScrollProvider } from "./shared/Scroll.provider";
import { SnackbarProvider } from "notistack";

// Assets
import "./styles/index.scss";

// Store
import { Provider } from "react-redux";
import { store } from "./store/createStore";

// Component
import App from "./views/App/App";

// Keycloak
import { AuthenticationProvider } from "@dsk-lib/user";
import { GqlProvider } from "./shared/Gql.provider";

/** Google analytics init */
ReactGA.initialize([
  {
    debug: process.env.NODE_ENV !== "production",
    gaOptions: {},
    titleCase: false,
    trackingId: CONST.GA_ID,
  },
]);

/** Referrer */
!localStorage.getItem("referrer") &&
  !document.referrer.includes(window.location.hostname) &&
  CONST.PAGE_PRODUCTLIST.includes(document.referrer) &&
  localStorage.setItem("referrer", document.referrer);

/** Browser history */
const history: History = createBrowserHistory();

/** History listener for GA */
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const themeRF = createTheme({
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#77C35E",
    },
    text: {
      primary: "#1b1b1d",
      secondary: "#18202f",
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        height: "56px",
        minHeight: "56px",
      },
    },
    MuiSnackbarContent: {
      root: {
        display: "block",
      },
      action: {
        display: "block",
        margin: "0 !important",
        padding: "0 !important",
      },
    },
  },
});

const keycloakConfig = {
  url: CONST.KEYCLOAK.URL,
  realm: CONST.KEYCLOAK.REALM,
  clientId: CONST.KEYCLOAK.CLIENTID,
};

ReactDOM.render(
  <AuthenticationProvider keycloakConfig={keycloakConfig}>
    <GqlProvider>
      <Router history={history}>
        <Provider store={store}>
          <ScrollProvider>
            <MuiThemeProvider theme={themeRF}>
              <SnackbarProvider>
                <App />
              </SnackbarProvider>
            </MuiThemeProvider>
          </ScrollProvider>
        </Provider>
      </Router>
    </GqlProvider>
  </AuthenticationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
