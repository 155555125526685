import {
  BlocDataFragment,
  ModuleFieldsFragment,
  ThemeDataFragment
} from "../graphql/index";
import { Activity, ActivityPath, Quiz } from "./activity.model";

/**
 * Module interface
 */
export type Module = ModuleFieldsFragment & {
  recursiveActivities: ActivityPath[];
  nbActivities: number;
  estimatedTime: number;
  confirmedTime: number;
  progress: number;
  blocs: Bloc[];
  expired: boolean;
  expertOpinion?: string;
  file?: string;
  approvalType: string;
  monthFolder?: string;
  isStarted: boolean;
  canAccess: boolean;
};

/**
 * Bloc slug enum
 */
export enum BlocSlug {
  PratiqueComptable = "pratique-comptable",
  EnvironnementLegal = "environnement-legal-et-professionnel",
  DossierDuMois = "dossier-du-mois",
  InfoExpert = "info-de-lexpert"
}

/**
 * Bloc interface
 */
export type Bloc = BlocDataFragment & {
  themes: Theme[];
  recursiveActivities: ActivityPath[];
  nbActivities: number;
  estimatedTime: number;
  confirmedTime: number;
  progress: number;
  starterQuiz?: Quiz;
  isStarted: boolean;
};

/**
 * Theme type
 */
export type Theme = ThemeDataFragment & {
  activitiesMapped: Activity[];
  nbActivities: number;
  estimatedTime: number;
  confirmedTime: number;
  progress: number;
  isStarted: boolean;
};
