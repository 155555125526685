// React
import React, { useState } from "react";
import { withRouter } from "react-router";
// Material ui
import {
  Box,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
// Libs
import Helmet from "react-helmet";
// Color
import { colorCAC } from "../../styles/color";
// Component
import FaqExpansionPanel from "../../features/FaqExpansionPanel/FaqExpansionPanel";
// Data
import { provideFaqData } from "../../data/faq.data";
// Model
import { Faq } from "../../models/faq.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
      },
      /* ----------- iPhone X ----------- */
      /* Portrait */
      ["@media only screen and (min-device-width: 375px)  and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)"]:
        {
          paddingBottom: 180,
        },
      /* ----------- iPhone 6+, 7+ and 8+ ----------- */
      /* Portrait */
      ["@media only screen  and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)"]:
        {
          paddingBottom: 180,
        },
    },
    header: {
      marginBottom: 10,
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.greenDark,
      paddingLeft: 6,
      paddingRight: 6,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    button: {
      color: "white",
      fontWeight: "bold",
      marginTop: theme.spacing(5),
      textTransform: "none",
    },
  })
);

/**
 * FrequentlyAskedQuestion Component
 */
const FrequentlyAskedQuestion = () => {
  /** Classes  */
  const classes = useStyles();

  /** Faq data */
  const faqList = provideFaqData().faqList;

  const [expanded, setExpanded] = useState<string>("");

  const expand =
    (panel: string) =>
    (_event: React.ChangeEvent<{}>, _newExpanded: boolean) => {
      setExpanded(panel);
    };

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>FAQ - RF e-Learning CAC</title>
      </Helmet>

      <Container maxWidth="md" className={classes.container}>
        <Grid item={true} xs={true}>
          <Grid
            container={true}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.header}
          >
            <Grid item={true}>
              <Box fontSize="h6.fontSize" className={classes.title}>
                Foire aux questions
              </Box>
            </Grid>
          </Grid>

          {faqList
            ? faqList.map((faq: Faq, index: number) => (
                <FaqExpansionPanel
                  key={index}
                  expandedPanel={expand}
                  panelId={`panel${index}`}
                  expanded={expanded === `panel${index}`}
                  title={faq.title}
                  content={faq.content}
                />
              ))
            : null}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(React.memo(FrequentlyAskedQuestion));
