// React
import React, { useState } from "react";
// Material
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Hidden,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
// Models
import iconAttestation from "../../assets/ic-attestations.svg";
import iconTimeGreen from "../../assets/ic-timer-green.svg";
import { User } from "../../models/user.model";
// Helpers
import {
  formattedDate,
  getSecondsFormatted,
} from "../../helpers/date-fomatter.helper";
// Icons
import ListItemIcon from "@material-ui/core/ListItemIcon";
// Colors
import { colorCAC } from "../../styles/color";
import clsx from "clsx";
import { isIPad13 } from "react-device-detect";
import { RESPONSIVE } from "../../config/responsive";
import Certificate from "../Certificate/Certificate";
import { getPdf } from "../../services/utility.service";
import { useSnackbar } from "notistack";
import { Module } from "../../models/module.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(0.5, 0.5),
      minHeight: "min-content",
    },
    carIpad: {
      padding: theme.spacing(0.5, 0.5),
      minHeight: "min-content",
      width: "569px !important",
      height: "160px !important",
    },
    cardLeftBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTopRightRadius: "0.5em",
        borderBottomRightRadius: "0.5em",
      },
      [theme.breakpoints.down("sm")]: {
        borderBottomRightRadius: "0.5em",
        borderBottomLeftRadius: "0.5em",
      },
    },
    cardContainerBorder: {
      [RESPONSIVE.IPAD_3_AND_4_AND_PRO_9.LANDSCAPE]: {
        flexBasis: "75%!important",
        height: "100%",
      },
      [RESPONSIVE.IPAD_3_AND_4_AND_PRO_9.PORTRAIT]: {
        flexBasis: "100%!important",
      },
      "@supports (-webkit-touch-callout: none)": {},
    },
    cardRightBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTopLeftRadius: "0.5em",
        borderBottomLeftRadius: "0.5em",
      },
      [theme.breakpoints.down("sm")]: {
        borderTopLeftRadius: "0.5em",
        borderTopRightRadius: "0.5em",
      },
    },
    cardContent: {
      "&:last-child": {
        paddingBottom: 10,
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative",
      },
    },
    cardCheckbox: {
      position: "absolute",
      top: 10,
      right: 10,
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.anthracite,
      padding: 5,
      [theme.breakpoints.up("xs")]: {
        marginRight: 10,
      },
    },
    subTitle: {
      textAlign: "center",
      fontSize: 14,
    },
    subTitleTime: {
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        color: "#7fbc4d",
      },
    },
    subTitleTimeBolder: {
      fontWeight: 600,
    },
    chipType: {
      color: "white",
      fontWeight: 600,
    },
    cardDownload: {
      "&:hover": {
        backgroundColor: "#D8DBDC",
      },
      cursor: "pointer",
      width: "100%",
    },
    cardDownloadProgress: {
      cursor: "progress",
    },
    cardContentDownload: {
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px dashed #bbbcbe",
      },
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px dashed #bbbcbe",
      },
      padding: 5,
      "&:last-child": {
        paddingBottom: 5,
      },
    },
    cocardeContainer: {
      display: "flex",
    },
    cocardeIcon: {
      height: 26,
    },
  })
);

/**
 * Circular progress
 */
const ColorCircularProgress = withStyles({
  root: {
    color: "#000",
  },
})(CircularProgress);

/**
 * Form control label checkbox
 */
const CertificateFormControlLabel = withStyles({
  root: {
    marginRight: 0,
  },
})(MuiFormControlLabel);

/**
 * Green checkbox
 */
const GreenCheckbox = withStyles({
  root: {
    color: colorCAC.greenRF,
    "&$checked": {
      color: colorCAC.greenRF,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

/**
 * CertificateCardProps props
 */
interface ICertificateCardProps {
  certificate: Module;
  user: User;
  selectState: boolean;
  checked: boolean;
  addCertificateToDownloadList(moduleId: string): void;
  disabledCheckbox: (moduleId: string) => boolean;
}

/**
 * CertificateCard component
 */
const CertificateCard = (props: ICertificateCardProps) => {
  /** Classes  */
  const classes = useStyles();
  /** Theme */
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  /** Props */
  const {
    certificate,
    user,
    selectState,
    checked,
    addCertificateToDownloadList,
    disabledCheckbox,
  } = props;

  console.log("certificate", certificate);
  /** downloading state */
  const [downloading, setDownloading] = useState(false);
  /** downloaded state */
  const [downloaded, setDownloaded] = useState(false);
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  const getCertificate = async () => {
    if (!downloading) {
      setDownloading(true);
      const formattedStartDate = certificate.validityTimeRange?.start
        ? formattedDate(
            new Date(certificate.validityTimeRange?.start),
            "-",
            "mm/yyyy"
          )
        : "";
      const pdfName: string = `Attestation-RF-e-Learning-CAC-${formattedStartDate}-${user.firstName}-${user.lastName}-${certificate._id}`;

      try {
        await getPdf(
          <Certificate
            certificate={certificate}
            fullName={user?.lastName + " " + user?.firstName}
          />,
          pdfName
        );
      } catch (e) {
        enqueueSnackbar(
          "Une erreur est survenue pendant la génération de l'attestation",
          {
            variant: "error",
          }
        );
      }

      setDownloading(false);
      setDownloaded(true);
      setTimeout(() => {
        setDownloaded(false);
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <Grid
        container={true}
        item={true}
        xs={true}
        className={clsx({
          [classes.card]: !isIPad13,
          [classes.carIpad]: isIPad13,
        })}
      >
        {selectState ? (
          <Hidden smDown={true}>
            <Grid item={true} md={1} xs={1} container={true}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={checked}
                    onChange={() =>
                      addCertificateToDownloadList(certificate._id)
                    }
                    disabled={disabledCheckbox(certificate._id)}
                    value="checked"
                  />
                }
                label=""
              />
            </Grid>
          </Hidden>
        ) : null}
        <Grid
          item={true}
          xs={12}
          md={selectState ? 8 : 9}
          className={clsx({
            [classes.cardContainerBorder]: isIPad13,
          })}
        >
          <Card className={classes.cardLeftBorder}>
            <CardContent className={classes.cardContent}>
              {selectState ? (
                <Hidden mdUp={true}>
                  <CertificateFormControlLabel
                    className={classes.cardCheckbox}
                    control={
                      <GreenCheckbox
                        checked={checked}
                        disabled={disabledCheckbox(certificate._id)}
                        onChange={() =>
                          addCertificateToDownloadList(certificate._id)
                        }
                        value="checked"
                      />
                    }
                    label=""
                  />
                </Hidden>
              ) : null}

              <Grid
                container={true}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item={true} md={12}>
                  <Grid
                    container={true}
                    direction="row"
                    alignItems="baseline"
                    justifyContent={isUpMd ? "flex-start" : "space-between"}
                  >
                    <Grid item={true} xs="auto">
                      <Box
                        marginBottom={1}
                        marginTop={1}
                        fontWeight="600"
                        color="textPrimary"
                        fontSize="body1.fontSize"
                      >
                        <span className={classes.title}>
                          Attestation {certificate.title}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item={true} xs={12} md={"auto"}>
                      <Box
                        marginBottom={1}
                        marginTop={1}
                        fontSize={16}
                        component="span"
                        color="textPrimary"
                      >
                        Module terminé:&nbsp;
                      </Box>
                      <Box
                        marginBottom={1}
                        marginTop={1}
                        fontSize={16}
                        component="span"
                        fontWeight="600"
                        color="textPrimary"
                      >
                        le{" "}
                        {
                          //! TODO pour le moment pas possible il faut enregistrer l'elapsedTime des activités sur la catégorie du module pour avoir la date de fin
                          /** {certificate.endDate
                          ? formattedDate(certificate.endDate)
                          : ""} */
                        }
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} md={12}>
                  <Grid
                    container={true}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item={true} xs="auto">
                      <img src={iconTimeGreen} alt="icon-attestation" />
                    </Grid>
                    <Grid item={true} xs="auto">
                      <Grid container={true} direction="column">
                        <Box marginBottom={1} marginTop={1}>
                          <Grid item={true} xs={12}>
                            <Typography
                              className={classes.subTitle}
                              color="textSecondary"
                              gutterBottom={false}
                              component="span"
                            >
                              Temps de formation réalisé&nbsp;
                            </Typography>
                            <Typography
                              className={`${classes.subTitleTime} ${classes.subTitleTimeBolder}`}
                              gutterBottom={false}
                              component="span"
                            >
                              {getSecondsFormatted(certificate.confirmedTime)}
                            </Typography>
                          </Grid>
                          <Grid item={true} xs={12}>
                            <Typography
                              className={classes.subTitle}
                              color="textSecondary"
                              gutterBottom={false}
                              component="span"
                            >
                              Temps du module&nbsp;
                            </Typography>
                            <Typography
                              className={classes.subTitleTime}
                              gutterBottom={false}
                              component="span"
                            >
                              {getSecondsFormatted(certificate.estimatedTime)}
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item={true} xs={12} md={3} container={true}>
          <Card
            className={`${classes.cardDownload} ${classes.cardRightBorder} ${
              downloading ? classes.cardDownloadProgress : ""
            }`}
            style={{ display: "flex", justifyContent: "center" }}
            onClick={getCertificate}
          >
            <Grid
              container={true}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.cardContentDownload}
            >
              <Grid item={true} md={2} className={classes.cocardeContainer}>
                <ListItemIcon>
                  {downloading ? (
                    <ColorCircularProgress size={17} thickness={5} />
                  ) : downloaded ? (
                    <OfflinePinIcon />
                  ) : (
                    <img
                      src={iconAttestation}
                      alt="icon-attestation"
                      className={classes.cocardeIcon}
                    />
                  )}
                </ListItemIcon>
              </Grid>
              <Grid item={true} md={8}>
                <Typography
                  className={classes.subTitle}
                  color="textPrimary"
                  gutterBottom={false}
                >
                  {downloading
                    ? `Téléchargement...`
                    : downloaded
                    ? `Téléchargé !`
                    : `Télécharger l’attestation`}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(CertificateCard);
