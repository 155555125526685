// React
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// Libs
import Helmet from "react-helmet";
// Components
import LastModuleBlocs from "../../features/LastModuleBlocs/LastModuleBlocs";
import LastModuleCard from "../../features/LastModuleCard/LastModuleCard";
import LastModuleInfoCard from "../../features/LastModuleInfoCard/LastModuleInfoCard";
import LastModuleInfoExpertCard from "../../features/LastModuleInfoExpertCard/LastModuleInfoExpertCard";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
// Models
import { Module } from "../../models/module.model";
// Keycloak
import { useModuleLazyQuery } from "../../graphql";
import { mapModule } from "../../services/module.service";
import { getMostRecentModule } from "../../services/user.service";
import { useHistory } from "react-router";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "10%",
        paddingRight: "10%",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      "@supports (-webkit-touch-callout: none)": {
        marginBottom: 149,
      },
    },
    bandeau: {
      objectFit: "contain",
      marginBottom: 12,
    },
  })
);

/**
 * Home Component
 */
const Home = () => {
  /** Classes  */
  const classes = useStyles();
  /** loading state */
  const [loading, setLoading] = useState(true);
  /** Last module state */
  const [module, setModule] = useState<Module>();
  /** History */
  const history = useHistory();

  /** GraphQl */
  const [getModule, { data, error }] = useModuleLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  /** Use effect */
  useEffect(() => {
    const moduleId = getMostRecentModule();
    if (moduleId) {
      getModule({
        variables: {
          idOrPath: moduleId,
        },
      });
    } else {
      history.push("/mon-profil");
    }
  }, []);

  useEffect(() => {
    if (data?.category) {
      setModule(mapModule(data.category));
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Accueil - RF e-Learning CAC</title>
      </Helmet>
      {!loading && module ? (
        <React.Fragment>
          <Grid
            container={true}
            direction="column"
            spacing={2}
            className={classes.container}
          >
            <Grid item={true}>
              <LastModuleCard module={module} />
              <Hidden mdUp={true}>
                <LastModuleBlocs module={module} />
              </Hidden>
            </Grid>
            {module.monthFolder && (
              <Grid item={true}>
                <LastModuleInfoCard module={module} />
              </Grid>
            )}
            {module.expertOpinion && (
              <Grid item={true}>
                <LastModuleInfoExpertCard module={module} />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid
            container={true}
            direction="column"
            spacing={2}
            className={classes.container}
          >
            <SkeletonLoader height={350} />
            <SkeletonLoader height={350} />
            <SkeletonLoader height={350} />
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
