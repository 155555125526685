// React
import React from "react";

// Material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Lib
import HTMLReactParser from "html-react-parser";
import ContentLoader from "react-content-loader";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "hidden",
      maxWidth: "100%",
      marginBottom: 25,
      [theme.breakpoints.down("sm")]: {
        padding: 20,
      },
    },
    unsetMarginBottom: {
      marginBottom: 0,
    },
  })
);

/**
 * Skeleton loader props
 */
interface ISkeletonLoaderProps {
  noMarginBottom?: boolean;
  loader?: string;
  width?: number;
  height?: number;
}

/**
 * Skeleton loader
 */
const SkeletonLoader = (props: ISkeletonLoaderProps) => {
  /** Classes  */
  const classes = useStyles();

  /** Props */
  const { noMarginBottom, loader, width, height } = props;

  /** Retreive SVG shapes from constants */
  const getLoader = () => {
    if (loader) {
      return HTMLReactParser(loader);
    }
    return;
  };

  return (
    <ContentLoader
      className={`${classes.container} ${
        noMarginBottom ? classes.unsetMarginBottom : ""
      }`}
      style={{ width: width ? width : "unset" }}
      height={height ? height : 160}
      width={width ? width : 744}
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#e3e3e3"
    >
      {getLoader()}
    </ContentLoader>
  );
};

export default React.memo(SkeletonLoader);
