// React
import React, { useEffect, useState } from "react";

// Material
import { Container, makeStyles, Paper, Theme } from "@material-ui/core";

// Styles
import "../../styles/activity.scss";

// Models
import {
  ActivityPath,
  Lesson as LessonCAC,
  Type,
} from "../../models/activity.model";
import YouTubePlayer from "../Video/YouTubePlayer";
import NextActivityBtn from "../NextActivity/NextActivityBtn";
import Alert from "../Alert/Alert";
import ActivityPager from "../ActivityPager/ActivityPager";
import { formatLessonSummaryContent } from "../../helpers/activity.helper";
import { getUserId } from "../../services/user.service";
import { getContentList, getYoutubeId } from "../../services/activity.service";
import { useLessonTimePostMutation } from "../../graphql";
import { getTimestamp } from "../../helpers/date.helper";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  videoContainer: {
    textAlign: "center",
  },
  backgroundContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    marginTop: 40,
    marginBottom: 140,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  secondgroundContainer: {
    backgroundColor: "#FFFFFF",
    width: "97%",
    margin: "0 auto",
    marginTop: -15,
    marginBottom: -15,
  },
  foregroundContainer: {
    width: "97%",
    margin: "0 auto",
    marginTop: -10,
    position: "relative",
    display: "inline-block",
    left: "50%",
    overflow: "hidden",
    transform: "translateX(-50%)",
    marginBottom: -15,
  },
  flipbookContainer: {
    width: "100% !important",
  },
}));

/**
 * Lesson props
 */
interface ILessonProps {
  lesson: LessonCAC;
  fontSize: number;
  isExpired: boolean;
  scrollToTop: () => void;
  nextActivity?: ActivityPath;
  playTimer: (play: boolean) => void;
}
/**
 * Lesson component
 */
const Lesson = ({
  lesson,
  fontSize,
  scrollToTop,
  nextActivity,
  playTimer,
  isExpired,
}: ILessonProps) => {
  console.log("yo");
  /** Classes  */
  const classes = useStyles();
  /** Is video */
  const isVideo: boolean = lesson.type === Type.Video;
  /** Page state */
  const [page, setPage] = useState(1);
  /** Content list */
  const [contentList, setContentList] = useState<string[]>();
  /** ErrorMessage */
  const [errorMessage, setErrorMessage] = useState<string>();

  /**
   * Handle page navigation
   * @param pageValue
   */
  const handlePageNavigation = (pageValue: number): void => {
    setPage(pageValue);
    scrollToTop();
  };

  const [saveLessonTime] = useLessonTimePostMutation();

  /**
   * Handle play timer
   * @param play
   */
  const handlePlayTimer = (play: boolean): void => {
    playTimer(play);
  };

  /**
   * Remove useless space and other unicode char
   */
  const replaceSpace = (lessonContent: string): string => {
    return lessonContent?.replace(/[\u200B-\u200D\uFEFF]/g, "") ?? "";
  };

  const getPagePourcentage = (): number => {
    return contentList ? (100 * page) / contentList.length : 0;
  };

  const updateContentList = async () => {
    if (lesson && lesson.html) {
      const newContentList = await getContentList(lesson);
      console.log("newContentList", newContentList);
      try {
        setErrorMessage("");
        newContentList[0] = formatLessonSummaryContent(newContentList[0]);
      } catch (e) {
        setErrorMessage("Le sommaire de cette leçon n'a pas pu être formaté.");
      }
      setContentList(newContentList);
    }
  };
  console.log("lesson", lesson);

  useEffect(() => {
    updateContentList();
    const startTimestamp = getTimestamp(new Date());

    return () => {
      const endTimestamp = getTimestamp(new Date());
      const duration = endTimestamp - startTimestamp;
      console.log("duration", duration);
      if (lesson.estimatedTime && !isExpired) {
        const durationWithBuffer = duration + lesson.confirmedTime;
        if (durationWithBuffer <= lesson.estimatedTime) {
          console.log("test1");
          console.log("lesson.parentCategories", lesson.parentCategories);
          saveLessonTime({
            variables: {
              id: lesson._id,
              userID: getUserId(),
              startTimestamp: startTimestamp,
              endTimestamp: endTimestamp,
              categoryId: lesson.parentCategories?.[0]?.parent?.parent?._id,
            },
          });
        } else {
          console.log("test2");
          if (lesson.estimatedTime - lesson.confirmedTime > 0 && !isExpired) {
            const endTimestampLimited =
              startTimestamp + (lesson.estimatedTime - lesson.confirmedTime);
            saveLessonTime({
              variables: {
                id: lesson._id,
                userID: getUserId(),
                startTimestamp: startTimestamp,
                endTimestamp: endTimestampLimited,
                categoryId: lesson.parentCategories?.[0]?.parent?.parent?._id,
              },
            });
          }
        }
      }
    };
  }, [lesson]);

  return (
    <React.Fragment>
      {isVideo && (
        <Container maxWidth="md" className={classes.videoContainer}>
          <YouTubePlayer
            videoId={getYoutubeId(lesson)}
            handleOver={() => console.log("over")}
            handlePlayTimer={handlePlayTimer}
          />
          <NextActivityBtn nextActivity={nextActivity} />
        </Container>
      )}
      {contentList && (
        <React.Fragment>
          <Alert type="error">{errorMessage}</Alert>
          {isVideo && lesson.assetLinks ? (
            lesson.assetLinks.map((assetLink, index) =>
              assetLink?.media?.data ? (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: assetLink.media.data.html,
                  }}
                />
              ) : null
            )
          ) : (
            <Paper elevation={1} className={classes.backgroundContainer}>
              <Paper elevation={1} className={classes.secondgroundContainer}>
                <Paper
                  elevation={1}
                  className={classes.foregroundContainer}
                  style={{ fontSize: `${fontSize}px` }}
                >
                  <div
                    className={`${lesson.tags?.join(" ")}`}
                    dangerouslySetInnerHTML={{
                      __html: replaceSpace(contentList![page - 1]),
                    }}
                  />
                </Paper>
              </Paper>
            </Paper>
          )}
          {!isVideo && (
            <Paper
              elevation={3}
              style={{
                position:
                  navigator.platform === "iPad" || "iPhone"
                    ? "fixed"
                    : "absolute",
                bottom: 0,
                height: "70px",
                left: 0,
                right: 0,
              }}
            >
              <ActivityPager
                pageNavigation={handlePageNavigation}
                page={page}
                totalPages={contentList.length}
                pagePercentage={getPagePourcentage()}
                nextActivity={nextActivity}
              />
            </Paper>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(Lesson);
