// React
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// Material ui
import {
  Box,
  Button,
  createStyles,
  Fab,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
// Libs
import Helmet from "react-helmet";
// Components
import CertificateCard from "../../features/CertificateCard/CertificateCard";
import CertificateDownloadCard from "../../features/CertificateDownloadCard/CertificateDownloadCard";
import NoResult from "../../features/NoResult/NoResult";
import SkeletonLoader from "../../features/SkeletonLoader/SkeletonLoader";
// Color
import { colorCAC } from "../../styles/color";
// Keycloak
import { useSnackbar } from "notistack";
import { useCertificateListChrisLazyQuery } from "../../graphql";

import { useSelector } from "react-redux";
import { StoreState } from "../../store";
import { User } from "../../models/user.model";

import { Module } from "../../models/module.model";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 16,
      [theme.breakpoints.up("md")]: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "25%",
      },
    },
    header: {
      position: "sticky",
      background: colorCAC.grayLight,
      width: "calc(100% + 20px)",
      zIndex: 1,
      top: 0,
      marginTop: -10,
      marginLeft: -10,
      padding: 10,
    },
    title: {
      color: "#FFFFFF",
      backgroundColor: colorCAC.yellow,
      paddingLeft: 6,
      paddingRight: 6,
      fontWeight: "bold",
    },
    buttonEnableSelection: {
      color: colorCAC.black,
      borderColor: colorCAC.black,
      fontWeight: 600,
      textTransform: "none",
    },
    button: {
      color: "white",
      fontWeight: "bold",
      marginTop: theme.spacing(5),
      textTransform: "none",
    },
    containerListAttestation: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "45%",
      },
    },
  })
);

/**
 * Certificate Component
 */
const Certificate = (props: RouteComponentProps) => {
  const { history } = props;
  /** Classes  */
  const classes = useStyles();
  /** loading state */
  const [loading, setLoading] = useState(true);
  /** Certificate List state */
  const [certificatesList, setCertificatesList] = useState<Module[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  /** select multiple certificate state */
  const [selectMultipleCertificate, setSelectMultipleCertificate] =
    useState<boolean>(false);
  /** Certificates to download */
  const [certificatesToDownload, setCertificatesToDownload] = useState<
    string[]
  >([]);
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  const totalMaxToDownload: number = 12;

  /** GraphQl */
  const [getCertificateList, { data, error }] =
    useCertificateListChrisLazyQuery({
      errorPolicy: "all",
      fetchPolicy: "network-only",
    });

  /** Use effect */
  useEffect(() => {
    getCertificateList();
  }, []);

  useEffect(() => {
    if (data?.categoriesV2) {
      setCertificatesList([]);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  /** Use effect when select state change */
  useEffect(() => {
    setCertificatesToDownload([]);
  }, [selectMultipleCertificate]);

  const disabledCheckboxValue = (moduleId: string): boolean => {
    return (
      !isCheckedCertificate(moduleId) &&
      certificatesToDownload.length >= totalMaxToDownload
    );
  };

  /** Use effect when certificatesToDownload is up or equal to 12 */
  useEffect(() => {
    if (certificatesToDownload.length >= totalMaxToDownload) {
      enqueueSnackbar(
        "Attention, vous pouvez sélectionner jusqu'à 12 attestations simultanément.",
        {
          variant: "warning",
          persist: false,
        }
      );
    }
  }, [certificatesToDownload]);

  /**
   * add a certificate to download
   * @param moduleId
   */
  const addCertificateToDownloadList = (moduleId: string): void => {
    if (
      !isCheckedCertificate(moduleId) &&
      certificatesToDownload.length != totalMaxToDownload
    ) {
      setCertificatesToDownload([...certificatesToDownload, moduleId]);
      return;
    }
    setCertificatesToDownload(
      certificatesToDownload.filter((id) => id !== moduleId)
    );
  };

  /**
   * check if certificate is check
   */
  const isCheckedCertificate = (moduleId: string): boolean => {
    return certificatesToDownload.filter((id) => id === moduleId).length > 0;
  };

  /**
   * Redirect to modules
   */
  const redirectToModules = (): void => {
    history.push({
      pathname: "/modules",
    });
  };

  //console.log("certificatesList", certificatesList);

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Mes attestations - RF e-Learning CAC</title>
      </Helmet>
      {!loading ? (
        <React.Fragment>
          <Grid
            container={true}
            spacing={3}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.container}
          >
            {user && certificatesList?.length ? (
              <Grid item={true} xs={true}>
                <React.Fragment>
                  <Grid
                    container={true}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.header}
                  >
                    <Grid item={true}>
                      <Box fontSize="h6.fontSize" className={classes.title}>
                        MES ATTESTATIONS
                      </Box>
                    </Grid>

                    <Hidden smDown={true}>
                      <Grid item={true}>
                        <Button
                          variant="outlined"
                          className={classes.buttonEnableSelection}
                          onClick={() =>
                            setSelectMultipleCertificate(
                              !selectMultipleCertificate
                            )
                          }
                        >
                          {selectMultipleCertificate
                            ? `Annuler la sélection de plusieurs attestations`
                            : `Sélectionner plusieurs attestations`}
                        </Button>
                      </Grid>
                    </Hidden>
                  </Grid>

                  <Grid
                    container={true}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    className={classes.container}
                  >
                    {certificatesList.map((certificate) => (
                      <CertificateCard
                        key={certificate._id}
                        user={user}
                        certificate={certificate}
                        selectState={selectMultipleCertificate}
                        checked={isCheckedCertificate(certificate._id)}
                        addCertificateToDownloadList={
                          addCertificateToDownloadList
                        }
                        disabledCheckbox={disabledCheckboxValue}
                      />
                    ))}
                  </Grid>
                  <CertificateDownloadCard
                    enableSelection={setSelectMultipleCertificate}
                    multipleSelectionEnabled={selectMultipleCertificate}
                    buttonDisabled={certificatesToDownload.length === 0}
                    certificateIdList={certificatesToDownload}
                  />
                </React.Fragment>
              </Grid>
            ) : (
              <React.Fragment>
                <NoResult
                  isCertificate={true}
                  titleCustom=""
                  subTitleCustom="Vous ne disposez pas encore d’attestations, nous vous invitons
                   à terminer au moins un des modules de formation proposés."
                />
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  onClick={() => redirectToModules()}
                  className={classes.button}
                >
                  Accéder aux modules
                </Fab>
              </React.Fragment>
            )}
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid
            container={true}
            spacing={3}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item={true}>
              <Grid
                container={true}
                direction="column"
                justifyContent="flex-start"
                spacing={2}
              >
                <SkeletonLoader width={200} height={20} />
              </Grid>

              <Grid
                container={true}
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <SkeletonLoader width={960} height={135} />
                <SkeletonLoader width={960} height={135} />
                <SkeletonLoader width={960} height={135} />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(React.memo(Certificate));
